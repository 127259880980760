@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
:root {
  --dark-black: #303049;
  --soft-black: #39364F;
  --darker-black: #272738;
  --background-color: #f4f6fb;
  --primary: #1CBEC0;
  --primary-contrast-light: #ecfcfa;
  --primary-contrast-dark: #0a6a6c;
  --text-color: #262c38;
  --text-secondary-color: #6f7987;
  --headers-color: #181d26;
  --link-color: var(--primary);
  --greyish-brown: #3d3d3d;
  --dark-grey: #454d5b;
  --clear-blue: #2979ff;
  --bland-grey: #878787;
  --blue-grey: #f2f8fc;
  --very-light-blue: #dceffc;
  --semi-black: #363636;
  --kinky-grey: #edebeb;
  --easy-grey: #e5e7eb;
  --green: #06c2a6;
  --ok-green: #26cf95;
  --red: #ee1949;
  --dark-purple: #303049;
  --gold: #caa83c;
  --purple: #a42ece;
  --soft-purple: #fbebff;
  --material-color-red: #f44336;
	--material-color-red-50: #ffebee;
	--material-color-red-100: #ffcdd2;
	--material-color-red-200: #ef9a9a;
	--material-color-red-300: #e57373;
	--material-color-red-400: #ef5350;
	--material-color-red-500: var(----material-color-red);
	--material-color-red-600: #e53935;
	--material-color-red-700: #d32f2f;
	--material-color-red-800: #c62828;
	--material-color-red-900: #b71c1c;
	--material-color-red-a100: #ff8a80;
	--material-color-red-a200: #ff5252;
	--material-color-red-a400: #ff1744;
	--material-color-red-a700: #d50000;

	--material-color-pink: #e91e63;
	--material-color-pink-50: #fce4ec;
	--material-color-pink-100: #f8bbd0;
	--material-color-pink-200: #f48fb1;
	--material-color-pink-300: #f06292;
	--material-color-pink-400: #ec407a;
	--material-color-pink-500: var(--material-color-pink);
	--material-color-pink-600: #d81b60;
	--material-color-pink-700: #c2185b;
	--material-color-pink-800: #ad1457;
	--material-color-pink-900: #880e4f;
	--material-color-pink-a100: #ff80ab;
	--material-color-pink-a200: #ff4081;
	--material-color-pink-a400: #f50057;
	--material-color-pink-a700: #c51162;

	--material-color-purple: #9c27b0;
	--material-color-purple-50: #f3e5f5;
	--material-color-purple-100: #e1bee7;
	--material-color-purple-200: #ce93d8;
	--material-color-purple-300: #ba68c8;
	--material-color-purple-400: #ab47bc;
	--material-color-purple-500: var(--material-color-purple);
	--material-color-purple-600: #8e24aa;
	--material-color-purple-700: #7b1fa2;
	--material-color-purple-800: #6a1b9a;
	--material-color-purple-900: #4a148c;
	--material-color-purple-a100: #ea80fc;
	--material-color-purple-a200: #e040fb;
	--material-color-purple-a400: #d500f9;
	--material-color-purple-a700: #a0f;

	--material-color-deep-purple: #673ab7;
	--material-color-deep-purple-50: #ede7f6;
	--material-color-deep-purple-100: #d1c4e9;
	--material-color-deep-purple-200: #b39ddb;
	--material-color-deep-purple-300: #9575cd;
	--material-color-deep-purple-400: #7e57c2;
	--material-color-deep-purple-500: var(--material-color-deep-purple);
	--material-color-deep-purple-600: #5e35b1;
	--material-color-deep-purple-700: #512da8;
	--material-color-deep-purple-800: #4527a0;
	--material-color-deep-purple-900: #311b92;
	--material-color-deep-purple-a100: #b388ff;
	--material-color-deep-purple-a200: #7c4dff;
	--material-color-deep-purple-a400: #651fff;
	--material-color-deep-purple-a700: #6200ea;

	--material-color-indigo: #3f51b5;
	--material-color-indigo-50: #e8eaf6;
	--material-color-indigo-100: #c5cae9;
	--material-color-indigo-200: #9fa8da;
	--material-color-indigo-300: #7986cb;
	--material-color-indigo-400: #5c6bc0;
	--material-color-indigo-500: var(--material-color-indigo);
	--material-color-indigo-600: #3949ab;
	--material-color-indigo-700: #303f9f;
	--material-color-indigo-800: #283593;
	--material-color-indigo-900: #1a237e;
	--material-color-indigo-a100: #8c9eff;
	--material-color-indigo-a200: #536dfe;
	--material-color-indigo-a400: #3d5afe;
	--material-color-indigo-a700: #304ffe;

	--material-color-blue: #2196f3;
	--material-color-blue-50: #e3f2fd;
	--material-color-blue-100: #bbdefb;
	--material-color-blue-200: #90caf9;
	--material-color-blue-300: #64b5f6;
	--material-color-blue-400: #42a5f5;
	--material-color-blue-500: var(--material-color-blue);
	--material-color-blue-600: #1e88e5;
	--material-color-blue-700: #1976d2;
	--material-color-blue-800: #1565c0;
	--material-color-blue-900: #0d47a1;
	--material-color-blue-a100: #82b1ff;
	--material-color-blue-a200: #448aff;
	--material-color-blue-a400: #2979ff;
	--material-color-blue-a700: #2962ff;

	--material-color-light-blue: #03a9f4;
	--material-color-light-blue-50: #e1f5fe;
	--material-color-light-blue-100: #b3e5fc;
	--material-color-light-blue-200: #81d4fa;
	--material-color-light-blue-300: #4fc3f7;
	--material-color-light-blue-400: #29b6f6;
	--material-color-light-blue-500: var(--material-color-light-blue);
	--material-color-light-blue-600: #039be5;
	--material-color-light-blue-700: #0288d1;
	--material-color-light-blue-800: #0277bd;
	--material-color-light-blue-900: #01579b;
	--material-color-light-blue-a100: #80d8ff;
	--material-color-light-blue-a200: #40c4ff;
	--material-color-light-blue-a400: #00b0ff;
	--material-color-light-blue-a700: #0091ea;

	--material-color-cyan: #00bcd4;
	--material-color-cyan-50: #e0f7fa;
	--material-color-cyan-100: #b2ebf2;
	--material-color-cyan-200: #80deea;
	--material-color-cyan-300: #4dd0e1;
	--material-color-cyan-400: #26c6da;
	--material-color-cyan-500: var(--material-color-cyan) ;
	--material-color-cyan-600: #00acc1;
	--material-color-cyan-700: #0097a7;
	--material-color-cyan-800: #00838f;
	--material-color-cyan-900: #006064;
	--material-color-cyan-a100: #84ffff;
	--material-color-cyan-a200: #18ffff;
	--material-color-cyan-a400: #00e5ff;
	--material-color-cyan-a700: #00b8d4;

	--material-color-teal: #009688;
	--material-color-teal-50: #e0f2f1;
	--material-color-teal-100: #b2dfdb;
	--material-color-teal-200: #80cbc4;
	--material-color-teal-300: #4db6ac;
	--material-color-teal-400: #26a69a;
	--material-color-teal-500: var(--material-color-teal);
	--material-color-teal-600: #00897b;
	--material-color-teal-700: #00796b;
	--material-color-teal-800: #00695c;
	--material-color-teal-900: #004d40;
	--material-color-teal-a100: #a7ffeb;
	--material-color-teal-a200: #64ffda;
	--material-color-teal-a400: #1de9b6;
	--material-color-teal-a700: #00bfa5;

	--material-color-green: #4caf50;
	--material-color-green-50: #e8f5e9;
	--material-color-green-100: #c8e6c9;
	--material-color-green-200: #a5d6a7;
	--material-color-green-300: #81c784;
	--material-color-green-400: #66bb6a;
	--material-color-green-500: var(--material-color-green);
	--material-color-green-600: #43a047;
	--material-color-green-700: #388e3c;
	--material-color-green-800: #2e7d32;
	--material-color-green-900: #1b5e20;
	--material-color-green-a100: #b9f6ca;
	--material-color-green-a200: #69f0ae;
	--material-color-green-a400: #00e676;
	--material-color-green-a700: #00c853;

	--material-color-light-green: #8bc34a;
	--material-color-light-green-50: #f1f8e9;
	--material-color-light-green-100: #dcedc8;
	--material-color-light-green-200: #c5e1a5;
	--material-color-light-green-300: #aed581;
	--material-color-light-green-400: #9ccc65;
	--material-color-light-green-500: var(--material-color-light-green);
	--material-color-light-green-600: #7cb342;
	--material-color-light-green-700: #689f38;
	--material-color-light-green-800: #558b2f;
	--material-color-light-green-900: #33691e;
	--material-color-light-green-a100: #ccff90;
	--material-color-light-green-a200: #b2ff59;
	--material-color-light-green-a400: #76ff03;
	--material-color-light-green-a700: #64dd17;

	--material-color-lime: #cddc39;
	--material-color-lime-50: #f9fbe7;
	--material-color-lime-100: #f0f4c3;
	--material-color-lime-200: #e6ee9c;
	--material-color-lime-300: #dce775;
	--material-color-lime-400: #d4e157;
	--material-color-lime-500: var(--material-color-lime);
	--material-color-lime-600: #c0ca33;
	--material-color-lime-700: #afb42b;
	--material-color-lime-800: #9e9d24;
	--material-color-lime-900: #827717;
	--material-color-lime-a100: #f4ff81;
	--material-color-lime-a200: #eeff41;
	--material-color-lime-a400: #c6ff00;
	--material-color-lime-a700: #aeea00;

	--material-color-yellow: #ffeb3b;
	--material-color-yellow-50: #fffde7;
	--material-color-yellow-100: #fff9c4;
	--material-color-yellow-200: #fff59d;
	--material-color-yellow-300: #fff176;
	--material-color-yellow-400: #ffee58;
	--material-color-yellow-500: var(--material-color-yellow);
	--material-color-yellow-600: #fdd835;
	--material-color-yellow-700: #fbc02d;
	--material-color-yellow-800: #f9a825;
	--material-color-yellow-900: #f57f17;
	--material-color-yellow-a100: #ffff8d;
	--material-color-yellow-a200: #ff0;
	--material-color-yellow-a400: #ffea00;
	--material-color-yellow-a700: #ffd600;

	--material-color-amber: #ffc107;
	--material-color-amber-50: #fff8e1;
	--material-color-amber-100: #ffecb3;
	--material-color-amber-200: #ffe082;
	--material-color-amber-300: #ffd54f;
	--material-color-amber-400: #ffca28;
	--material-color-amber-500: var(--material-color-amber);
	--material-color-amber-600: #ffb300;
	--material-color-amber-700: #ffa000;
	--material-color-amber-800: #ff8f00;
	--material-color-amber-900: #ff6f00;
	--material-color-amber-a100: #ffe57f;
	--material-color-amber-a200: #ffd740;
	--material-color-amber-a400: #ffc400;
	--material-color-amber-a700: #ffab00;

	--material-color-orange: #ff9800;
	--material-color-orange-50: #fff3e0;
	--material-color-orange-100: #ffe0b2;
	--material-color-orange-200: #ffcc80;
	--material-color-orange-300: #ffb74d;
	--material-color-orange-400: #ffa726;
	--material-color-orange-500: var(--material-color-orange);
	--material-color-orange-600: #fb8c00;
	--material-color-orange-700: #f57c00;
	--material-color-orange-800: #ef6c00;
	--material-color-orange-900: #e65100;
	--material-color-orange-a100: #ffd180;
	--material-color-orange-a200: #ffab40;
	--material-color-orange-a400: #ff9100;
	--material-color-orange-a700: #ff6d00;

	--material-color-deep-orange: #ff5722;
	--material-color-deep-orange-50: #fbe9e7;
	--material-color-deep-orange-100: #ffccbc;
	--material-color-deep-orange-200: #ffab91;
	--material-color-deep-orange-300: #ff8a65;
	--material-color-deep-orange-400: #ff7043;
	--material-color-deep-orange-500: var(--material-color-deep-orange);
	--material-color-deep-orange-600: #f4511e;
	--material-color-deep-orange-700: #e64a19;
	--material-color-deep-orange-800: #d84315;
	--material-color-deep-orange-900: #bf360c;
	--material-color-deep-orange-a100: #ff9e80;
	--material-color-deep-orange-a200: #ff6e40;
	--material-color-deep-orange-a400: #ff3d00;
	--material-color-deep-orange-a700: #dd2c00;

	--material-color-brown: #795548;
	--material-color-brown-50: #efebe9;
	--material-color-brown-100: #d7ccc8;
	--material-color-brown-200: #bcaaa4;
	--material-color-brown-300: #a1887f;
	--material-color-brown-400: #8d6e63;
	--material-color-brown-500: var(--material-color-brown);
	--material-color-brown-600: #6d4c41;
	--material-color-brown-700: #5d4037;
	--material-color-brown-800: #4e342e;
	--material-color-brown-900: #3e2723;

	--material-color-grey: #9e9e9e;
	--material-color-grey-50: #fafafa;
	--material-color-grey-100: #f5f5f5;
	--material-color-grey-200: #eee;
	--material-color-grey-300: #e0e0e0;
	--material-color-grey-400: #bdbdbd;
	--material-color-grey-500: var(--material-color-grey);
	--material-color-grey-600: #757575;
	--material-color-grey-700: #616161;
	--material-color-grey-800: #424242;
	--material-color-grey-900: #212121;

	--material-color-blue-grey: #607d8b;
	--material-color-blue-grey-50: #eceff1;
	--material-color-blue-grey-100: #cfd8dc;
	--material-color-blue-grey-200: #b0bec5;
	--material-color-blue-grey-300: #90a4ae;
	--material-color-blue-grey-400: #78909c;
	--material-color-blue-grey-500: var(--material-color-blue-grey);
	--material-color-blue-grey-600: #546e7a;
	--material-color-blue-grey-700: #455a64;
	--material-color-blue-grey-800: #37474f;
	--material-color-blue-grey-900: #263238;
  
  --header-size: 64px;
  --max-width-default: 1200px;
}

.app {
  height: 100%;
  width: 100%;
}

html {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0px;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #303049;
  color: var(--dark-black);
  width: 100%;
  height: 100%;
  font-size: 16px;
  line-height: 1 !important;
}

a {
  color: #1CBEC0;
  color: var(--primary);
}

.white-button {
  color: white !important;
  border-color: white !important;
  background-color: transparent !important;
  transition: 0.3s !important;
}

.white-button:hover {
  color: white !important;
  border-color: white !important;
  background-color: rgba(255, 255, 255, 0.084) !important;
}

.white-button:focus {
  background-color: transparent !important;
}

.underlinedLink{
  font-weight: 600;
  text-decoration: underline;
  -webkit-text-decoration-color: #b5b5b5;
          text-decoration-color: #b5b5b5;
  -webkit-text-decoration-style: dotted;
          text-decoration-style: dotted;
  cursor: pointer;
}

.loaderContainer{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}


/* Overrides */

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #ebebeb;
  color: #2a3038;
}

.ant-menu-item a {
  font-size: 15px;
  font-weight: 600;
}

.ant-menu-item-selected a {
  color: #2a3038;
  font-weight: 700;
}

.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #1CBEC0;
  border-right: 3px solid var(--primary);
}

.ant-menu-item a:hover {
  color: #1CBEC0;
  color: var(--primary);
}

.ant-menu-item:hover {
  color: #1CBEC0;
  color: var(--primary);
}



.ant-btn {
  color: #1CBEC0;
  color: var(--primary);
  border-color: #1CBEC0;
  border-color: var(--primary);
  text-shadow: none;
  text-shadow: initial;
}

.ant-btn:hover {
	border-color: #0a6a6c;
	border-color: var(--primary-contrast-dark);
	color: #0a6a6c;
	color: var(--primary-contrast-dark);;
}

.ant-btn-link {
  border: none;

}

.ant-btn-primary {
  background: #1CBEC0;
  background: var(--primary);
  border-color: #1CBEC0;
  border-color: var(--primary);
  color: white;
  font-weight: 600;
}

.ant-btn-dangerous {
  color: #ff4d4f;
  background: #fff;
  border-color: #ff4d4f;
}


.ant-btn-text:hover, .ant-btn-text:focus {
  color: unset;
  background: transparent;
  border-color: none;
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
  background: #0a6a6c;
  background: var(--primary-contrast-dark);
  border-color: transparent;
  color: white;
}

.ant-input, .ant-select-selector, .ant-input-number-input, .ant-input-number, .ant-picker, .ant-input-affix-wrapper{
  background-color: #f5f8fa !important;
  border-radius: 5px !important;
  border-color: transparent !important;
  height: 60px;
  color: #3c3c3c !important;
  font-weight: 700 !important;
  font-size: 16px !important;
}

.ant-input::-webkit-input-placeholder{
	color: rgb(138, 138, 138);
}

.ant-input:-ms-input-placeholder{
	color: rgb(138, 138, 138);
}

.ant-input::-ms-input-placeholder{
	color: rgb(138, 138, 138);
}

.ant-input::placeholder{
	color: rgb(138, 138, 138);
}

.ant-input-affix-wrapper > input.ant-input {
  height: auto;
}

.ant-picker {
  width: 100%;
}

.ant-picker-input > input {
  font-size: 16px;
  font-weight: 700;
}

.hide-resize textarea {
  vertical-align:middle; 
  display: table-cell;
  resize: none;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1CBEC0;
    background-color: var(--primary);
    border-color: #1CBEC0;
    border-color: var(--primary);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
	border-color: #1CBEC0 !important;
	border-color: var(--primary) !important;
}


.ant-input:hover, .ant-input:focus {
  border-color: #1CBEC0;
  border-color: var(--primary);
}

.ant-form-item-label > label {
  font-size: 14px;
  color: #a4a4a4;
  font-weight: 700;
}

.ant-form-item-label > label.ant-form-item-required:before {
  content: unset !important;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 100%;
  color: #3c3c3c;
  font-weight: 700;
  font-size: 16px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 60px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 60px;
}

.ant-drawer-title {
  font-weight: 700;
}

.ant-input-group-addon {
  background-color:#f5f8fa;
  border: unset;
  color: #0000007c;
  font-weight: 600;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)  {
  color: #1CBEC0 !important;
  color: var(--primary) !important;
  border-color: #1CBEC0 !important;
  border-color: var(--primary) !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #1CBEC0 !important;
  color: var(--primary) !important;
  border-color: #1CBEC0 !important;
  border-color: var(--primary) !important;
}

.doubleInputGrid {
  display: grid;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  grid-template-columns: 1fr 1fr;
}

.sectors .ant-tag-checkable {
  padding: 12px 20px;
  color: #1CBEC0;
  color: var(--primary);
  font-size: 16px;
  border-radius: 3px;
  border: 1px #1CBEC0 solid;
  border: 1px var(--primary) solid;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.sectors .ant-tag-checkable-checked {
  background-color: #ecfcfa;
  background-color: var(--primary-contrast-light);
  color: #1CBEC0;
  color: var(--primary);
  font-weight: 700;
  border: 2px #1CBEC0 solid;
  border: 2px var(--primary) solid;
  padding-right: 12px;
  padding-left: 12px;
}

.sectors .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #0a6a6c;
  color: var(--primary-contrast-dark);
  border: 1px #0a6a6c solid;
  border: 1px var(--primary-contrast-dark) solid;
} 

.ant-select-selection-item {
    padding-top: 10px !important;
}

.ant-select-selection-placeholder {
    padding-top: 10px !important;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
  font-weight: 700;
}

.ant-layout {
  background-color: #f4f6fb;
  background-color: var(--background-color);
}

a.ant-typography, .ant-typography a {
  color: #1CBEC0;
  color: var(--primary);
}

a.ant-typography, .ant-typography a:hover {
  color: #0a6a6c;
  color: var(--primary-contrast-dark);
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn  {
  font-weight: 700;
  color: #1CBEC0;
  color: var(--primary);
}

.ant-tabs-ink-bar {
  background: #1CBEC0;
  background: var(--primary);
}

.ant-select-selection-item {
  padding-top: 10px !important;
}

.ant-select-selection-placeholder {
  padding-top: 10px !important;
}

.ant-modal-title   {
  font-weight: 700;
}

.ant-slider-track {
  background-color: #1CBEC0;
  background-color: var(--primary);
}

.ant-slider:hover .ant-slider-track { 
  background-color: #1CBEC0; 
  background-color: var(--primary);
}

.ant-slider-handle.ant-tooltip-open {
  border-color: #1CBEC0;
  border-color: var(--primary);
}

.ant-switch-checked { 
  background-color: #1CBEC0; 
  background-color: var(--primary);
}

.ant-timeline-item-head-blue {
  color: #1CBEC0;
  color: var(--primary);
  border-color: #1CBEC0;
  border-color: var(--primary);
}


.ant-carousel .slick-dots li.slick-active button {
  background: #1CBEC0;
  background: var(--primary);
  opacity: 1;
}

.ant-carousel .slick-dots li button {
  background: #1CBEC0;
  background: var(--primary);
  opacity: 0.3;
  /* color: var(--primary); */
}

.ant-carousel .slick-dots  {
  bottom: 0;
}

.ant-rate-star-first, .ant-rate-star-second {
  color: #e8e8e8;
}

[data-aspect-ratio] {
  display: block;
  max-width: 100%;
  position: relative;
}

[data-aspect-ratio]:before {
  content: '';
  display: block;
}

[data-aspect-ratio] > * {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
[data-aspect-ratio="3:1"]:before {
  padding-top: 33.33%;
}
[data-aspect-ratio="2:1"]:before {
  padding-top: 50%;
}
[data-aspect-ratio="16:9"]:before {
  padding-top: 56.25%;
}
[data-aspect-ratio="3:2"]:before {
  padding-top: 66.66%;
}
[data-aspect-ratio="4:3"]:before {
  padding-top: 75%;
}



/* icons */

@font-face {
  font-family: 'icomoon';
  src:  url(/static/media/icomoon.64a0d742.eot);
  src:  url(/static/media/icomoon.64a0d742.eot#iefix) format('embedded-opentype'),
    url(/static/media/icomoon.e291d573.ttf) format('truetype'),
    url('/fonts/icomoon.woff?occdc7') format('woff'),
    url(/static/media/icomoon.628f8e05.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ic-tables:before {
  content: "\e1";
}
.icon-ic-admin:before {
  content: "\e2";
}
.icon-ic-help:before {
  content: "\e3";
}
.icon-ic-lounge:before {
  content: "\e4";
}
.icon-ic-stage:before {
  content: "\e5";
}
.icon-ic-people:before {
  content: "\e6";
}
.icon-ic-people2:before {
  content: "\e7";
}

.Card_container__ZuTLF {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    box-shadow: 0 2px 5px 0 rgba(211, 211, 211, 0.5);
    background-color: #ffffff;
    padding: 20px;
}
.CommunityChatCard_container__1Ua7n {
    width: 100%;
    max-width: 700px;
    line-height: 1.5;
    padding: 28px;
    position: relative;
}

.CommunityChatCard_header__Jvepd {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 8px;
    color: var(--dark-black);
}

@media (max-width: 599px) {
    .CommunityChatCard_header__Jvepd {
        font-size: 16px;
    }
}

.CommunityChatCard_description__10IP6 {
    font-size: 15px;
    margin-bottom: 16px;
    color: var(--dark-black);
}

.CommunityChatCard_creditsNumber__2PGEQ {
    color: var(--primary);
}

.CommunityChatCard_premiumMember__2xue5 {
    font-weight: 700;
    margin-bottom: 12px;
    /* color: var(--gold); */
}

.CommunityChatCard_buttonContainer__3OJt3 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width: 599px) {
    .CommunityChatCard_buttonContainer__3OJt3 {
        display: grid;
        grid-row-gap: 12px;
        row-gap: 12px;
    }
}


.CommunityChatCard_ctaButton__1OUCI {
    font-size: 16px;
    font-weight: 700;
    background-color: #707af8;
    border: none;
}

.CommunityChatCard_discountBadge__2B6fR {
    margin-left: 12px;
}

@media (max-width: 599px) {
    .CommunityChatCard_discountBadge__2B6fR {
        margin-left: 0px;
    }
}


.CommunityChatCard_socialProofContainer__2nI8r {
    margin-top: 12px;
}

.CommunityChatCard_socialProofText__3Xmx_ {
    font-size: 13px;
    color: var(--text-secondary-color);
}

.CommunityChatCard_companies__1DbQP {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
}

.CommunityChatCard_companies__1DbQP img {
    width: 56px;
    margin-right: 10px; 
}

.CommunityChatCard_icon__3kr0b {
    width: 20px;
    margin-right: 12px;

}

.CommunityChatCard_dismissIcon__1pvSy {
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;
}
.MembershipCard_container__16_6M {
    width: 100%;
    max-width: 700px;
    line-height: 1.5;
    padding: 28px;
}

.MembershipCard_header__3XCRz {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 8px;
    color: var(--dark-black);
}

@media (max-width: 599px) {
    .MembershipCard_header__3XCRz {
        font-size: 16px;
    }
}

.MembershipCard_description__CAseL {
    font-size: 15px;
    margin-bottom: 16px;
    color: var(--dark-black);
}

.MembershipCard_creditsNumber__1Reu4 {
    color: var(--primary);
}

.MembershipCard_premiumMember__1QULP {
    font-weight: 700;
    margin-bottom: 12px;
    /* color: var(--gold); */

}

.MembershipCard_buttonContainer__2pbJ6 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width: 599px) {
    .MembershipCard_buttonContainer__2pbJ6 {
        display: grid;
        grid-row-gap: 12px;
        row-gap: 12px;
    }
}


.MembershipCard_ctaButton__Ns4wZ {
    font-size: 16px;
    font-weight: 700;
}

.MembershipCard_discountBadge__3EyGB {
    margin-left: 12px;
}

@media (max-width: 599px) {
    .MembershipCard_discountBadge__3EyGB {
        margin-left: 0px;
    }
}


.MembershipCard_socialProofContainer__MfylG {
    margin-top: 12px;
}

.MembershipCard_socialProofText__1ce1G {
    font-size: 13px;
    color: var(--text-secondary-color);
}

.MembershipCard_companies__3IkIw {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
}

.MembershipCard_companies__3IkIw img {
    width: 56px;
    margin-right: 10px; 
}
.DiscountBadge_container__RCOU6 {
    color: var(--purple);
    font-weight: 700;
    font-size: 12px;
    border-radius: 8px;
    padding: 4px 8px;
    background-color: var(--soft-purple);
    display: flex;
    justify-content: center;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.WelcomeModal_modal__2DWmO {
    width: 750px !important;
    /* overflow: hidden; */
}

.WelcomeModal_container__3V8ft {
    height: 450px;
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow: hidden;
}

.WelcomeModal_founderAvatar__3qlf6 {
    position: absolute;
    top: -40px;
}

.WelcomeModal_avatar__2zp9X {
    width: 80px;
    height: 80px;
    border: 2px #1cbcbe solid;
    box-shadow: rgba(21, 21, 25, 0.208) 0px 10px 20px;
}

.WelcomeModal_content___EY5t {
    margin-top: 50px;
}

.WelcomeModal_header__2PwOY {
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 12px;
}

.WelcomeModal_textsContainer__o4Lxv {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.WelcomeModal_text__2RTOd {
    font-size: 15px;
    line-height: 1.7;
    color: #29232d;
    max-width: 600px;
}

@media (max-width: 599px) {
    .WelcomeModal_text__2RTOd {
        padding: 0px 8px;
    }
}

.WelcomeModal_phase__1ujZo {
    display: flex;
    flex-direction: column;
}

@media (max-width: 599px) {
    .WelcomeModal_phase__1ujZo {
        /* flex-direction: row; */
    }
}

.WelcomeModal_bolded__1dmY6 {
    font-weight: 700;
}

.WelcomeModal_progressBar__2vmV- {
    position: absolute;
    top: -10px;
    z-index: 10;
}

.WelcomeModal_progressBarRaw__1H8jl {
    height: 6px;
    width: 0px;
    position: absolute;
    bottom: -0px;
    background-color: var(--primary);
    animation: WelcomeModal_progress__2Cs0L 42s linear forwards;
    z-index: 10;
}

@keyframes WelcomeModal_progress__2Cs0L {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}

.WelcomeModal_thirdText__3xyzP {
    /* max-width: 500px; */
}

.WelcomeModal_forthText__1gtXR {
    /* max-width: 400px; */
}

.WelcomeModal_exploreButton__254Po {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 200px;
}

.WelcomeModal_teamText__2juCn {
    font-size: 14px;
    color: var(--text-secondary-color);
    margin-bottom: 12px;
}

.WelcomeModal_teamAvatar__3_d7n {
    width: 40px;
    height: 40px;
}

.welcome-modal .ant-progress-inner {
    border-radius: 0px;
    height: 5px;
}

.welcome-modal .ant-progress-bg {
    border-radius: 0px;
}

/* scenes */

.WelcomeModal_sceneBase__2F56M {
    position: absolute;
    height: 240px;
    right: 0;
    left: 0;
    bottom: 0px;
    overflow: hidden;
}

.WelcomeModal_firstScene__2yu0e {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 100%;
}

.WelcomeModal_firstScene__2yu0e .WelcomeModal_right__2Sglt {
    width: 180px;
    position: absolute;
    bottom: -35px;
    right: -20px;
}

.WelcomeModal_firstScene__2yu0e .WelcomeModal_left__3rbeu {
    width: 180px;
    position: absolute;
    bottom: -17px;
    left: -20px;
}

.WelcomeModal_logoAnimation__1rrYB {
    margin-top: -80px;
    width: 320px;
}

.WelcomeModal_secondScene__17ByV {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 100%;
}

.WelcomeModal_secondSceneContainer__3F5rp {
    margin-top: -80px;;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

@media (max-width: 599px) {
    .WelcomeModal_secondSceneContainer__3F5rp {
        margin-top: -40px;;

    }
}


.WelcomeModal_tableScene__lI2No {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 100%;
}

.WelcomeModal_tableVideoContainer__3Gild {
    margin-top: -70px;
    margin-left: -30px;
    height: 200px;
    object-fit: contain;
}

@media (max-width: 599px) {
    .WelcomeModal_tableVideoContainer__3Gild {
        margin-top: -20px;
    }
}

.WelcomeModal_scAvatar__3lB4I {
    transition: all 300ms;
}

.WelcomeModal_emojiGif__2IeSk {
    position: absolute;
    top: -0px;
    width: 100px;
}

.WelcomeModal_eventsScene__KIYdw {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
}

.WelcomeModal_eventsSceneLogosGrid__aHqU0 {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 480px;
}

@media (max-width: 599px) {
    .WelcomeModal_eventsSceneLogosGrid__aHqU0 {
        margin-top: 60px;

    }
}


.WelcomeModal_eventsSceneLogo__3OQHg {
    margin-right: 12px;
    margin-bottom: 12px;
    width: 70px;
    height: 70px;
}

@media (max-width: 599px) {
    .WelcomeModal_eventsSceneLogo__3OQHg {
        width: 40px;
        height: 40px;
    }
}


.WelcomeModal_welcomeText__1TVbn {
    max-width: 700px;
}
.UserAvatar_container___lVTZ {
    display: inline-block;
    position: relative;
}

.UserAvatar_small__2YwuA {
    transform: scale(1);
}

.UserAvatar_medium__S07Pe {
    transform: scale(1.33);
}

.UserAvatar_large__2e5N5 {
    transform: scale(1.66);
}

.UserAvatar_userImage__1iU-c {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px white solid;
}

.UserAvatar_nonImage__2qKY1 {
    width: 60px;
    height: 60px;
    background-color: var(--primary-contrast-light);
    border-radius: 50%;
    border: 1px white solid;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25%;
}

.UserAvatar_noProfileImage__2wOFb {
    opacity: 0.4;
    width: 100%;
    height: 100%;
}

.UserAvatar_companyImage__HiLF9 {
    object-fit: contain;
    position: absolute;
    border-radius: 50%;
    border: 1px white solid;
    box-shadow: 0 0 10px 0 rgba(198, 198, 198, 0.5);
    background-color: #ffffff;
    width: 24px;
    height: 24px;
    right: 0px;
    bottom: 0px;
}

.UserAvatar_onlineDot__2_xkx {
    position: absolute;
    left: 3px;
    bottom: 0;
    width: 10px;
    height: 10px;
    border: 1px white solid;
    border-radius: 50%;
    background-color: #30a24c;
}

.UserAvatar_onlineDotRipple__3nmLW {
    position: absolute;
    bottom: 0;
    left: 3.4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    animation: UserAvatar_ripple__2AqJo 1.4s infinite ease-in-out;
    border: 1px solid #30a24c;
    content: '""',
}

@keyframes UserAvatar_ripple__2AqJo {
    0% {
        transform: scale(.8);
        opacity: 1;
    }
    1% {
        transform: scale(.8);
        opacity: 1;
    }
    2% {
        transform: scale(.8);
        opacity: 1;
    }
    3% {
        transform: scale(.8);
        opacity: 1;
    }
    100% {
        transform: scale(1.4);
        opacity: 0;
    }
}
.SectionHeader_container__38jzQ {
    display: flex;
    flex-direction: column;
}

.SectionHeader_titleContainer__3dAow {
    display: flex;
    align-items: center;
}

.SectionHeader_title__1nhNk {
    font-size: 32px;
    font-weight: 700;
    color: var(--headers-color);
}

.SectionHeader_linkContainer__1h9mb {
    display: flex;
    align-items: center;
    margin-left: 12px;
}

.SectionHeader_link__3S3b_ {
    padding: 4px 6px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.337);
    color: var(--material-color-grey-600);
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    border: 1px solid var(--material-color-grey-300);
    /* box-shadow: 0 4px 20px hsl(0deg 0% 50% / 17%); */
    transition: 0.3s all;
}

.SectionHeader_link__3S3b_:hover {
    border-color: var(--primary-contrast-dark);
    color: var(--primary-contrast-dark);
}

@media(max-width: 599px) {
    .SectionHeader_titleContainer__3dAow {
        flex-direction: column;
        align-items: flex-start;
    }
    .SectionHeader_title__1nhNk {
        font-size: 24px;
        font-weight: 800;
    }
    .SectionHeader_linkContainer__1h9mb {
        margin-top: 12px;
        margin-left: 0px;
    }
}

.SectionHeader_subTitle__1dNNh {
    margin-top: 12px;
    line-height: 1.7;
    font-size: 16px;
    color: var(--text-secondary-color);
}

.SectionHeader_mediumTitle__1bHd0 {
    font-size: 20px;
    font-weight: 700;
}

@media(max-width: 599px) {
    .SectionHeader_mediumTitle__1bHd0 {
        font-size: 20px;
    }
}

.SectionHeader_extraContainer__28wHO {
    margin-left: 4px;
    font-size: 14px;
    font-weight: 700;
    color: var(--material-color-grey-600);
}
.LiveBadge_container__3Bysl {
    justify-content: center;
    align-items: center;
    padding: 4px 8px 4px 8px;
    font-size: 12px;
    background-color: var(--red);
    border-radius: 5px;
    font-weight: 600;
    display: flex;
    color: white;
    flex-grow: 0;
    width: auto;
}

@keyframes LiveBadge_flickerAnimation__2bU22 {
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }

.LiveBadge_circle__22Kx7 {
    width: 5px;
    height: 5px;
    background: white;
    border-radius: 50%;
    margin-right: 5px;
    animation: LiveBadge_flickerAnimation__2bU22 2s infinite;
}
.AttendanceEncourager_container__2MXNH {
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 8px 5px 8px;
    font-weight: 600;
    border-radius: 2px;
    font-size: 12px;
    color: white;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.653);
}


.AttendanceEncourager_emoji__3R99n {
    width: 14px;
    margin-right: 2px;
    margin-left: -2px;
    animation: AttendanceEncourager_bounce__17Dsq 0.9s infinite ease-in-out alternate-reverse;
}

@keyframes AttendanceEncourager_bounce__17Dsq {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.2);
    }
}
.PeopleGoingSnippet_membersGoginContainer__3j7SO {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    color: #a9acb0;
    flex-shrink: 0;
}

.PeopleGoingSnippet_teamAvatar__OB2ev {
    width: 20px;
    height: 20px;
}

.PeopleGoingSnippet_membersGogingText__cXiqD {
    font-size: 11px;
    margin-top: 4px;
}

.PeopleGoingSnippet_icon__LNNKV {
    margin-right: 1px;
    width: 12px;
    height: 12px;
}

.EventCardSpeaker_speaker__2tcVW {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.EventCardSpeaker_speakerExtended__2XF6q {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.EventCardSpeaker_speakerDetails__2Cu4H {
    margin-left: 12px;
}

.EventCardSpeaker_speakerAvatar__1mOpe {
    border: 2px var(--primary) solid;
}

.EventCardSpeaker_speakerRole__23Ypr {
    margin-top: 5px;
    font-size: 11px;
    color: #a9acb0;
    text-align: center;
    text-transform: capitalize;
}

.EventCardSpeaker_speakerName__10AbK {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 4px;
    line-height: 1.3em;
}


.EventCardSpeaker_speakerTitle__1Rd-4  {
    font-size: 12px;
    color: #a9acb0;
    line-height: 1.3em;
}
.EventCard_container__217U2 {
    position: relative;
    padding: 0;
    width: 400px;
    height: 318px;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    transition: ease 0.3s;
    overflow: hidden;
}

@media (max-width: 599px) {
    .EventCard_container__217U2 {
        width: 100%;
        height: 348px;
        background-color: white;
    }
}

.EventCard_themeStrip__oeK40 {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 6px;
    background-color: var(--primary);
}

.EventCard_content__r1bwC {
    height: 100%;
    padding: 20px 18px 18px 18px;
    display: flex;
    flex-direction: column;
}

.EventCard_themeTitle__pLrIH {
    font-size: 12px;
    color: var(--primary);
    margin-bottom: 2px;
    font-weight: 600;
}

.EventCard_title__MkB92 {
    font-size: 19px;
    line-height: 1.6;
    font-weight: bold;
    color: var(--soft-black);
    margin-bottom: 0 !important;
}

.EventCard_dateContainer__2oc2P {
    display: inline-flex;
    margin-top: 8px;
    /* justify-content: center; */
    align-items: center;
}

.EventCard_date__3xrnL {
    display: flex;
    align-items: center;
    color: var(--primary-contrast-dark);
    font-size: 14px;
    font-weight: 700;
    /* line-height: 1.2; */
}

.EventCard_liveBadge__14_T7 {
    margin-left: 12px;
}

.EventCard_speakersContainer__3nGh9 {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.EventCard_speakersHeader__ZZbfS {
    font-size: 12px;
    font-weight: bold;
    color: var(--text-secondary-color);
}

.EventCard_speakers__1Bgfg {
    /* margin-top: 14px; */
    display: flex;
    flex-direction: row;
    align-items: center;
}

.EventCard_speakers__1Bgfg>* {
    margin-right: 10px;
}

.EventCard_membersGoing__2LRQh {
    margin-top: 8px;
    /* position: absolute; */
    /* bottom: 20px; */
    left: 16px;
    margin-bottom: -4px;
    align-items: flex-start ;
}

.EventCard_extraSpeakers__2TFqt {
    width: 34px;
    height: 34px;
    border: 1px solid var(--primary);
    background-color: var(--primary-contrast-light);
    border-radius: 50%;
    display: flex;
    font-size: 12px;
    margin-top: -10px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    color: var(--primary);
}

/* forum event styles */

.EventCard_paddedHeader__2ChBk {
    display: inline;
    font-size: 20px;
    line-height: 1.8;
    font-weight: 700;
    padding: 0.1em 0.5em;
    background-color: white;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
}

.EventCard_forumDate__l-R3l {
    /* margin-top: 12px; */
    padding: 4px 9px;
    background-color: rgba(255, 255, 255, 0.15);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: white;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 600;
}

.EventCard_forumLiveBadge__3OCON {
    margin-top: 8px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.EventCard_endedBadge__2p0nP {
    margin-left: 8px;
    justify-content: center;
    align-items: center;
    padding: 4px 8px 4px 8px;
    font-size: 10px;
    text-transform: capitalize;
    background-color: var(--material-color-grey-900);
    border-radius: 5px;
    font-weight: 600;
    display: flex;
    color: white;
    flex-grow: 0;
    width: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.EventCard_registeredBadge__rNwE8 {
    margin-left: 8px;
    justify-content: center;
    align-items: center;
    padding: 4px 8px 4px 8px;
    font-size: 10px;
    text-transform: capitalize;
    background-color: var(--material-color-green-800);
    border-radius: 5px;
    font-weight: 600;
    display: flex;
    color: white;
    flex-grow: 0;
    width: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.EventCard_forumMembersGoing__jj5ok {
    /* position: absolute; */
    /* bottom: 20px; */
    align-items: flex-start ;
    color: white ;
    margin-bottom: -4px;
}

.EventCard_encourager__38bAs {
    margin-top: 9px;
}

.EventCard_encouragerLight__35AuM {
    background-color: var(--primary-contrast-light);
    color: var(--primary-contrast-dark);
}

.EventCard_hiddenIndicator__5Zp6C{
    position: absolute;
    top: 12px;
    text-transform: uppercase;
    border-radius: 5px;
    right: 12px;
    font-size: 10px;
    padding: 4px 8px;
    color: white;
    font-weight: 600;
    background-color: rgba(99, 99, 99, 0.954);
}

.EventCard_videoIcon__1GqEk {
    margin-right: 4px;
}
.EventsStrip_container__3qFpk {
    width: 100%;
}

.EventsStrip_stripWrapper__3NSZO {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 12px 0;
    position: relative;
    height: 360px;
    width: 100%;
}

@media (max-width: 599px) {
    .EventsStrip_stripWrapper__3NSZO {
        height: 382px;
        /* background-color: black; */
    }
}

.EventsStrip_stripWrapper__3NSZO::-webkit-scrollbar {
    display: none;
  }

.EventsStrip_strip__2su3q {
    -webkit-overflow-scrolling: touch;
    display: flex;
    padding-top: 12px;
    position: absolute;
    width: 100%;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

@media (max-width: 599px) {
    .EventsStrip_strip__2su3q {
        width: auto;
    }
    
}

.EventsStrip_strip__2su3q::-webkit-scrollbar {
    display: none;
}

.EventsStrip_strip__2su3q>* {
    margin-right: 16px;
}

@media (max-width: 599px) {
    .EventsStrip_stripItem__2T_Ma {
        width: 85vw;
        height: 348px;
    }
}

.EventsStrip_emptyEvents__3Pvgx {
    /* margin-top: 30px; */
    border: 1px solid var(--kinky-grey);
    padding: 20px 40px;
    width: 100%;
    max-width: 850px;
    background-color: #ffffff;
    color: var(--text-secondary-color);
    font-size: 15px;
    line-height: 1.6em;
}

.EventsStrip_emptyEventsHeader__1_Y30 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 22px;
}
.UserBox_container__XN73e {
    width: 220px;
    height: 220px;
    cursor: pointer;
    position: relative;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}


@media(max-width: 599px) {
    .UserBox_container__XN73e {
        width: 100%;
    }
}

.UserBox_card__Pbyp4 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.UserBox_text__3lsZR {
margin-top: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.UserBox_name__2F4Zz {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    flex-direction: row;
    font-size: 15px;
}

.UserBox_titles__iuh5q {
    margin-top: 8px;
    font-size: 12px;
    color: var(--text-secondary-color);
}

.UserBox_title__cLmOG {
    line-height: 1.3;
    font-weight: 600;
    color: var(--text-secondary-color) !important;
    margin-bottom: 0px !important;
}

.UserBox_companyName__21u0A {
    margin-top: 5px;
}

.UserBox_onlineBubble__rEUOY {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #30a24c;
    margin-right: 6px;
}

.UserBox_role__6ljTz {
    background-color: var(--primary-contrast-light);
    color: var(--primary);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 6px;
    position: absolute;
    top: 10px;
    left: 10px;
    font-weight: 600;
    border-radius: 1px;
}

.UserBox_premiumBadge__2Oqgb {
    padding: 2px 4px;
    background-color: #cba83c;
    color: white;
    font-size: 8px;
    border-radius: 4px;
    position: absolute;
    font-weight: 600;
    text-transform: uppercase;
    top: 12px;
    left: 12px;
}
.MembersStrip_container__3ndk_ {
    
}

.MembersStrip_stripWrapper__3QiwO {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 2px 0;
    position: relative;
    height: 250px;
}

.MembersStrip_stripWrapper__3QiwO::-webkit-scrollbar {
    display: none;
  }


.MembersStrip_strip__1lB4D {
    -webkit-overflow-scrolling: touch;
    display: flex;
    padding-top: 12px;
    position: absolute;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.MembersStrip_strip__1lB4D::-webkit-scrollbar {
    display: none;
  } 
  
.MembersStrip_strip__1lB4D > * {
    margin-right: 12px;
}

.MembersStrip_stripItem__3lFLO {
    width: 220px;
    height: 230px;
}
.ResourcesStrip_container__2hP58 {}

.ResourcesStrip_strip__3jwxT {
    margin-top: 18px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* max-width: 1000px; */
}

@media (max-width: 599px) {
    .ResourcesStrip_strip__3jwxT {
        display: grid;
        flex-direction: column;
    }
}

.ResourcesStrip_strip__3jwxT>* {
    margin-bottom: 16px;
    margin-right: 16px;
}

.ResourcesStrip_itemContainer__UikWD {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 165px;
    position: relative;
    overflow: hidden;
    padding: 20px;
    width: 100%;
    max-width: 520px;
    align-items: flex-start;
}

@media (max-width: 599px) {
    .ResourcesStrip_itemContainer__UikWD {
        height: auto;
        padding: 20px 12px 20px 12px;
    }
}

.ResourcesStrip_itemHeader__1BTuF {
    font-size: 18px;
    line-height: 1.4;
    font-weight: bold;
}

.ResourcesStrip_itemSubHeader__vsE3x {
    margin-top: 10px;
    color: var(--text-secondary-color);
    line-height: 1.5;
    max-width: 85%;
    font-size: 15px;
}

.ResourcesStrip_sosImage__25gTc {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 70px;
}

.ResourcesStrip_sosReportImage__3felD {
    position: absolute;
    right: -40px;
    bottom: -20px;
    width: 135px;
}

@media (max-width: 599px) {
    .ResourcesStrip_sosImage__25gTc {
        width: 80px;
    }
    .ResourcesStrip_sosReportImage__3felD {
        position: absolute;
        right: -35px;
        bottom: -20px;
        width: 120px;
    }
}

.ResourcesStrip_badge__SnR4V {
    padding: 3px 5px;
    height: 20px;
    background-color: #01c479;
    color: white;
    font-weight: 600;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    position: absolute;
    bottom: 20px;
    text-transform: uppercase;
}

@media (max-width: 599px) {
    .ResourcesStrip_badge__SnR4V {
        position: static;
        margin-top: 30px;
    }
}

.ResourcesStrip_phocuswrightLogo__1FSxB {
    height: 22px;
    margin-top: -4px;
    margin-left: 5px;
}

.ResourcesStrip_subTitle__QCbbz {
    display: flex;
    align-items: center;
}

.ResourceCard_itemContainer__nSvFZ {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 165px;
    position: relative;
    width: 100%;
    max-width: 520px;
    align-items: flex-start;
}

@media (max-width: 599px) {
    .ResourceCard_itemContainer__nSvFZ {
        height: auto;
        /* padding: 20px 12px 20px 12px; */
    }
}

.ResourceCard_itemHeader__1GFD9 {
    font-size: 18px;
    line-height: 1.4;
    font-weight: bold;
}

.ResourceCard_itemSubHeader__1MA2d {
    margin-top: 10px;
    color: var(--text-secondary-color);
    line-height: 1.5;
    /* max-width: 85%; */
    font-size: 15px;
}

.ResourceCard_badge__2XK19 {
    padding: 6px 8px;
    height: 20px;
    font-weight: 600;
    background-color: #f6f6f8;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    position: absolute;
    bottom: 20px;
    text-transform: uppercase;
}

@media (max-width: 599px) {
    .ResourceCard_badge__2XK19 {
        position: static;
        margin-top: 30px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }
}

.ResourceCard_subTitle__1AZet {
    display: flex;
    align-items: center;
}

.ResourceCard_icon__1D-th {
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: 80px;
}
.DiscoverPage_container__1pEzJ {
    padding-top: 2px;
}

@media (max-width: 599px) {
    .DiscoverPage_container__1pEzJ {
        /* margin-right: -12px; */
    }
}

@media (max-width: 599px) {
    .DiscoverPage_mobileStrip__1vwlP {
        margin-right: -12px;
    }
}

.DiscoverPage_strips__mxFfg {
    margin-bottom: 30px;
    display: grid;
    grid-row-gap: 38px;
    row-gap: 38px;
}

.DiscoverPage_greeting__1hXfZ {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
    ;
}

@media (max-width: 599px) {
    .DiscoverPage_greeting__1hXfZ .DiscoverPage_header__2nAsG {
        font-size: 24px;
        font-weight: 700;
    }
}

.DiscoverPage_greeting__1hXfZ .DiscoverPage_subHeader__yFPkR {
    margin-top: 8px;
    color: var(--text-secondary-color);
}

.DiscoverPage_membershipCard__3Z_Qt {
    /* margin-bottom: 20px; */
}
.MembersPage_container__1msCv {
    height: auto;
    height: calc(100vh - var(--header-size));
    overflow: scroll;
    /* margin-bottom: -30px; */
    position: relative;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.MembersPage_container__1msCv::-webkit-scrollbar {
    display: none;
}

.MembersPage_membersGrid__3Ou7a {
    display: flex;
    flex-wrap: wrap;
    max-width: var(--max-width-default);
    padding: 20px 0px 0px 0px;
}

.MembersPage_membersGrid__3Ou7a>* {
    margin-right: 16px;
    margin-bottom: 16px;
}

@media(max-width: 599px) {
    .MembersPage_membersGrid__3Ou7a>* {
        margin-right: 0px;
        margin-bottom: 16px;
    }
}

.MembersPage_loader__1EY_n {
    width: 40px;
    height: 40px;
}

.MembersPage_showMoreButton__3tdWm {
    display: none;
}

@media (min-height: 1000px) {
    .MembersPage_showMoreButton__3tdWm {
        display: inline;
        display: initial;
    }
}

.MembersPage_sectionContainer__77fYx {
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: var(--max-width-default);
}

.MembersPage_suggestMemberButton__3_GBQ {
    bottom: 0;
    position: absolute;
    right: 34px;
    font-weight: 700;
}

@media (max-width: 599px) {
    .MembersPage_sectionContainer__77fYx {
        display: flex;
        flex-direction: column-reverse;
    }
    .MembersPage_suggestMemberButton__3_GBQ {
        margin-top: 10px;
        position: static;
    }
}


.MembersPage_suggestIcon__1OHYT {
    width: 22px;
    margin-right: 4px;
}


.MembersPage_soonContainer__2iPzP {
    flex-direction: column;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.MembersPage_animation__tWJBz {
    width: 200px;
}
.Loader_hide__2RM6E { 
    display: none;
}

.Loader_small__310t7 {
    width: 40px;
    height: 40px;
}

.Loader_medium__1IqAP {
    width: 60px;
    height: 60px;
}

.Loader_large__3CAEZ {
    width: 100px;
    height: 100px;
}
.SuggestMemberModal_container__BV8VP {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 20px 5px 20px;
}

@media (max-width: 599px) {
    .SuggestMemberModal_container__BV8VP {
        padding: 0px;
    }
}

.SuggestMemberModal_header__3XMJX {
    font-weight: 800;
    font-size: 28px;
    line-height: 1.4;
    text-align: center;
}

.SuggestMemberModal_subHeader__2_JqR {
    margin-top: 20px;
    text-align: center;
    margin-bottom: 20px;
} 

.SuggestMemberModal_textArea__2xaa4 {
    margin-top: 16px;
    width: 100%;
}


.SuggestMemberModal_loginButton__3ZjME {
    font-weight: 600;
    width: 100%;
}


.question-form textarea {
    min-height: 100px;
}

.SuggestMemberModal_askingAsContainer__2SbUu {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    font-size: 12px;
    color: var(--text-secondary-color);
}

.SuggestMemberModal_askingAsContainer__2SbUu div {
    margin-left: 8px;
}
.SuggestMemberModal_askingAsContainer__2SbUu span {
    font-weight: 600;
}
.DashboardPage_container__34slx {
    height: 100%;
    color: var(--text-color);
    position: relative;
}

.DashboardPage_sider__1Bq9c {
    box-shadow: 0 2px 10px 0 rgba(151, 151, 151, 0.5);
    background-color: #ffffff;
    z-index: 2;
}

@media(max-width: 599px) {
    .DashboardPage_sider__1Bq9c {
        position: fixed;
        height: 100vh;
        left: 0px;
    }
}

.DashboardPage_siderHeader__1OUn2 {
    display: flex;
    padding: 12px 20px;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    background-color: var(--primary);
}

.DashboardPage_siderLogo__2_7_z {
    width: 20px;
    cursor: pointer;
    -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
}

.DashboardPage_menuIcon__14HIL {
    opacity: 0.7;
    width: 24px;
    cursor: pointer;
}

.DashboardPage_siderMenu__2TFnw {
    margin-top: 20px;
}

.DashboardPage_content__3_SpG {
    position: relative;
    flex: auto;
    min-height: auto;
    height: auto;
    padding: 0px 12px 0px 75px;
    margin-top: 64px;
    background-color: var(--background-color);
}

.DashboardPage_fullPageMode__3qWC4 {
    padding: 0px !important;
    margin-top: 0px;
}

@media (max-width: 599px) {
    .DashboardPage_content__3_SpG {
        padding: 12px 12px;
    }
}

.DashboardPage_header__1pet2 {
    background-color: var(--background-color);
    position: fixed;
    top: 0;
    height: var(--header-size);
    right: 0;
    padding: 0 20px;
    z-index: 1;
}

@media (max-width: 599px) {
    .DashboardPage_header__1pet2 {
        padding: 0 12px;
    }
}

.DashboardPage_menuItem__2HfDM {
    display: flex;
    align-items: center;
    position: relative;
}

.DashboardPage_menuWithBadge__1kgM4 {
    display: flex;
    align-items: center;
}

.DashboardPage_menuBadge__18U9j {
    position: absolute;
    padding: 3px 5px;
    height: 20px;
    right: 10px;
    background-color: #57c676;
    color: white;
    font-weight: 600;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    text-transform: uppercase;
}

.DashboardPage_siderBottom__1UJBc {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 30px;
    right: 0;
    left: 0;
}

.DashboardPage_logo__2m-SB {
    width: 130px !important;
    margin-left: -5px;
}

.HeaderContent_container__3sdrg {
    display: flex;
    flex-direction: row;
    width:100%;
    height: 100%;
}

.HeaderContent_menuIconMobile__InshQ {
    cursor: pointer;
    width: 26px;
    object-fit: contain;
}

.HeaderContent_sidePanel__1JmlM {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    height: 100%;
}


.HeaderContent_logo__2K-e5 {
    height: 22px !important;
    margin-top: -6px;
    margin-right: 12px;
}


.EditProfileBubble_profileDetails__2kBCT {
    display: flex;
    align-items: center;
    padding: 0 12px;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.EditProfileBubble_profileText__3tAcN {
    margin-left: 12px;
}

.EditProfileBubble_userName__1EH6R {
    font-size: 16px;
    font-weight: 600;
}

.EditProfileBubble_editProfileText__3Rjy2 {
    color: var(--text-secondary-color);
    font-weight: 600;
    font-size: 13px;
}

.EditProfileBubble_menu__21nPX {
    background-color: white;
    padding: 12px 0px;
    width: 300px;
    max-width: 300px;
    border-radius: 5px;
    border: 2px #e1e1e1 solid;
    box-shadow: 0 0 10px 0 rgba(198, 198, 198, 0.5);
}

.EditProfileBubble_proMember__1ul-y {
    font-weight: 700;
}

.EditProfileBubble_trialMember__eViCS {
    font-weight: 700;
}

.EditProfileBubble_proItem__dPXd2 {
    width: 100%;
    background-color: var(--primary);
    color: white;
}

.EditProfileBubble_proItem__dPXd2:hover {
    color: white;
}

.EditProfileBubble_avatar__26TKn {
    width: 40px;
    height: 40px;
    cursor: pointer;
    border: 2px solid var(--primary);
}

.EditProfileBubble_avatarContainer__1MdoM{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
}

.EditProfileBubble_badge__acKXr {
    background-color: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 4px;
    font-size: 8px;
    font-weight: 700;
    border-radius: 8px;
    position: absolute;
    color: white;
    bottom: -6px;
    height: 12px;
    width: auto;
    flex-grow: 0;
    text-transform: uppercase;
}
.ProfilePage_container__2jQo7 {
    width: 100%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.ProfilePage_card__3iGHM {
    margin-top: 20px;
    position: relative;
}

.ProfilePage_headerContainer__2Uj0b  {
    position: relative;
    display: flex;
    background-color: var(--background-color);
}

@media (max-width: 599px) {
    .ProfilePage_headerContainer__2Uj0b {
        flex-direction: column;
    }
}

.ProfilePage_updateButton__2mRQQ {
    width:  200px;
    margin-top: 20px;
    margin-left: auto;
}

@media (max-width: 599px) { 
    .ProfilePage_updateButton__2mRQQ {
        width: 100%;
        margin-left: 0px;
    }
}

.ProfilePage_viewProfileButton__1ZNEA {
    margin-top: 8px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.ProfilePage_buttonsContainer__23oyd {
    display: flex;
    flex-direction: column;
}


.ProfilePage_doubleInputGrid__30_bE {
    margin-top: -15px;
    display: grid;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    grid-template-columns: 1fr 1fr;
}

@media (max-width: 599px) {
    .ProfilePage_doubleInputGrid__30_bE {
        grid-template-columns: 1fr;
    }
}

.ProfilePage_userProfile__15gNA {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ProfilePage_profileLabels__2mYyj {
    margin-left: 24px;
}

.ProfilePage_profileHeaderText__10C4O {
    font-weight: bold;
}

.ProfilePage_profileSubHeaderText__1mJ-s {
    color: var(--text-secondary-color);
}

.ProfilePage_companySearchHit__2FMGZ {
    display: flex;
    align-items: center;
    height: 40px;
}

.ProfilePage_companySearchName__QXujM {
    font-weight: 600;
}

.ProfilePage_companySearchLogo__7nqpY {
    margin-right: 12px;
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 2px;
    border: 1px #f2f2f2 solid;
}

.ProfilePage_addNewButtonLink__1eCb7 {
    padding: 8px 0px;
    font-weight: 600;
    color: var(--primary);
}


.profile textarea {
    min-height: 140px;
    font-weight: 600 !important;
}

.profile .ant-select-selection-placeholder {
    padding-top: 0px !important;
  }
  
  .profile .ant-select-selection-item {
    padding-top: 0px !important;
  }
  
  .profile .ant-select-selector {
    padding: 5px;
    height: auto;
    min-height: 60px;
  }

  .ProfilePage_sectorTag__3Kkxz {
    font-size: 14px;
    background-color: var(--primary-contrast-light);
    padding: 8px;
    border: 1px solid #c4d0cf;
    color: var(--primary);
    margin-bottom: 2px;
}

.ProfilePage_header__3cEM6 {
    font-size: 18px; 
    color: var(--dark-black);
    font-weight: 700;
    margin-bottom: 30px;
}

.ProfilePage_subHeader__E1wCU {
    margin-top: 4px;
    font-weight: 400;
    line-height: 1.4;
    font-size: 16px;
    color: var(--text-secondary-color);
}

.ProfilePage_objectiveButton__3Hjlz {
    text-align: center;
    overflow: hidden;
    flex-direction: column;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 14px;
    color: var(--primary);
    font-weight: 600;
    border: 1px var(--kinky-grey) solid;
    border-radius: 5px;
    width: 100%;
    line-height: 1.4;
    max-width: 400px;
    height: 150px;
    transition: 0.3s;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
}

.ProfilePage_objectiveButton__3Hjlz:hover {
    box-shadow: 0 0 10px 0 rgba(198, 198, 198, 0.5);
}

.ProfilePage_objectivesContainer__3qWss {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 12px;
    gap: 12px;
    grid-template-rows: auto;
}

@media (max-width: 599px) {
    .ProfilePage_objectivesContainer__3qWss {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
        gap: 10px;
        grid-template-rows: auto;
    }
}

.ProfilePage_objectiveSelected__2vKEK {
    background-color: var(--primary-contrast-light);
    font-weight: 600;
}

.ProfilePage_icon__3718F {
    margin-bottom: 16px;
    width: 40px;
}

.ProfilePage_goBack__16DSr {
    background: linear-gradient(1deg, rgb(255, 248, 57) 0%, rgb(255, 167, 85) 100%);
    font-size: 15px;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.927);
    font-weight: 600;
    cursor: pointer;
    border: none;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    opacity: 1;
    padding: 12px 12px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: -20px;
    margin-bottom: 40px;
    transition: 0.3s;
}

.ProfilePage_labelDescription__Yrt2l {
    font-size: 14px;
    color: var(--text-secondary-color);
    margin-top: -12px;
}

.ProfilePage_reauthContainer__312DT {
    display: flex;
    flex-direction: column;
    /* margin-top: -12px; */
}

.ProfilePage_reloginButtton__1bNQV {
    margin-top: 8px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.ProfilePhoto_container__2FKZ7 {
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
}

.ProfilePhoto_profileImage__3C-oU {
    width: 90px;
    height: 90px;
    background-size: cover;
    background-color: #f5f8fa;
}

.ProfilePhoto_changeButton__P0jjk {
    height: 50px;
    color: white;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-size: 12px;
    opacity: 1;
    background-color: #0000005f;
    text-shadow: 0 -1px 10 rgba(0, 0, 0, 0.12);
     bottom: -25px;
}
.CompanyBox_container__2mGlo {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 20px;
    border: 1px var(--kinky-grey) solid;
    width: 100%;
}

.CompanyBox_content__30aCY {
    display: flex;
    width: 100%;
    flex-direction: row;
}

@media(max-width: 599px) {
    .CompanyBox_content__30aCY {
        flex-direction: column;
    }
}

.CompanyBox_logo__1yfwW {
    margin-right: 24px;
    width: 50px;
    height: 50px;
    border-radius: 3px;
    object-fit: contain;
}

.CompanyBox_name__R_OKQ {
    font-weight: 700;
}

.CompanyBox_unlinkButton__fLVd9 {
    font-size: 14px;
    margin-left: auto;
}

@media(max-width: 599px) {
    .CompanyBox_unlinkButton__fLVd9 {
        margin-top: 4px;
        margin-left: 0px;
    }
}

.CompanyBox_label__3kJop {
    font-size: 14px;
    color: #a4a4a4;
    font-weight: 700;
    margin-bottom: 8px;
}
.AddCompanyDrawer_content__1Z8Of {
    color: var(--text-secondary-color);
    line-height: 1.6;
    font-size: 16px;
    padding: 30px 24px;
    display: grid;
    grid-gap: 60px;
    gap: 60px;
}

@media(max-width: 599px) {
    .AddCompanyDrawer_content__1Z8Of {
        padding: 30px 12px;
    }
}

.AddCompanyDrawer_industryTag__3QKrM {
    font-size: 14px;
    background-color: #ecfcfa;
    padding: 8px;
    border: 1px solid #c4d0cf;
    color: var(--primary);
    margin-bottom: 2px;
}

.AddCompanyDrawer_companyLogo__1dlJV {
    width: 100px;
    object-fit: contain;
    height: 100px;
}

.AddCompanyDrawer_uploadText__jQFmw {
    font-size: 14px;
    color: var(--text-secondary-color);
}

.add-company .ant-select-selection-item {
    padding-top: 10px !important;
}

.add-company .ant-select-selection-placeholder {
    padding-top: 10px !important;
}

.industries-selector .ant-select-selection-placeholder {
    padding-top: 0px !important;
}

.industries-selector .ant-select-selection-item {
    padding-top: 0px !important;
}

.industries-selector .ant-select-selector {
    padding: 5px;
    height: auto;
    min-height: 60px;
}

.add-company textarea {
    min-height: 140px;
}

.NavigationButtons_buttonsContainer__2ooCX {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 12px;
    position: fixed;
    display: flex;
    width: 100%;
    bottom: 0px;
    left: 0px;
    box-shadow: 0px -4px 10px rgba(211, 211, 211, 0.5);
    background-color: white;

}

@media(max-width: 599px) {
    .NavigationButtons_buttonsContainer__2ooCX {
        margin-top: 45px;
    }
}
.OnboardingHeader_headerContainer__GhCy6 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}

.OnboardingHeader_header__JOkZs {
    font-size: 38px;
    font-weight: 800;
    line-height: 1.4;
}

@media(max-width: 599px) {
    .OnboardingHeader_header__JOkZs {
        font-size: 28px;
    }
}



.OnboardingHeader_subTitle__3YRtx {
    margin-top: 12px;
    color: var(--text-secondary-color);
    font-size: 18px;
    max-width: 500px;
}

.StepWrapper_container__3DV_a {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 70px;
}

.StepWrapper_content__1t72f {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 680px;
    width: 100%;
}

@media(max-width: 599px) {
    .StepWrapper_content__1t72f {
        margin-top: 35px;
    }
}







.ObjectivesStep_content__1XL7M {
    width: 100%;
    margin-top: -20px;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media (max-width: 599px) {
    .ObjectivesStep_content__1XL7M {
        margin-top: 0;
        margin-top: initial;
    }
}

.ObjectivesStep_objectiveButton__11O3e {
    text-align: center;
    overflow: hidden;
    flex-direction: column;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 14px;
    color: var(--primary);
    font-weight: 600;
    border: 1px var(--kinky-grey) solid;
    border-radius: 5px;
    width: 100%;
    line-height: 1.4;
    max-width: 400px;
    height: 150px;
    transition: 0.3s;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    font-size: 14px;
    cursor: pointer;
}

.ObjectivesStep_objectiveButton__11O3e:hover {
    box-shadow: 0 0 10px 0 rgba(198, 198, 198, 0.5);
}

.ObjectivesStep_objectivesContainer__3zx_q {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 12px;
    gap: 12px;
    grid-template-rows: auto;
}

@media (max-width: 599px) {
    .ObjectivesStep_objectivesContainer__3zx_q {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
        gap: 10px;
        grid-template-rows: auto;
    }
}

.ObjectivesStep_objectiveSelected__3qC6d {
    background-color: var(--primary-contrast-light);
    font-weight: 600;
}

.ObjectivesStep_icon__3N-MZ {
    margin-bottom: 16px;
    width: 40px;
}
.AddEventDrawer_content__2PU6f {
    color: var(--text-secondary-color);
    font-size: 16px;
    padding: 30px 24px;
    display: grid;
    grid-gap: 60px;
}

@media(max-width: 599px) {
    .AddEventDrawer_content__2PU6f {
        padding: 30px 12px;
    }
}

.AddEventDrawer_speakersContainer__V_-cS {
    margin-top: 20px;
}

.AddEventDrawer_speakersLabel__1t2eO {
    font-size: 14px;
    color: #a4a4a4;
    font-weight: 700;
}

.AddEventDrawer_uploadText__2Sv0G {
    font-size: 14px;
    color: var(--text-secondary-color);
}

.add-event .ant-select-selection-item {
    padding-top: 10px !important;
}

.add-event .ant-select-selection-placeholder {
    padding-top: 10px !important;
}

.add-event textarea {
    min-height: 140px;
}

.AddEventDrawer_themeColor__37Kt4 {
    position: relative;
}

.AddEventDrawer_colorPicker__1Gvv4 {
    position: absolute;
    right: 12px;
    top: 45px;
    width: 30px;
    height: 30px;
    border: 1px #9b9b9b solid;
    margin-left: 8px;
}

.AddEventDrawer_smallInput__1t5cR {
    width: 120px;
}
.AddEventDrawer_smallInput2__Wvku8 {
    width: 60px;
}

.AddEventDrawer_side2side__OwD-Y {
    display: flex;
}

.AddEventDrawer_side2side__OwD-Y > * {
    margin-right: 12px;
}

.AddEventDrawer_row__3BUO- {
     display: flex;
     flex-direction: row;
     align-items: center;
}

.AddEventDrawer_suffix__28NZ7 {
    margin-left: 12px;
    font-size: 12px;
    color: var(--text-secondary-color);
}


.ImageUpload_image__2YtUO {
    width: 100px;
    object-fit: cover;
    height: 100px;
}

.ImageUpload_uploadText__3_xzS {
    font-size: 14px;
    color: var(--text-secondary-color);
}

.ImageUpload_removeButton__3I6OT {
    margin-top: 0px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    color: var(--material-color-red);
}
.TargetingModal_attendeeOption__103ae {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.TargetingModal_attendeeAvatar__1LjMZ {
    margin-right: 12px;
    width: 24px;
    height: 24px;
    margin-left: 8px;
    flex-shrink: 0;
}

.TargetingModal_attendeeName__2pKfO {
    color: var(--text-secondary-color);
    font-size: 14px;
    color: var(--dark-black);
    margin-right: 8px;
}

.TargetingModal_userSelect__2QYJ2 {
    width: 100%;
    height: auto;
}

.event-targeting-modal .ant-select-show-search.ant-select-multiple .ant-select-selector {
    height: auto;
    min-height: 60px;
}
.event-targeting-modal .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    height: auto;
    font-weight: 600;
    padding: 4px !important;
    border-radius: 4px;
    border-color: rgb(220, 220, 220);
    background-color:#f2f2f2;
}
.EventsPage_container__3beVH {
    position: relative;
    padding-bottom: 40px;
}

.EventsPage_eventsGrid__3i4oo {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
}

.EventsPage_sectionContainer__LFT7X {
    display: flex;
    flex-direction: column;
}

.EventsPage_eventsGrid__3i4oo>* {
    margin-right: 15px;
    margin-bottom: 15px;
}

@media(max-width: 599px) {
    .EventsPage_eventsGrid__3i4oo>* {
        margin-right: 0px;
        margin-bottom: 16px;
        width: 100%;
    }
}

.EventsPage_eventsContainer__L-Rr3 {
    margin-top: 60px;
}

.EventsPage_eventBox__3Vq9J {
    width: 380px;
}

@media (max-width: 599px) {
    .EventsPage_eventBox__3Vq9J {
        width: 100%;
    }
}

.EventsPage_buttons__3BWYx {
    margin-top: 22px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.EventsPage_buttons__3BWYx>* {
    margin-right: 8px;
}

.EventsPage_filterButton__QHZgo {
    background-color: transparent;
    color: var(--darker-black);
    border-color: var(--darker-black);
}

.EventsPage_filterButtonSelected__20OwJ {
    background-color: var(--darker-black) !important;
    border-color: var(--darker-black) !important;
    color: white !important;
}

.EventsPage_filterButton__QHZgo {
    background-color: transparent;
    color: var(--darker-black);
    border-color: var(--darker-black);
}

.EventsPage_divider__3JXNB {
    background: #dfdfdf;
    width: 1px;
    margin-right: 20px;
    margin-left: 12px;
}

@media (max-width: 599px) {
    .EventsPage_buttons__3BWYx>* {
        margin-bottom: 8px;
    }
}

.EventsPage_futureEventsGrid__13w9P {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 24px;
    gap: 24px;
}

.EventsPage_dateDivider__1ctif {
    height: 1px;
    background-color: #e0e9f4;
    width: 100%;
    max-width: 400px;
    margin: 12px 0px;
}

.EventsPage_dateHeader__1Koyq {
    font-weight: 700;
    font-size: 34px;
}

.EventsPage_dateHeaderToday__3Y2BH {
    font-weight: 700;
    font-size: 34px;
}

.EventsPage_dayInTheWeek__3Dpvb {
    margin-top: 8px;
    color: var(--text-secondary-color);
    font-size: 15px;
}

.EventsPage_momthName__3JE-m {
    margin-left: 4px;
    font-size: 16px;
    font-weight: 700;
}

.EventsPage_emptyEvents__2YmGe {
    margin-top: 30px;
    border: 1px solid var(--kinky-grey);
    padding: 20px;
    max-width: 350px;
    background-color: #ffffff;
    color: var(--text-secondary-color);
    font-size: 15px;
}
.UserDrawerHeader_container__18wA1 {
    width: 100%;
}

.UserDrawerHeader_top__1p2C4 {
    padding: 0 48px;
    display: flex;
    height: 120px;
    align-items: flex-end;
    background-color: var(--primary);
}

@media(max-width: 599px) {
    .UserDrawerHeader_top__1p2C4 {
        padding: 30px 12px;
        flex-direction: column;
        height: auto;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}

.UserDrawerHeader_memberName__1ZVHW {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.45px;
    color: #ffffff;
}

.UserDrawerHeader_memberTitle__eLK9o {
    font-size: 16px;
    margin-top: 5px;
    line-height: 1.4;
    color: #ffffff;
}


.UserDrawerHeader_avatarContainer__31EHm     {
    margin-bottom: -50px;
    margin-right: 24px;
}

@media(max-width: 599px) {
    .UserDrawerHeader_avatarContainer__31EHm {
        margin-bottom: 20px;
        margin-right: 0px;
    }
}

.UserDrawerHeader_avatar__3CyP7 {
    border-width: 4px;
}

.UserDrawerHeader_userDetailsContainer__1IA2E {
    display: flex;
    flex-direction: column;;
    margin-bottom: 20px;
}

@media(max-width: 599px) {
    .UserDrawerHeader_userDetailsContainer__1IA2E {
        margin-bottom: 0px;
    }
}

.UserDrawer_content__371Fp {
    color: var(--text-secondary-color);
    line-height: 1.6;
    font-size: 16px;
    padding: 38px 48px 40px 48px;
    display: grid;
    grid-gap: 40px;
    gap: 40px;
}

@media(max-width: 599px) {
    .UserDrawer_content__371Fp {
        padding: 20px 10px 10px 20px;
    }
}

.UserDrawer_buttonContainer__372nD {
    margin-top: 16px;
    margin-left: 170px;
}

.UserDrawer_buttonContainer__372nD > * {
    margin-right: 8px;
}

@media(max-width: 599px) {
    .UserDrawer_buttonContainer__372nD > * {
        margin-bottom: 8px;
    }
}

.UserDrawer_meetButton__7UJTu {
    font-weight: 600;
}

.UserDrawer_introButton__3Pw12 {
    font-weight: 600;
}

.UserDrawer_metricsButton__3usY9 {
    background-color: var(--darker-black);
    color: white;
    border-color: var(--darker-black);
}

.UserDrawer_metricsButton__3usY9:hover, .UserDrawer_metricsButton__3usY9:focus {
    background-color: var(--darker-black);
    color: white;
    border-color: var(--darker-black);
}

@media(max-width: 599px) {
    .UserDrawer_buttonContainer__372nD {
        margin-left: 12px;
    }
}


.UserDrawer_subHeader__18lml {
    font-weight: 700;
    color: #00063c;
    margin-bottom: 24px;
}

.UserDrawer_foundingMember__3F0gK {
    justify-content: center;
    align-items: center;
    padding: 4px 12px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: red 1px solid;
}

.UserDrawer_badges__b12Li {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.UserDrawer_badges__b12Li > * {
    margin-right: 18px;
    margin-bottom: 18px;
}

.UserDrawer_badge__3BgA6 {
    display: flex;
    width: 140px;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding: 12px;
    border: 1px var(--kinky-grey) solid;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.UserDrawer_badge__3BgA6 img {
    width: 70px
}

.UserDrawer_badge__3BgA6 div {
    margin-top: 5px;
    color: #191220;
    font-weight: 600;
    font-size: 12px;
}
.CompanyInfo_container__2_hCx {
    width: 100%;
}

.CompanyInfo_image__1R8gX {
    height: 80px;
    width: 80px;
    object-fit: contain;
    margin-right: 16px;
}

.CompanyInfo_company__14MZl {
    display: flex;
    flex-direction: row;
    
}

.CompanyInfo_description__15ADX {
    color: var(--text-secondary-color);
    font-size: 15px;
    
}

.CompanyInfo_name__3E7j3 {
    color: #3f3f3f;
    margin-bottom: 3px;
    font-weight: bold;
}
.MeetMemberModal_container__1LjHp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 24px 0px 24px;
    font-size: 16px;
}

@media (max-width: 599px) {
    .MeetMemberModal_container__1LjHp {
        padding: 40px 12px 20px 12px;
    }
}

.MeetMemberModal_header__1bg_N {
    font-weight: 800;
    font-size: 24px;
    line-height: 1.4;
    text-align: center;
}

.MeetMemberModal_subHeader__2eXvo {
    margin-top: 20px;
    text-align: center;
    margin-bottom: 20px;
    font-size: 16px;
    color: var(--text-secondary-color);
} 

.MeetMemberModal_upcomingEventsContainer__3wbr6 {
    width: 100%;
    margin: 0px 0px 30px 0px;
}

.MeetMemberModal_upcomingEventsHeader__3eypf {
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    margin-bottom: 12px;
}

.MeetMemberModal_events__1qnvV {
    display: grid;
    grid-template-rows: 1fr;
    grid-row-gap: 8px;
    row-gap: 8px;
    width: 100%;
    padding: 0px 20px;
}

@media (max-width: 599px) {
    .MeetMemberModal_events__1qnvV {
        padding: 0px;
    }
}

.MeetMemberModal_eventItem__xdmEV {
    position: relative;
    padding: 12px;
    width: 100%;
    border: 1px var(--kinky-grey) solid;
}


.MeetMemberModal_eventTitle__16xiO {
    font-weight: 800;
    margin-bottom: 2px;
    font-size: 16px;
    color: var(--dark-black);
}

.MeetMemberModal_eventDate__1gxMm {
    color: var(--text-secondary-color);
    font-size: 14px;
    margin-bottom: 16px;
    color: #008e8e;
    font-weight: 700;
}

.MeetMemberModal_peopleGoing__1JIRd {
    line-height: 1;
    align-items: flex-start;    
}

.MeetMemberModal_attendToButton__1A91e {
    position: absolute;
    bottom: 12px;
    right: 12px;
    font-weight: 600;
}

@media (max-width: 599px) {
    .MeetMemberModal_attendToButton__1A91e {
        margin-top: 18px;
        position: static;
    }
}

.MeetMemberModal_currentMemberSnippet__g4hN9 {
    display: flex;
    font-size: 14px;
    color: var(--text-secondary-color);
}

.MeetMemberModal_currentMemberSnippet__g4hN9 > *{
    margin-right: 8px;
}

.MeetMemberModal_followContainer__2b5cP {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 18px;
    border-radius: 12px 12px 0px 0px;
    background-color: var(--primary-contrast-light);
}

.MeetMemberModal_notifyText__255L5 {
    margin-right: 8px;
    margin-bottom: 12px;
    color: black;
}

.MeetMemberModal_bubblesContainer__dWd1F {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
}

.MeetMemberModal_userAvatar__9_Tgh {
    margin-right: -8px;
}
.RequestIntroModal_container__2xbJp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 24px 24px 24px;
    font-size: 16px;
}

@media (max-width: 599px) {
    .RequestIntroModal_container__2xbJp {
        padding: 40px 12px 20px 12px;
    }
}

.RequestIntroModal_header__qMIlP {
    font-weight: 800;
    font-size: 24px;
    line-height: 1.4;
    text-align: center;
}

.RequestIntroModal_subHeader__1JP9x {
    margin-top: 14px;
    text-align: center;
    margin-bottom: 20px;
    font-size: 16px;
    /* color: var(--text-secondary-color); */
} 

.RequestIntroModal_askingAsContainer__1hlDY {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -4px;
    font-size: 14px;
    /* color: var(--text-secondary-color); */
}

.RequestIntroModal_askingAsContainer__1hlDY div {
    margin-left: 8px;
}

.RequestIntroModal_askingAsContainer__1hlDY span {
    font-weight: 600;
}

.RequestIntroModal_form__2eCMI { 
    /* display: flex; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
}

.RequestIntroModal_bubblesContainer__P8f_n {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
}

.RequestIntroModal_leftAvatar__kyZPs {
    margin-right: -8px;
}


.RequestIntroModal_avatar__2fqXX {
    border: 1px solid var(--kinky-grey);
}

.RequestIntroModal_textarea__pMuWp {
    width: 100%;
    min-height: 120px !important;
}

.RequestIntroModal_button__3o-7p {
    width: 100%;
}


.RequestIntroModal_reasonHeader__1egoh {
    font-weight: 700;
    font-weight: 18px;
    text-align: center;
    color: var(--dark-purple);
}

.RequestIntroModal_reasonSubHeader__3QKfR {
    margin-top: 2px;
    text-align: center;
    margin-bottom: 18px;
    font-size: 15px;
    color: var(--text-secondary-color);
} 

.RequestIntroModal_doneAnimation__22UXy {
    margin-top: -20px;
    width: 150px;
    height: 120px;
}
.MemberMetricsModal_content__OPAdD {
    line-height: 1.4;
    padding-bottom: 20px;
}

.MemberMetricsModal_top__F3RVT {
    display: flex;
    background-color: #f7f9fc;
    padding: 30px;
    align-items: center;
}

.MemberMetricsModal_texts__3JOtf {
    display: flex;
    flex-direction: column;
    margin-left: 18px;
    justify-content: center;
}

.MemberMetricsModal_name__30IRM {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 4px;
}

.MemberMetricsModal_title__cBDpO {
    font-size: 14px;
    color: var(--text-secondary-color);
}

.MemberMetricsModal_bottomPart__3UiVy {
    margin-top: 18px;
    display: grid;
    grid-gap: 20px;
    gap: 20px;
}

.MemberMetricsModal_header__cPbPV {
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 12px;
}

.MemberMetricsModal_overview__3VyRw {
    padding: 12px 30px;
}

.MemberMetricsModal_overviewItems__23MHy {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media(max-width: 599px) {
    .MemberMetricsModal_overviewItems__23MHy {
        justify-content: flex-start;
    }
    .MemberMetricsModal_overviewItems__23MHy > * {
        margin-right: 24px;
        margin-bottom: 24px; 
    }
}

.MemberMetricsModal_membershipItems__2fOzS {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    /* flex-wrap: wrap;
    justify-content: flex-start; */
    
}

@media (max-width: 599px) {
    .MemberMetricsModal_membershipItems__2fOzS {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}


.MemberMetricsModal_overviewItemHeader__3eWb0 {
    font-size: 14px;
    color: var(--text-secondary-color);
    margin-bottom: 5px;
    /* font-weight: 600; */
}

.MemberMetricsModal_overviewItemValue___6hsJ {
    font-weight: 600;
    font-size: 15px;
}

.MemberMetricsModal_activity__3Sbx1  {
    margin-top:-10px;
    max-width: 100vw;
}

.MemberMetricsModal_tabs__1U300 {
    margin-top: 12px;
    height: 280px;
}

.MemberMetricsModal_events__MOirz {
    display: flex;
    flex-direction: column;
    /* row-gap: 12px; */
    overflow-y: scroll;
    height: 200px;
}

.MemberMetricsModal_eventItem__1KZFq {
    position: relative;
    /* padding: 12px; */
    width: 100%;
    height: auto;
    cursor: pointer;
    /* border: 1px var(--kinky-grey) solid; */
    /* margin-bottom: 12px; */
}


.MemberMetricsModal_eventTitle__3WhOF {
    font-weight: 700;
    margin-bottom: 2px;
    font-size: 16px;
    color: var(--dark-black);
}

.MemberMetricsModal_eventDate__2DD5g {
    color: var(--text-secondary-color);
    font-size: 14px;
    margin-bottom: 16px;
    /* color: #008e8e; */
    font-weight: 700;
}

.MemberMetricsModal_introList__1nnvr {
    display: flex;
    flex-direction: column;
    /* margin: 16px 0px; */
    overflow-y: auto;
    /* padding: 10px 0px; */
    /* height: 320px; */
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.MemberMetricsModal_introList__1nnvr::-webkit-scrollbar {
    display: none;
}

.MemberMetricsModal_introItem__3nQ77 {
    display: flex;
    flex-direction: row;
    width: 100%;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    align-items: center;
}

.MemberMetricsModal_meetingItem__19arW {
    cursor: pointer;
}

.MemberMetricsModal_introTitle__4HjZO {
    margin-left: 8px;
    color: var(--soft-black);
    /* font-size: 15px; */
    /* color: var(--text-secondary-color); */
    /* font-weight: 600; */
}

.MemberMetricsModal_teamAvatar__1Nhn9 {
    width: 24px;
    height: 24px;
}

.MemberMetricsModal_introStatusTag__22dPn {
    font-size: 12px !important;
    font-weight: 600;
    margin-left: auto;
}

.MemberMetricsModal_introName__s2WY2 {
    font-weight: 700;
    color: var(--soft-black);
    /* cursor: pointer; */
}

.intro-list .ant-list-item-action {
    margin-left: 8px;
}

.MemberMetricsModal_tags__2adCm {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.MemberMetricsModal_tags__2adCm > * {
    margin-right: 8px;
}

.MemberMetricsModal_referrerUserContainer__pGQCk {
    margin-top: 8px;
    display: flex;
}

.MemberMetricsModal_referrerUserContainer__pGQCk div {
    /* margin-left: 12px; */
    margin-top: -4px;
    font-size: 14px;
    color: var(--darker-black);
    font-weight: 600;
}

.MemberMetricsModal_timeline__1lap5 {
    display: flex;
    flex-direction: column;
    /* row-gap: 12px; */
    overflow-y: scroll;
    height: 250px;
    padding: 12px;
}

.MemberMetricsModal_timelineItemMessage__25_gS{
    font-size: 15px;
    /* font-weight: 600; */
    color: var(--dark-black)
}

.MemberMetricsModal_timelineItemTime__3wxNf {
    font-size: 12px;
    margin-left: 6px;
    color: var(--text-secondary-color);
}


.MemberMetricsModal_timelineIcon__3xIHT {
    font-size: 18px;
    color: var(--primary);
    font-weight: 700;
}
.IntroductionsManagementPage_container__3M2QX {
    max-width: var(--max-width-default);
}

.IntroductionsManagementPage_content__2DP5g {
    margin-top: 30px;
}

.IntroductionsManagementPage_sideBySide__3T9mP {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
    gap: 12px;
}

@media (max-width: 1200px) {
    .IntroductionsManagementPage_sideBySide__3T9mP {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 12px;
        gap: 12px;
    }    
}


.IntroductionsManagementPage_card__1Dgjj {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    margin-top: 12px;;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.IntroductionsManagementPage_cardHolder__2q1R5 {
    position: relative;
}

.IntroductionsManagementPage_cardHeader__2y531{
    font-weight: 700;
    font-size: 16px;
    color: var(--dark-black);
}

.IntroductionsManagementPage_createIntroButton__6Qn6J {
    position: absolute; 
    right: 20px;
    top: 20px;
    /* margin-top: 12px; */
    font-weight: bold !important;
}

.IntroductionsManagementPage_introList__2mACV {
    display: flex;
    flex-direction: column;
    margin: 16px 0px;
    overflow-y: auto;
    padding: 10px 0px;
    height: 320px;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.IntroductionsManagementPage_introList__2mACV::-webkit-scrollbar {
    display: none;
}

.IntroductionsManagementPage_introItem__2S4aP {
    display: flex;
    flex-direction: row;
    width: 100%;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    align-items: center;
}

.IntroductionsManagementPage_meetingItem__Fn2HU {
    cursor: pointer;
}

.IntroductionsManagementPage_introTitle__3bx3G {
    margin-left: 8px;
    color: var(--soft-black);
    /* font-size: 15px; */
    /* color: var(--text-secondary-color); */
    /* font-weight: 600; */
}

.IntroductionsManagementPage_teamAvatar__2TbR0 {
    width: 24px;
    height: 24px;
}

.IntroductionsManagementPage_introStatusTag__1aIk4 {
    font-size: 12px !important;
    font-weight: 600;
    margin-left: auto;
}

.IntroductionsManagementPage_introName__2tf7H {
    font-weight: 700;
    color: var(--soft-black);
    cursor: pointer;
}

.intro-list .ant-list-item-action {
    margin-left: 8px;
}

.IntroductionsManagementPage_deleteIntroButton__2_att {
    color: var(--red);
    font-weight: 600;
}

.IntroductionsManagementPage_moreItem__22x0q {
    padding: 4px;
    /* box-shadow: rgba(180, 180, 180, 0.708) 0px 0px 5px; */
    display: flex;
    justify-content: center;
    font-weight: 800;
    font-size: 18px;
    align-items: center;
    border-radius: 3px;
    cursor: pointer;
}

.IntroductionsManagementPage_meetingStatus__10hH2 {
    font-size: 12px !important;
    font-weight: 600;
    margin-left: auto;
}

.IntroductionsManagementPage_meetingHeader__41ush {
    display: flex;   
    flex-shrink: 0;
    /* justify-content: center; */
    align-items: center;
    margin-left: 8px;
    color: var(--soft-black);
} 

.NewIntroModal_container__36I0d {
    width: 100vw !important;
    max-width: 800px;
    overflow: hidden;
}

.NewIntroModal_content__3Rsws {
    padding-top: 20px;
    /* width: 900px; */
    min-height: auto;
}

.NewIntroModal_choosingContainer__jtjpW {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: row;
}

@media (max-width: 599px) {
    .NewIntroModal_choosingContainer__jtjpW {
        flex-direction: column;
    }
}

.NewIntroModal_choosingBoxContainer__1Qo_L {
    display: flex;
    width: 100%;
    flex-direction: column;
    flex: 1 1;
}

.NewIntroModal_memberChoosingBoxContainer__1Y_6o {
    border: 1px var(--kinky-grey) solid;
    padding: 30px 20px;
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    cursor: pointer;
}

.NewIntroModal_selectBox__22jVk {
    border: 1px var(--easy-grey) dashed !important;
    transition: all 0.3s;
}

.NewIntroModal_selectBox__22jVk:hover {
    border-color: var(--primary) !important;
}

.NewIntroModal_header__3oLdS {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 12px;
}

.NewIntroModal_arrow__1ojcL {
    width: 50px;
}

@media (max-width: 599px) {
    .NewIntroModal_arrow__1ojcL {
        transform: rotate(90deg);
    }
}

.NewIntroModal_texts__1mEFb {
    margin-left: 24px;
}

.NewIntroModal_choosingBoxHeader__ZRIj8 {
    font-weight: 500;
    font-size: 16px;
    /* text-decoration: underline;
    text-decoration-color: #b5b5b5;
    text-decoration-style: dotted; */
    color: var(--text-secondary-color);
    cursor: pointer;
}

.NewIntroModal_choosingBoxSubHeader__1kyLU {
    color: var(--text-secondary-color);
    margin-top: 0px;
    font-size: 14px;
}

.NewIntroModal_flexRow__1wxm_ {
    display: flex;
    align-items: center;
}

.NewIntroModal_arrow__1ojcL {
    font-size: 20px;
    margin-top: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.NewIntroModal_choosingBoxMemberName__UxYAn {
    font-size: 16px !important;
    font-weight: 700;
}

.NewIntroModal_info__NGp1i {
    margin-top: 18px;
}

.NewIntroModal_introducer__RQZYw {
    margin-top: 30px;
}

.NewIntroModal_introducerButton__2HlQq {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 12px 20px;
    font-size: 15px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: 1px solid var(--easy-grey);
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.NewIntroModal_menuButton__3MHfD {
    display: flex;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 8px;
    font-size: 15px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.NewIntroModal_introducerName__n2-m1 {
    margin-left: 12px;
    font-weight: 600;
}

.NewIntroModal_youBadge__3fqZI {
    margin-left: 12px;
    padding: 0px 4px;
    font-size: 9px;
    background-color: var(--ok-green);
    border-radius: 4px;
    color: white;
}

.NewIntroModal_downIcon__2Vz4_ {
    margin-left: 8px;
}

.NewIntroModal_description__1mjVH {
    margin-top: -12px;
    font-size: 15px;
    color: var(--text-secondary-color);
}

.NewIntroModal_choosingBoxLocation__1kuFW {
    margin-top: 2px;
    font-size: 12px;
    font-weight: 600;
    color: var(--text-secondary-color);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.NewIntroModal_location__1RRTv {
    display: flex;
    align-items: center;
}

.NewIntroModal_alert__2Qz6S {
    border-color: var(--primary);
    background-color: var(--primary-contrast-light);
}

.NewIntroModal_notesInput__1MTiY {
    margin-top: 18px;
    font-weight: 600 !important;
}

.NewIntroModal_previewButton__1rDgW {
    /* position: absolute; */
    /* border-color: #8800ff;
    color: #8800ff; */
    /* background-color: #8800ff15; */
}


.NewIntroModal_previewHeader__31qyX {
    margin-bottom: 4px;
    font-weight: 700;
    color: black;
    font-size: 16px;
}

.NewIntroModal_subject__lvGEq {
    font-size: 16px;
    color: black;
    font-weight: 600;
}

.NewIntroModal_emailContent__uIioe {
    margin-top: 10px;
    color: var(--dark-purple);   
}
.MemberSearchPopover_addMemberContainer__c_0Ph {
    display: flex;
    width: 280px;
    flex-direction: column;
}

.MemberSearchPopover_addMemberButton__2hWb3 {
    margin: 8px 0px;
}

.MemberSearchPopover_attendeeOption__3XoOR {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.MemberSearchPopover_attendeeAvatar__EgPoi {
    margin-right: 12px;
    width: 24px;
    height: 24px;
    margin-left: 8px;
    flex-shrink: 0;
}

.MemberSearchPopover_attendeeName__217Gv {
    color: var(--text-secondary-color);
    font-size: 14px;
    color: var(--dark-black);
    margin-right: 8px;
}

.MemberSearchPopover_userSelect__k6p4n {
    width: 100%;
    height: auto;
}

.member-search-popover .ant-select-show-search.ant-select-multiple .ant-select-selector {
    height: auto;
    min-height: 60px;
}
.member-search-popover .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    height: auto;
    font-weight: 600;
    padding: 4px !important;
    border-radius: 4px;
    border-color: rgb(220, 220, 220);
    background-color:#f2f2f2;
}


.IntroRequestInfoModal_container__19oa_ {
    
}


.IntroRequestInfoModal_content__1tsHi {
    padding: 0px 0px;
    /* width: 900px; */
    min-height: auto;    
}

.IntroRequestInfoModal_header__Kw6MR {
    margin-top: 12px;
    margin-bottom: 6px;
    font-weight: 700;
}

.IntroRequestInfoModal_bubblesContainer__1OmV9 {
    margin: 24px 0px 20px 0px;
    display: flex;
    flex-direction: row;
}

.IntroRequestInfoModal_purpose__1ligz {
    font-size: 15px;
}

.IntroRequestInfoModal_userAvatar__380k4 {
    margin-right: -8px;
}

.IntroRequestInfoModal_timeline__3oKuf {
    margin-top: 50px;
}

.IntroRequestInfoModal_timelineItemMessage__2AtT6{

}

.IntroRequestInfoModal_timelineItemTime__2WBET {
    font-size: 12px;
    margin-left: 4px;
    color: var(--text-secondary-color);
}

.IntroRequestInfoModal_names__3OqUY {
    font-size: 16px;
    font-weight: 700;
}

.IntroRequestInfoModal_timelineIcon__GOQfU {
    font-size: 16px;
}


.IntroRequestInfoModal_itemId__33SW7  {
    font-size: 14px;
    color: var(--text-secondary-color);
    margin-top: 12px;
}
.MeetingInfoModal_container__urO4W {
    
}

.MeetingInfoModal_header__1D0V5 {
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 700;
}


.MeetingInfoModal_content__3Ynkq {
    padding: 0px 0px;
    /* width: 900px; */
    min-height: auto;    
}

.MeetingInfoModal_teamAvatar__25sHU {
    width: 60px;
    height: 60px;
}

.MeetingInfoModal_itemContent__1Rh6h {
    display: flex;
}

.MeetingInfoModal_itemContent__1Rh6h > * {
    margin-right: 8px;
}

.MeetingInfoModal_userTitle__1x1BU {
    font-weight: 600;
    cursor: pointer;
}

.MeetingInfoModal_detailsContainer__1dIyL {

    margin-bottom: 40px;
}

.MeetingInfoModal_date__t8hvt {
    color: var(--text-secondary-color);
}

.MeetingInfoModal_meetingLink__3GCQ7 {
    margin-top: 18px;
}

.MeetingInfoModal_subHeader__2CwAz {
    font-weight: 600;
    margin-bottom: 4px;
}

.MeetingInfoModal_link__373NB {
    margin-top: 4px;
    color: var(--text-secondary-color);

}

.EditUserModal_companySearchHit__3YBbz {
    display: flex;
    align-items: center;
    height: 40px;
}

.EditUserModal_companySearchName__2v7v0 {
    font-weight: 600;
}

.EditUserModal_companySearchLogo__1i1V4 {
    margin-right: 12px;
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 2px;
    border: 1px #f2f2f2 solid;
}

.EditUserModal_referrerUserContainer__2zej5 {
    margin-top: 20px;
    display: flex;
}

.EditUserModal_referrerUserContainer__2zej5 div {
    margin-left: 12px;
    font-size: 15px;
    color: var(--text-secondary-color);
}

.EditUserModal_addNewButtonLink__1ajcP {
    padding: 8px 0px;
    font-weight: 600;
    color: var(--primary);
}

.EditUserModal_sideBySide__1KZ2S {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.EditUserModal_sideBySide__1KZ2S > * {
    margin-right: 16px;
}

.EditUserModal_subHeader__3q7Ze {
    font-weight: 600;
    color: var(--dark-black);
    margin-bottom: 14px;
}

.EventInfoPage_container__15bPF {
    position: relative;
    max-width: var(--max-width-default);
    padding-bottom: 30px;
}

.EventInfoPage_detailsContainer__xMpKQ {
    display: flex;
}

@media (max-width: 599px) {
    .EventInfoPage_detailsContainer__xMpKQ {
        flex-direction: column;
    }
}

.EventInfoPage_mainDetails__3IRaY {
    position: relative;
    width: 60%;
    margin-right: 16px;
    padding: 30px;
    overflow: hidden;
}

@media (max-width: 599px) {
    .EventInfoPage_mainDetails__3IRaY {
        width: 100%;
        margin-right: 0px;
    }
}

.EventInfoPage_extraDetails__3e3ud {
    display: flex;
    flex-direction: column;
    width: 40%;
    position: relative;
    position: -webkit-sticky;
    position: sticky;
    top: calc(var(--header-size) + 0px);
    display: flex;
    width: 100%;
    height: auto;
    margin-top: 0;
    flex-direction: column;
    align-self: flex-start;
    flex: 1 1;
}

.EventInfoPage_sticky__3Z-Jb {
    position: fixed;
    top: 60px;
    width: 100%;
}

@media (max-width: 599px) {
    .EventInfoPage_extraDetails__3e3ud {
        margin-top: 16px;
        width: 100%;
        margin-right: 0px;
    }
}

.EventInfoPage_regsiterContainer__1pFmB {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 16px;
    flex-shrink: 0;
}

.EventInfoPage_registerSnippet__3PZD0 {
    font-weight: 700;
    margin-bottom: 16px;
}

.EventInfoPage_eventTitle__2oNuF {
    font-size: 22px;
    line-height: 1.6;
    font-weight: bold;
    width: 80%;
}

.EventInfoPage_liveBadge__3HYgC {
    margin-left: 8px;
}

.EventInfoPage_divider__1BhAQ {
    height: 1px;
    width: 100%;
    background-color: #e9e9e9;
    margin-top: 20px;
    margin-bottom: 20px;
}

.EventInfoPage_dateContainer__1qibB {
    display: inline-flex;
}

.EventInfoPage_date__PmtfB {
    display: flex;
    align-items: center;
    color: #727C8B;
    font-weight: 600;
    font-size: 15px;
}

@media (max-width: 599px) {
    .EventInfoPage_dateContainer__1qibB {
        display: flex;
        flex-direction: column;
    }
    .EventInfoPage_date__PmtfB {
        line-height: 1.4;
    }
    .EventInfoPage_addToCalendar__vL_hk {
        display: flex;
        margin-top: 8px;
    }
}

.EventInfoPage_icon__2wmIa {
    font-size: 14px;
    color: #727C8B;
}

.EventInfoPage_location__1TGqA {
    margin-top: 14px;
}

.EventInfoPage_miniHeader__VUPG8 {
    margin-top: 44px;
    font-size: 18px;
    font-weight: bold;
}

.EventInfoPage_speakersGrid__2N797 {
    margin-top: 24px;
    display: grid;
    grid-row-gap: 16px;
    row-gap: 16px;
    grid-column-gap: 16px;
    -webkit-column-gap: 16px;
            column-gap: 16px;
    grid-template-columns: 1fr 1fr;
}

@media (max-width: 599px) {
    .EventInfoPage_speakersGrid__2N797 {
        grid-template-columns: 1fr;
    }
}

.EventInfoPage_dateAndLocationContainer__ps3cH {
    margin-top: 16px;
}

.EventInfoPage_date__PmtfB>div {
    margin-left: 12px;
}

.EventInfoPage_eventDescription__NZqR0 {
    margin-top: 44px;
    line-height: 1.6;
}

.EventInfoPage_adminSection__2dE4p {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 16px;
}

.EventInfoPage_adminOptionsButton__3NcfK {
    font-size: 12px;
    opacity: 0.8;
    color: var(--text-secondary-color);
    margin-top: 12px;
    cursor: pointer;
}

.EventInfoPage_adminSnippet__2hYev {
    font-size: 12px;
    color: var(--text-secondary-color);
    margin-top: 12px;
}

.EventInfoPage_registerButton__37GyJ {
    width: 100%;
    font-weight: 700;
    height: 50px;
}

.EventInfoPage_startEventButton__1tiDu {
    margin-bottom: 8px;
}

.EventInfoPage_endEventButton__2mRml {
    margin-bottom: 8px;
    color: #ff7010;
    background-color: white;
    border-color: #ff7010;
}

.EventInfoPage_deleteEventButton__3u8Yo {
    margin-top: 8px;
    border-color: red;
    color: red;
}

.EventInfoPage_attendeesContainer__3tcfJ {
    margin-top: 50px;
}

@media (max-width: 599px) {
    .EventInfoPage_attendeesContainer__3tcfJ {
        padding-bottom: 48px;
    }
}

.EventInfoPage_membersContainer__xlRMB {
    margin-top: 28px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.EventInfoPage_membersContainer__xlRMB>* {
    margin-right: 16px;
    margin-bottom: 16px;
}

@media (max-width: 599px) {
    .EventInfoPage_membersContainer__xlRMB>* {
        margin-right: 0px;
    }
}

.EventInfoPage_beFirstToJoinText__18j2Y {
    text-align: center;
    margin-top: 20px;
    color: var(--text-secondary-color);
}

.EventInfoPage_attendeesSnippet__3OC5I {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.EventInfoPage_attendessSnipetText__3QMCn {
    text-align: center;
    margin-top: 14px;
    font-size: 14px;
    color: var(--text-secondary-color);
}

.EventInfoPage_notificationIcon__n6-mR {
    color: var(--primary);
}

.EventInfoPage_navBarMobile__2sIb_ {
    display: none;
}

@media (max-width: 599px) {
    .EventInfoPage_navBarMobile__2sIb_ {
        justify-content: center;
        align-items: center;
        padding: 12px;
        position: fixed;
        display: flex;
        width: 100%;
        bottom: 0px;
        left: 0px;
        box-shadow: 0px -4px 10px rgba(211, 211, 211, 0.5);
        background-color: white;
    }
}

.EventInfoPage_themeStrip__2T75E {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 6px;
    background-color: var(--primary);
}

.EventInfoPage_themesContainer__1578X {
    margin-top: 10px;
}

.EventInfoPage_applicationButtons__3H1kh {
    margin-top: 4px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 4px;
    -webkit-column-gap: 4px;
            column-gap: 4px;
}

.EventInfoPage_approveButton__4vNg7 {
    border-color: var(--ok-green);
    color: var(--ok-green);
}

.EventInfoPage_denyButton__1_Y6Q {
    border-color: var(--red);
    color: var(--red);
}

@media (max-width: 599px) {
    .EventInfoPage_applicationContainer__1kjGl {
        width: 100%;
    }
}
.SpeakerItem_container__27bp_ {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
}

.SpeakerItem_colored__1wxGM {
    padding: 20px 12px;
    border-radius: 5px;
    background-color: #fbfcff;
    border: 1px solid #f2f2f2;
}

.SpeakerItem_texts__2Y3Rt {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.SpeakerItem_nameContainer__3XqG- {
    display: flex;
    align-items: center;
}

.SpeakerItem_name__2AcLe {
    padding-bottom: 0px;
    color: var(--headers-color);
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    margin-bottom: 0px !important;
}

.SpeakerItem_host__2aj3c {
    margin-top: -3px;
    margin-left: 6px;
    font-size: 10px;
    border-radius: 5px;
    padding: 2px 6px 2px 6px;
    background-color: var(--primary);
    color: white;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}

.SpeakerItem_title__1uQn_ {
    padding-bottom: 2px;
    margin-top: 4px;
    line-height: 1.4;
    color: var(--text-secondary-color);
    font-size: 12px;
    margin-bottom: 0px !important;
}

.EventPromo_container__2hUlQ {
    width: 100%;
    height: 100%;
    max-height: 250px;
    background: url(/static/media/invite-bg.fe3c0ed5.jpg) no-repeat;
    animation: EventPromo_movingBackground__3ShU1 8s ease infinite;
    background-size: cover;
    color: white;
    padding: 0;
    text-align: center;
    line-height: 1.4;
    position: relative;
    overflow: hidden;
}

.EventPromo_overlay__AmaIS {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1 );
    padding: 38px 22px;
}

.EventPromo_header__1CtJW {
    font-size: 18px;
    font-weight: 700;
}

.EventPromo_subHeader__C1Fa9 {
    margin-top: 2px;
    font-size: 15px;
}

.EventPromo_countdownWrapper__3yVno {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
}

.EventPromo_countdownWrapper__3yVno > * {
    margin-right: 10px;
}

.EventPromo_countdownItem__kImOp {
	background-color: var(--primary);
	font-size: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 44px;
	height: 44px;
    font-weight: bolder;
    border-radius: 5px;
}

.EventPromo_countdownTimeDescription__-QxDL {
    margin-top: 3px;
    font-size: 12px;
}

.EventPromo_countdownItem__kImOp span {
	font-size: 10px;
	font-weight: 400;
	text-transform: uppercase;
}

.EventPromo_activatingTitle__3J697 {
	margin-left: 8px;
	margin-right: 8px;
	font-weight: 500;
}

@media (max-width: 599px) {
	.EventPromo_countdownItem__kImOp { 
		width: 45px;
		height: 45px;

	}
}

.EventPromo_joinButton__3lxzz {
    font-weight: 700;
    width: auto;
    margin-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    background-color: var(--ok-green);
    border: 1px solid rgba(255, 255, 255, 0.954);
}


.EventPromo_recordingButton__93KQb {
    font-weight: 700;
    width: auto;
    margin-top: 20px;
    border: 1px solid rgba(255, 255, 255, 0.721);
}


.EventPromo_animation__Ruago {
    position: absolute;
    bottom: -80px;
    width: 100%;
    z-index: 1;
}

@keyframes EventPromo_movingBackground__3ShU1 {
	0% {
		background-position: 00% 100%;
	}
	50% {
		background-position: 10% 10%;
	}
	100% {
		background-position: 00% 100%;
	}
}

.EventPromo_addToCalendar__CLiD1 {
    margin-top: 24px;   
    font-size: 14px;
    font-weight: 600;
    /* text-decoration: underline; */
    cursor: pointer;
}

.EventPromo_speakerAccess__1ik1z {
    /* position: absolute; */
    right: 12px;
    bottom: 12px;
    margin-top: 12px;
    color: white;
}
.EventPromo_backstageButton__3F9UJ {
    background-color: transparent;
    color: white;
    border-color: white;
    font-size: 12px;
}

.EventTheme_container__wsKH9 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.EventTheme_theme__1GDz5 {
    padding: 5px 8px;
    border-radius: 2px;
    font-size: 12px;
    color: white;
    margin-right: 8px;
    font-weight: 600;
}

.AttentedModal_container__3fuRd {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.AttentedModal_header__3XUda {
    font-weight: 800;
    font-size: 28px;
    line-height: 1.4;
    text-align: center;
}

.AttentedModal_subHeader__3AjM6 {
    margin-top: 20px;
    text-align: center;
    margin-bottom: 20px;
    font-size: 16px;
    color: var(--text-secondary-color);
}

.AttentedModal_calendarAnimation__3C78n {
    width: 200px;
    margin-bottom: 30px;
}

.AttentedModal_centered__3_1lu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.AttentedModal_actionButtonsContainer__eAzoC {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
}

.AttentedModal_actionButton__3NxXO {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 24px;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none; 
    border: 1px #d8d8d8 solid;
    transition: ease 0.4s;
}

.AttentedModal_actionButton__3NxXO:hover {
    transform: scale(1.02);
}

.AttentedModal_actionButton__3NxXO img {
    width: 80px;
    margin-bottom: 12px;
}

.AttentedModal_actionButton__3NxXO div {
    font-weight: 700;
    text-align: center;
}
.AddQuestionModal_container__2j8jo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 20px 5px 20px;
}

.AddQuestionModal_header__38zzK {
    font-weight: 800;
    font-size: 28px;
    line-height: 1.4;
    text-align: center;
}

.AddQuestionModal_subHeader__32MQr {
    margin-top: 20px;
    text-align: center;
    margin-bottom: 20px;
} 

.AddQuestionModal_textArea__hTiRh {
    margin-top: 16px;
    width: 100%;
}


.AddQuestionModal_loginButton__zQyim {
    font-weight: 600;
    width: 100%;
}


.question-form textarea {
    min-height: 100px;
}

.AddQuestionModal_askingAsContainer__V7X1X {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    font-size: 12px;
    color: var(--text-secondary-color);
}

.AddQuestionModal_askingAsContainer__V7X1X div {
    margin-left: 8px;
}
.AddQuestionModal_askingAsContainer__V7X1X span {
    font-weight: 600;
}
.EventQuestions_container__1WB4x {
    margin-top: 70px;
}

.EventQuestions_questionsContainer__2KgtP {
    margin-top: 28px;
    margin-bottom: 20px;
    display: grid;
    grid-row-gap: 16px;
    row-gap: 16px;
    grid-column-gap: 16px;
    -webkit-column-gap: 16px;
            column-gap: 16px;
    grid-template-columns: 1fr 1fr 1fr;
}

@media (max-width: 599px) {
    .EventQuestions_questionsContainer__2KgtP {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 599px) {
    .EventQuestions_questionsContainer__2KgtP {
        margin-right: 0px;
    }
}

.EventQuestions_question__3pdSB {
    min-height: 130px;
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
}

.EventQuestions_title__1M5z0 {
    font-weight: 600;
    line-height: 1.4;
}

.EventQuestions_askedByContainer__11Yfv {
    display: flex;
    margin-top: auto;
    flex-direction: row;
    align-items: center;
}

.EventQuestions_askedByContainer__11Yfv div {
    margin-left: 8px;
    font-size: 12px;
    color: var(--text-secondary-color);
}
.EventCapabilities_container__19OMe {
    display: flex;
    flex-direction: column;
}

.EventCapabilities_header__2wn1Z {
    margin-top: 44px;
    font-size: 18px;
    font-weight: bold;
}

.EventCapabilities_description__26MrI {
    font-size: 15px;
    margin-top: 12px;
}

.EventCapabilities_capabilitiesGrid__3-_Pm {
    margin-top: 24px;
    display: grid;
    grid-row-gap: 20px;
    row-gap: 20px;
}

.EventCapabilities_capability__3vPYd {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* padding: 12px; */
    /* border: 1px var(--text-secondary-color) solid; */
}

.EventCapabilities_capabilityIcon__3hfm1 {
    height: 24px;
    
}

.EventCapabilities_capabilityTexts__19w-q {
    margin-left: 25px;
}

.EventCapabilities_capabilityHeader__2bgMf {
    font-weight: 600;
    font-size: 16px;
    color: var(--dark-black);
}

.EventCapabilities_capabilityDescription__15-l1 {
    margin-top: 10px;
    font-size: 14px;
    line-height: 1.4;
    color: var(--text-secondary-color);
}
.ManageAttendeesModal_container__1lo7x {
    display: flex;
    flex-direction: column;
}

.ManageAttendeesModal_content__33yHl {
    position: relative;
    padding: 30px;
}

.ManageAttendeesModal_topPart__2crlY {
    display: flex;
}

.ManageAttendeesModal_header__1_5tP {
    font-weight: 800;
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 22px;
}

.ManageAttendeesModal_subHeader__3dK_O {
    margin-top: 20px;
    text-align: center;
    margin-bottom: 20px;
} 

.ManageAttendeesModal_buttons__CEynk {
    margin-left: auto;
    margin-right: 16px;
}

.ManageAttendeesModal_addMemberContainer__2--2N {
    display: flex;
    width: 200px;
    flex-direction: column;
}

.ManageAttendeesModal_addMemberButton__3LDnI {
    margin-top: 8px;
}

.ManageAttendeesModal_attendeeOption__17fPt {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ManageAttendeesModal_attendeeAvatar__irKJt {
    margin-right: 12px;
    width: 24px;
    height: 24px;
    margin-left: 8px;
    flex-shrink: 0;
}

.ManageAttendeesModal_attendeeName__2FMW3 {
    color: var(--text-secondary-color);
    font-size: 14px;
    color: var(--dark-black);
    margin-right: 8px;
}

.ManageAttendeesModal_userSelect__1fUgJ {
    width: 100%;
    height: auto;
}

.manage-attendees-modal .ant-select-show-search.ant-select-multiple .ant-select-selector {
    height: auto;
    min-height: 60px;
}
.manage-attendees-modal .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    height: auto;
    font-weight: 600;
    padding: 4px !important;
    border-radius: 4px;
    border-color: rgb(220, 220, 220);
    background-color:#f2f2f2;
}

.ManageAttendeesModal_exportButton__2IsTo {
    margin-left: 8px;
}
.EventMetrics_container__6EXVI {}

.EventMetrics_content__2ogPv {
    padding: 30px 0px 0px 0px;
}

.EventMetrics_grid__3bS8n {
    display: grid;
    grid-column-gap: 16px;
    -webkit-column-gap: 16px;
            column-gap: 16px;
    grid-template-columns: 2fr 1.5fr;
}

@media (max-width: 599px) {
    .EventMetrics_grid__3bS8n {
        grid-template-columns: 1fr;
    }
}

.EventMetrics_metrics__2RD0r {
    /* background-color: red; */
    display: flex;
    flex-direction: column;
}

.EventMetrics_charts__3Mpbs {
    /* background-color: green; */
}

.EventMetrics_sectionHeader__3WwMf {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 12px;
    display: flex;
}

.EventMetrics_statsStrip__BeNh5 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 14px;
    margin-bottom: 34px;
}

@media (max-width: 599px) {
    .EventMetrics_statsStrip__BeNh5 {
        grid-template-columns: 1fr;
    }
}

.EventMetrics_viewAll__2ZuVh {
    color: var(--text-secondary-color);
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    margin-top: 1px;
    margin-left: 15px;
}

.EventMetrics_statContainer__3d3w- {
    border: 1px solid var(--kinky-grey);
    padding: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.EventMetrics_statTitle__f8ueH{
    font-size: 16px;
    margin-bottom: 16px;
    font-weight: 600;
    color: var(--dark-black);
}

.EventMetrics_statsNumbersContainer__1XOg5 {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-end;
   
}

.EventMetrics_statsBigNumber__3n0Mu {
    font-size: 34px;
    color: var(--dark-black);
    font-weight: 700;
}

.EventMetrics_statsSideNote__jKGrW{
    color: var(--text-secondary-color);
    font-weight: 600;
    margin-left: 6px;
    margin-bottom: 2px;
}

.EventMetrics_bubbleText__2DSvn {
    font-size: 14px;
    background-color: #fbecff;
    padding: 4px 6px;
    color: #a42ece;
    font-weight: 600;
    border-radius: 3px;
    margin-left: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.EventMetrics_feedbacksDrawer__2jPHi {
    display: grid;
    grid-gap: 18px;
    gap: 18px;
}

.EventMetrics_charts__3Mpbs {
    padding: 0px 0px;
    display: grid;
    grid-row-gap: 14px;
    row-gap: 14px;
}

.EventMetrics_chart__14l8R {
    border: 1px solid var(--kinky-grey);
    padding: 14px;
    /* display: flex;
    flex-direction: column; */
}

.EventMetrics_chartContainer__38Rxm {
    height: 240px;
    width: 100%;
}

.EventMetrics_filterChartHeader__ecD5w {
    display: flex;
    align-items: center;
    font-weight: 700;

}

.EventMetrics_filterChartHeader__ecD5w > * {
    margin-left: 8px;
}

.EventMetrics_bubblesContainer__2EjKz {
    margin-left: auto;
    display: flex;
    flex-direction: column;
}

.EventMetrics_audienceRadioGroup__25aQq {
    margin-left: auto;
}
.GuestInviteModal_content__2dNQ7 {
    padding: 20px;
}

.GuestInviteModal_grid__2KK0p {
    display: grid;
    grid-gap: 24px;
    gap: 24px;
    grid-template-columns: 1fr;
}

.GuestInviteModal_form__ASipB {
    grid-gap: 12px;
    gap: 12px;
    /* background-color: rgb(237, 237, 237); */
    display: grid;
    padding: 20px;
    border: 1px solid var(--material-color-grey-200);
    grid-template-columns: 1fr 1fr;
}

.GuestInviteModal_header__18V_C {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 8px;
}

.GuestInviteModal_subHeader__3oXsM {
    font-size: 16px;
    color: var(--text-secondary-color);
}

.GuestInviteModal_label__DRcBK {
    font-weight: 700;
    margin-bottom: 12px;
}

.GuestInviteModal_input__3OvCl {
    width: 100%;
}

.GuestInviteModal_inputContainer__BS2wc {
    margin-top: 12px;
    display: flex;
}

.GuestInviteModal_copyButton__2AF3d {
    height: 60px;
}

.guest-invite-modal .ant-select-selection-item {
    padding-top: 10px !important;
}

.guest-invite-modal .ant-select-selection-placeholder {
    padding-top: 10px !important;
}
.Tag_container__2SrMG {
    font-size: 14px;
    background-color: var(--primary-contrast-light);
    padding: 8px 12px;
    color: var(--primary);
    font-weight: 600;
}
.CompanyPage_container__202Au {
    height: 100%;
    display: flex;
    max-width: var(--max-width-default);
    flex-direction: column;
}

.CompanyPage_text__17P3Q {
    line-height: 1.4;
}

.CompanyPage_companyTitlesContainer__1yS2a {
    width: 100%;
}

.CompanyPage_companyHeaderContainer__322u3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
    position: relative;
}

@media (max-width: 599px) {
    .CompanyPage_companyHeaderContainer__322u3 {
        flex-direction: column;
        text-align: center;
    }
}

.CompanyPage_requestIntroButton__204hs {
    align-self: flex-end;
    font-weight: 600;
}

@media (max-width: 599px) {
    .CompanyPage_requestIntroButton__204hs {
        position: static;
        margin-top: 20px;
        width: 100%;
    }
}

.CompanyPage_cardBase__2tIw0 {
    padding: 30px;
    height: auto;
    color: var(--text-secondary-color);
}

@media (max-width: 599px) {
    .CompanyPage_cardBase__2tIw0 {
        padding: 20px;
    }
}

.CompanyPage_companyLogo__3Dm9K {
    margin-right: 20px;
    height: 80px;
    width: 80px;
    object-fit: contain;
    border: 1px solid #f2f2f2;
    border-radius: 5px;
}

@media (max-width: 599px) {
    .CompanyPage_companyLogo__3Dm9K {
        margin-bottom: 16px;
    }
}

.CompanyPage_companyName__3wHU_ {
    font-size: 18px;
    font-weight: 600;
}

.CompanyPage_companyShortDescription__3zmpk {
    margin-top: 10px;
    line-height: 1.6;
    color: var(--text-secondary-color);
    margin-bottom: 0 !important;
    margin-right: 20px;
    max-width: 700px;
}

.CompanyPage_sideBySide__3sjHH {
    display: flex;
}

@media (max-width: 599px) {
    .CompanyPage_sideBySide__3sjHH {
        flex-direction: column-reverse;
    }
}

.CompanyPage_info__lrggS {
    margin-right: 16px;
    overflow: hidden;
    display: grid;
    grid-row-gap: 40px;
    row-gap: 40px;
}

.CompanyPage_generalInfoBox__NhUIL {
    width: 440px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex-shrink: 0;
    margin-left: 20px;
    grid-row-gap: 20px;
    row-gap: 20px;
    display: grid;
    grid-template-rows: auto;
}

@media (max-width: 599px) {
    .CompanyPage_generalInfoBox__NhUIL {
        position: static;
        width: 100%;
        margin-bottom: 20px;
        margin-left: 0px;
    }
}

.CompanyPage_generalInfoBox__NhUIL .CompanyPage_paragraph__1bbc7 {
    margin-bottom: 0;
}

.CompanyPage_infoItem__2ISRL {
    display: flex;
    justify-content: space-between;
}

.CompanyPage_infoItemTitle__1WX4X {
    color: var(--text-secondary-color);
    font-weight: 600;
}

.CompanyPage_infoItemChildren__2Ybj0 {
    font-weight: 600;
    color: black;
}

.CompanyPage_socialContainer__1Pz__ {
    display: flex;
    flex-wrap: wrap;
}

.CompanyPage_socialContainer__1Pz__>* {
    margin-left: 10px;
}

.CompanyPage_socialIcon__3o9O7 {
    width: 25px;
    height: 25px;
}

.CompanyPage_info__lrggS .CompanyPage_description__1RBv3 {
    line-height: 1.5;
    color: var(--text-secondary-color);
}

.CompanyPage_subHeader__13Qgv {
    font-weight: 700;
    color: #00063c;
    margin-bottom: 16px;
}

.CompanyPage_bigHeader__zFGti {
    font-weight: 700;
    color: #00063c;
    font-size: 24px;
    margin-bottom: 16px;
}

.CompanyPage_industryTags__1JpYQ {
    display: flex;
    flex-wrap: wrap;
}

.CompanyPage_industryTags__1JpYQ>* {
    margin-right: 6px;
    margin-bottom: 6px;
}

.CompanyPage_funding__6Ky4Y {
    margin-top: 20px;
}

.CompanyPage_fundingQubes__2R_uf {
    display: flex;
    flex-wrap: wrap;
}

.CompanyPage_fundingQubes__2R_uf>* {
    margin-right: 16px;
}

@media (max-width: 599px) {
    .CompanyPage_fundingQubes__2R_uf>* {
        margin-right: 0px;
        margin-bottom: 16px;
    }
}

.CompanyPage_cardGrid__2qkm1 {
    display: grid;
    grid-row-gap: 40px;
    row-gap: 40px;
}

.CompanyPage_qube__dlq-D {
    border: 1px var(--kinky-grey) solid;
    display: flex;
    width: 180px;
    height: 180px;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

@media (max-width: 599px) {
    .CompanyPage_qube__dlq-D {
        width: 100%;
    }
}

.CompanyPage_qubeTitle__2rgMD {
    margin-top: 20px;
    padding-bottom: 0;
    color: var(--primary);
    font-size: 26px;
    font-weight: 700;
    text-align: center;
}

.CompanyPage_qubeSubTitle__3blfw {
    margin-top: 12px;
    color: #51596c;
    font-size: 14px;
    font-weight: 600;
}

.CompanyPage_qubeImg__3QZsM {
    width: 35px;
}

.CompanyPage_members__12ApB {
    margin-top: 60px;
}

@media (max-width: 599px) {
    .CompanyPage_members__12ApB {
        padding-bottom: 48px;
    }
}

.CompanyPage_membersContainer__3YcCf {
    margin-top: 28px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.CompanyPage_membersContainer__3YcCf>* {
    margin-right: 16px;
    margin-bottom: 16px;
}

@media (max-width: 599px) {
    .CompanyPage_membersContainer__3YcCf>* {
        margin-right: 0px;
    }
}

.CompanyPage_statusContainer__CGNbR {
    margin-top: 10px;
}

@media (max-width: 599px) {
    .CompanyPage_statusContainer__CGNbR {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.CompanyPage_statusTag__mJwnB {
    padding: 6px 12px;
    border-radius: 2px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f94453;
    font-size: 12px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: 600;
}

.CompanyPage_statusIcon__2oq8w {
    width: 8px;
    margin-right: 8px;
}

.CompanyPage_editCompany__KS7oR {
    font-size: 12px;
}

CompanyIntroModal.CompanyIntroModal_container__1PZ8C {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 20px 5px 20px;
}

.CompanyIntroModal_header__16q3H {
    font-weight: 800;
    font-size: 28px;
    line-height: 1.4;
    text-align: center;
}

.CompanyIntroModal_subHeader__2Y1yh {
    margin-top: 20px;
    text-align: center;
    margin-bottom: 20px;
} 

.CompanyIntroModal_textArea__2hLJw {
    margin-top: 16px;
    width: 100%;
}


.CompanyIntroModal_loginButton__3OOSN {
    font-weight: 600;
    width: 100%;
}


.company-intro-form textarea {
    min-height: 100px;
}

.CompanyIntroModal_askingAsContainer__1GQO9 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    font-size: 12px;
    color: var(--text-secondary-color);
}

.CompanyIntroModal_askingAsContainer__1GQO9 div {
    margin-left: 8px;
}
.CompanyIntroModal_askingAsContainer__1GQO9 span {
    font-weight: 600;
}


.AmbassadorTab_container__2N03n {
    color: var(--text-secondary-color);
}

.AmbassadorTab_generateButton__1K0lu {
    margin-top: 20px;
}

.AmbassadorTab_yourLinksContainer__1p-6g {
    margin-top: 50px;
}

.AmbassadorTab_link__oq6hP {
    font-weight: 600;
}

.AmbassadorTab_referrals__3DwNT {
    margin-top: 50px;
}

.AmbassadorTab_linkModal__SZUv3 {

}

.AmbassadorTab_linkModalContent__otZfa {

}

.AmbassadorTab_subHeader__DWvQs {
    font-weight: 700;
    margin-bottom: 12px;
    font-size: 16px;
}

.AmbassadorTab_option__29jHB{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.UsersTab_container__1QJif {
    color: var(--text-secondary-color);
}

.UsersTab_title__yPqHe {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.UsersTab_name__2vfcZ {
    font-weight: 600;
}

.UsersTab_users__oVvW- {
    margin-top: 20px;
}

.UsersTab_badges__2TEIB {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.UsersTab_badges__2TEIB > * {
    margin-left: 8px;
}

.UsersTab_badge__pyLBk {
    padding: 2px 6px;
    font-size: 12px;
    background-color: var(--ok-green);
    border-radius: 4px;
    font-weight: 600;
    color: white;
}

.UsersTab_leadersBadge__kWmKx {
    background-color: var(--primary);
    color: white;
}

.UsersTab_guestBadge__1D9JF {
    background-color: #009d66;
    color: white;
}

.UsersTab_managerBadge__2mnEE {
    background-color: #1103a9;
    color: white;
}


.UsersTab_staffBadge__1ZJjY {
    background-color: #d00061;
    color: white;
}

.UsersTab_pendingBadge__w6pNJ {
    background-color: #9d7d00;
    color: white;
}

.UsersTab_filter__3hlR6 {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.UsersTab_usersCount__E23Et {
    font-size: 14px;
    color: var(--text-secondary-color);
    margin-left: 12px;
}

.UsersTab_exportButton__5If37 {
    margin-left: auto;
}


.ControlPanel_container__3H3_3 {
    max-width: var(--max-width-default);

}

.ControlPanel_tabs__3SamW {
    margin-top: 30px;
}

.ControlPanel_header__3qABJ {
    color: var(--dark-black);
    margin-bottom: 2px;
    font-size: 18px;
    font-weight: 700;
}

.ControlPanel_description__37HUU {
    color: var(--text-secondary-color);
    margin-top: 4px;
    font-size: 15px;
    font-weight: 400;
}




.SettingsTab_container__13NCF {
    color: var(--text-secondary-color);
    padding-bottom: 20px;
}

.SettingsTab_sectionsContainer__3sKrL {
    margin-top: 0px;
    display: grid;
    grid-gap: 18px;
    gap: 18px;
}

.SettingsTab_sideBySide__1l8Kn {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.SettingsTab_sideBySide__1l8Kn > * {
    margin-right: 16px;
}

.SettingsTab_label__3tljs {
    font-weight: 600;
    margin-right: 12px;
    margin-bottom: 8px;
    color: var(--text-secondary-color);
}

.SettingsTab_setting__3lPDY {
    margin-top: 20px;
}

.SettingsTab_threeItemsSetting__zOdAy {
    display: flex;
    flex-wrap: wrap;
    /* max-width: 400px; */
}

.SettingsTab_threeItemsSetting__zOdAy  > * {
    margin-right: 12px;
}

.SettingsTab_subHeader__15irl {
    font-weight: 700;
    color: var(--dark-black);
    margin-bottom: 14px;
}

.SettingsTab_input__2qy1G {
    /* height: 40px; */
    width: 60px;
}
.AboutPage_container__cPfTu {
    /* margin-top: 12px; */
    max-width: 900px;
}

.AboutPage_content__2mWLk {
    margin-top: 50px;
}

.AboutPage_team__3SQEO {
    margin-top: 80px;
}

.AboutPage_strip__3RNu2 {
    margin-top: 38px;
    display: flex;
    flex-direction: row;
}

@media (max-width: 599px) {
    .AboutPage_strip__3RNu2 {
        display: flex;
        flex-direction: column;
    }
}

.AboutPage_strip__3RNu2 > * {
    margin-right: 16px;
    margin-bottom: 16px;
    flex-shrink: 0;
    width: 220px;
}


@media (max-width: 599px) {
    .AboutPage_strip__3RNu2 > * {
        width: 100%;
    }
}

.AboutPage_title__VF3bY {
    /* justify-content: center; */
    display: flex;
    align-items: center;
}

.AboutPage_logo__1pp-J {
    height: 40px;
    margin-top: -15px;
    margin-right: 10px;
    margin-left: -12px;
}

@media (max-width: 599px) {
    .AboutPage_title__VF3bY {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
    .AboutPage_logo__1pp-J {
        height: 30px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 12px;
    }
}

.AboutPage_contactLink__mjmz3 {
    font-weight: 600;
}
.MeetingFeedback_container__KaM3U {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}


.MeetingFeedback_content__zlb-f {
    background: white;
    border-radius: 5px;
    width: 100%;
    max-width: 800px;
    padding: 24px 24px 0px 24px;
    margin: 12px;
    text-align: center;
    line-height: 1.6;
}


.MeetingFeedback_header__2qsYg {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 700;
}

.MeetingFeedback_subHeader__lNtOc {
    margin-top: 4px;
    color: var(--text-secondary-color);
}

.MeetingFeedback_rate__1nrJ_ {
    margin-top: 30px;
    transform: scale(2);
}


.MeetingFeedback_didNotMetButton__2YMss {
    margin-top: 50px;
}

.MeetingFeedback_phaseContainer__Ts9sc {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* padding: 10px 0px 40px 0px; */
}

.MeetingFeedback_ratePhase__2cLtE {
    height: 340px;
}

.MeetingFeedback_meetingNotesForm__AuhdP {
    margin-top: 20px;
    width: 100%;
}

.MeetingFeedback_meetingNotesForm__AuhdP textarea {
    width: 80%;
}

.MeetingFeedback_notesDoneButton__1Ne6H {

}
.MeetingRoomSider_container__1CSOY {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.MeetingRoomSider_container__1CSOY:hover .MeetingRoomSider_handler__hzvDH {
    opacity: 1;
}

.MeetingRoomSider_topHeader__261H_ {
    /* position: fixed;
    top: 0px; */
    height: auto;
    z-index: 10;
    width: 100%;
    background: white;
    display: flex;
    padding: 12px;
    align-items: center;
}

.MeetingRoomSider_headerTitle__3BKFe {
    font-weight: 600;
    font-size: 14px;
    color: var(--dark-purple);
}

.MeetingRoomSider_smallLogoButton__1agX1 {
    margin-left: auto;
}


.MeetingRoomSider_participantsInfo__ICPpd {
    overflow: auto;
    /* margin-top: 30px; */
    padding: 10px 24px 24px 24px;
    /* padding-right: 32px; */
}

.MeetingRoomSider_userBasicInfo__30elJ {
    display: flex;
    align-items: center;
}

.MeetingRoomSider_userBasicInfoTexts__1l34O {
    margin-left: 12px;
}

.MeetingRoomSider_userName__1-vhZ {
    font-size: 18px;
    font-weight: bold;
}

.MeetingRoomSider_userAvatar__1Pnmy {
    /* transform: scale(1.2); */
}

.MeetingRoomSider_title__1Fdts {
    margin-top: 8px;
    font-size: 15px;
    line-height: 1.6;
    color: var(--text-secondary-color);
}

.MeetingRoomSider_bio__14_vM {
    margin-top: 30px;
    line-height: 1.7;
    font-size: 15px;
    color: var(--dark-black);
}

.MeetingRoomSider_company__3N3ww {
    margin-top: 50px;
    line-height: 1.6;
}

.MeetingRoomSider_interests__10EvH {
    margin-top: 30px;

}

.MeetingRoomSider_location__3U4SA {
    margin-top: 30px;
}

.MeetingRoomSider_industryTags__1_gle {
    display: flex;
    flex-wrap: wrap;
}

.MeetingRoomSider_industryTags__1_gle>* {
    margin-right: 6px;
    margin-bottom: 6px;
}

.MeetingRoomSider_subHeader__3naly {
    font-weight: bold;
    color: var(--dark-black);
    font-size: 14px;
    margin-bottom: 16px;
}

.MeetingRoomSider_linkedCompany__2P_3S{
    font-weight: 600;
    text-decoration: underline;
    -webkit-text-decoration-color: #b5b5b5;
            text-decoration-color: #b5b5b5;
    -webkit-text-decoration-style: dotted;
            text-decoration-style: dotted;
    cursor: pointer;
}


.MeetingRoomSider_handler__hzvDH {
    height: 80px;
    width: 20px;
    top: 45%;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    position: absolute;
    left: -24px;
    display: flex;
    opacity: 0;
    justify-content: center;
    align-items: center;
    color: #a2a2a2;
    transition: all 0.3s;
    cursor: pointer;
    border: 1px solid #a2a2a2;
}

.MeetingRoomSider_handler__hzvDH:hover {
    background-color: rgba(255, 255, 255, 1);
    opacity: 1;
}


@media (max-width: 599px) {
    .MeetingRoomSider_handler__hzvDH {
        opacity: 1;
        color: var(--dark-purple);
        background-color: rgba(255, 255, 255, 0.9);
        border: 1px solid rgb(216, 216, 216);
        box-shadow: rgba(21, 21, 25, 0.708) 0px 10px 40px;
    }
    .MeetingRoomSider_handler__hzvDH:hover {
        background-color: rgba(255, 255, 255, 0.756);
    }
}

.MeetingRoomSider_handlerTablet__3Wlgl {
    opacity: 1;
}


.SmallLogoButton_logoContainer__3Yvpf {
    width: 43px;
    height: 43px;
    box-shadow: rgba(213, 213, 213, 0.708) 0px 0px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
    background-color: white;
    transition: all 0.3s;
}

.SmallLogoButton_logo__3K6ev {
    width: 20px;
    flex-shrink: 0;
}

.SmallLogoButton_logoContainer__3Yvpf:hover {
    transform: scale(1.1);
}
.VideoParticipant_container__2upa8 {
    background-color: #222226;
    overflow: hidden;
}

.VideoParticipant_content__3vFGN {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
} 

.VideoParticipant_contentSafariBug__360Kr {
    /* height: auto !important; */
}

.VideoParticipant_video__3dzGX {
    position: absolute;
    border-radius: 5px;
    object-position: center center;
    z-index: 1;
    transform: rotateY(180deg);
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.VideoParticipant_videoOverlay__5ZTfr {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100px;
    z-index: 2;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
}

.VideoParticipant_userInfo__1KR_e {
    position: absolute;
    bottom: 0;
    padding: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    z-index: 3;
}

.VideoParticipant_details__2J9VD {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.VideoParticipant_texts__11u02 {
    margin-left: 14px;
}

.VideoParticipant_participantName__3d19o {
    font-weight: 600;
    font-size: 14px;
    color: white;
}

.VideoParticipant_participantTitle__3WLXe {
    font-size: 12px;
    max-width: 100%;
    color: white;
    margin-bottom: 0 !important;
}

.VideoParticipant_controlButtons__e4IO_ {
    display: flex;
    flex-direction: row;
    justify-self: flex-end;
    align-self: flex-end;
    margin-left: auto;
}

.VideoParticipant_controlButtons__e4IO_>* {
    margin-left: 2px;
}

.VideoParticipant_message__zioKq {
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
}

.VideoParticipant_messageContainer__ECExg {
    display: flex;
    padding: 0px 4px 4px 4px;
    font-size: 16px;
    flex-direction: column;
}

.VideoParticipant_cameraOffContainer__2HVJ2 {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.VideoParticipant_companyAvatar__2x7eB {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
}

.VideoParticipant_controlButton__3K-Ds {
    padding: 5px;
}

.VideoParticipant_youBadge__CSPMK {
    position: absolute;
    z-index: 2;
    top: 12px;
    right: 12px;
    padding: 2px 8px;
    font-size: 12px;
    background-color: var(--ok-green);
    border-radius: 4px;
    color: white;
    font-weight: 700;
}

.VideoParticipant_infoBadge__UYM9o {
    color: white;
    font-size: 13px;
    margin-left: 6px;
}

.VideoParticipant_tooltip__2665M { 
    /* color: var(--text-secondary-color); */
    font-size: 14px;
}
.MeetingVideo_container__3TDrN {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.MeetingVideo_participantsGrid__fJDgZ {
    display: grid;
    flex-direction: row;
    grid-template-columns: 1fr 1fr;
    /* justify-content: center;
    align-items: center; */
    width: 100%;
    height: 100%;
    padding: 12px;
    grid-gap: 8px;
    gap: 8px;
}

@media (max-width: 599px) {
    .MeetingVideo_participantsGrid__fJDgZ {
        grid-template-columns: 1fr;
    }
}


.MeetingVideo_participantsGrid__fJDgZ>* {
    height: 100%;
    width: 100%;
    line-height: 1.6;
    border-radius: 5px;
    /* border: 1px #171718 solid; */
}

.MeetingVideo_screenShareMode__187NH {
    display: flex;
    flex-direction: row;
    z-index: 10;
    justify-content: center;
    height: auto;
}

.MeetingVideo_screenShareMode__187NH > * {
    width: 100px;
    height: 100px;
    margin-right: 8px;
}

.MeetingVideo_screenShareContainer__8SQ9Z {
    width: 100%;
    height: 100%;
    position: relative;
    margin: 8px;
}

.MeetingVideo_shareScreenVideo__3tOik {
    position: absolute;
    border-radius: 5px;
    object-position: center center;
    z-index: 1;
    object-fit: contain;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.454);
}




/* .participantsGrid>*::after {
    content: "";
    display: block;
    padding-bottom: 80%;
} */

.MeetingVideo_buttonStripContainer__1C8vM {
    margin-top: -8px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.MeetingVideo_buttonStrip__XkbmP {
    height: 90px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

@media (max-width: 599px) {
    .MeetingVideo_buttonStrip__XkbmP {
        flex-wrap: wrap;
        height: auto;
        padding: 12px 0px;
    }
}


@media (max-width: 599px) {
    .MeetingVideo_buttonStrip__XkbmP > * {
        margin-top: 8px;
    }
}


.MeetingVideo_leaveTableButton__ELhgz {
    cursor: pointer;
    /* position: absolute; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    background-color: var(--group-video-button-background);
    color: #e25549;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
}

.MeetingVideo_endMeetingButton__3v8rq {
    background-color: #e25549 !important;
    font-weight: 600;
}

.MeetingVideo_endMeetingButton__3v8rq:hover {
    transition: 0.3s;
    background-color: var(--group-video-button-background-hover);
}

.MeetingVideo_stripButton__2_59P {
    padding: 16px 20px;
    border-radius: 10px;
    cursor: pointer;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--group-video-button-background);
    margin-left: 8px;
    transition: 0.3s;
}

.MeetingVideo_stripButton__2_59P:hover {
    background-color: var(--group-video-button-background-hover);
}

.MeetingVideo_stripButtonDisabled__75ajf {
    background: var(--red);
}

.MeetingVideo_stripButtonDisabled__75ajf:hover {
    background-color: var(--red);
}

.MeetingVideo_stripButtonIcon__3Jxjz {
    font-size: 18px;
}

.MeetingVideo_noVideoIcon__5FEiM {
    width: 20px;
}

.MeetingVideo_waitingForOthersContainer__3N6Zm {
    background-color: rgba(0, 0, 0, 0.262);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    flex-direction: column;
}

.MeetingVideo_waitingForOthersHeader__35a-T {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 8px;
}

.MeetingVideo_settingsButton__2Ci5c {
    position: absolute;
    right: 12px;
}

@media (max-width: 599px) {
    .MeetingVideo_settingsButton__2Ci5c {
        position: static;
    }
}


.MeetingVideo_sendMessageContainer__nL8uG {
    width: 300px;
}

.MeetingVideo_sendMessageButtonContainer__R_QZo {
    display: flex;
    justify-self: center;
    align-items: center;
}

.MeetingVideo_sendMessageText__28agA {
    font-size: 12px;
    color: var(--text-secondary-color);
    margin-left: 8px;
}

.MeetingVideo_myNotesButton__2DKo0 {
    position: absolute;
    left: 12px;
}


@media (max-width: 599px) {
    .MeetingVideo_myNotesButton__2DKo0 {
        position: static;
    }
}


.MeetingVideo_notesContainer__CjB38 {
    width: 100%;
    max-width: 500px;
    line-height: 1.6;
}

.MeetingVideo_notesHeader__1Dd2Z {
    font-size: 18px;
    font-weight: 700;
}

.MeetingVideo_notesDescription__1lFDD {
    margin-top: 4px;
    font-size: 14px;
    color: var(--text-secondary-color);
}

.MeetingVideo_notesInput__307sS {
    margin-top: -8px;
    background-color: white !important;
}

.MeetingVideo_notesSavedBadge__2GtyT {
    opacity: 0;
    position: absolute;
    bottom: 22px;
    z-index: 4;
    right: 8px;
    transition: 0.3s ease;
}

.MeetingVideo_notesSavedBadge__2GtyT:hover {
    opacity: 0;
}

.DeviceSettings_content__tLXRL {
    display: flex;
    flex-direction: column;
}

.DeviceSettings_header__1CmaI {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 12px;
}

.DeviceSettings_settingsContainer__1TO2h {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width: 599px) {
    .DeviceSettings_settingsContainer__1TO2h {
        flex-direction: column;
    }
}


.DeviceSettings_select__nfALm {
    /* margin-bottom: 12px; */
    width: 100%;
}

.DeviceSettings_video__2syI8 {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 30px;
    transform: rotateY(180deg);
}

@media (max-width: 599px) {
    .DeviceSettings_video__2syI8 {
        width: 150px;
        height: 150px;
        margin-right: 0px;
        margin-bottom: 10px;
    }
}


.DeviceSettings_devices__1_JtE {
    max-width: 300px;
}
.VideoPreview_container__2OPhP {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.VideoPreview_content__jX4ZV { 
    background-color: white;
    padding: 24px;
    border-radius: 20px;
    display: flex;
    width: 100%;
    max-width: 600px;
    margin: 10px;
    flex-direction: column;
    align-items: center;
}

.VideoPreview_header__1vd2V {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 30px;
    color: var(--dark-purple);
}

.VideoPreview_joinMeetingButton__3TGVs {
    margin-top: 30px;
    /* width: fit-content; */
    padding: 0px 40px;
    /* margin-right: auto;
    margin-left: auto; */
    transform: scale(1.2);
    font-weight: 800;
}
.MeetingRoomPage_container__1_kUB {
    display: flex;
    min-height: 100vh;
    width: 100%;
}

@media (max-width: 599px) {
    .MeetingRoomPage_container__1_kUB {
        height: auto;
    }
}

.MeetingRoomPage_content__9XpBM{ 
    position: relative;
    flex: auto;
    background: url(/static/media/videobg.f7d230aa.jpeg) no-repeat;
    background-size: auto 200%;;
    height: 100%;
}

@media (max-width: 599px) {
    .MeetingRoomPage_content__9XpBM {
    }
}

.MeetingRoomPage_sider__2kAGL {
    flex-shrink: 0;
    background-color: white;
    height: 100vh;
    /* position: fixed;
    right: 0; */
    z-index: 10;
    border-left: 1px solid var(--kinky-grey);
    padding: 20px;

}

@media(max-width: 599px) {
    .MeetingRoomPage_sider__2kAGL {
        position: fixed;
        height: 100vh;
        right: 0px;
    }
}


.MeetingRoomPage_loadingContainer__2TAxS {
    color: white;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.MeetingRoomPage_loadingHeader__13vDH {
    margin-top: 14px;
    font-size: 18px;
    font-weight: 600;
}


.ConnectionsPage_container__IC4YH {
    width: 100%;
    max-width: 1400px;
}

.ConnectionsPage_content__3v02I {
    margin-top: 50px;
    display: grid;
    grid-row-gap: 60px;
    row-gap: 60px;

}

.ConnectionsPage_conversationGrid__2eDhq {
    padding-top: 24px;
    display: flex;
    flex-direction: row;
    /* overflow-x: auto; */
    flex-wrap: wrap;
}

.ConnectionsPage_conversationGrid__2eDhq > * {
    margin-right: 15px;
    margin-bottom: 15px;
    flex-shrink: 0;
}

@media (max-width: 599px) {
    .ConnectionsPage_conversationGrid__2eDhq > * {
        margin-right: 0px;
    }
    
}
.ConnectionsPage_usersGrid__1CzsM {
    padding-top: 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.ConnectionsPage_usersGrid__1CzsM > * {
    margin-right: 12px;
    margin-bottom: 12px;
}

@media (max-width: 599px) {
    .ConnectionsPage_usersGrid__1CzsM > * {
        margin-right: 0px;
    }
    
}


.ConnectionsPage_emptyCard__2HGIW {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    min-height: 200px;
    text-align: center;
    height: auto;
    max-width: 1000px;
    color: var(--text-secondary-color);
}

.ConnectionsPage_messagesIcon__Tt8mC {
    color: var(--primary);
    font-size: 60px;
}

.ConnectionsPage_emptyCardFollowers__3Fepn {
    /* padding: 20px 20px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    height: auto;
    text-align: center;
    min-height: 200px;
    max-width: 1000px;
    color: var(--text-secondary-color);
}

.ConnectionsPage_emotyStateRandomPeople__1IsFq {
    margin-bottom: 12px;
}

.ConnectionsPage_browseMembersButton__2FMG3 {
    margin-top: 20px;
}

.ConnectionsPage_followingYou__2SbUy {
    margin-top: 12px;
    padding: 12px;
    border: 1px solid white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background: #50576414;
    font-size: 15px;
    line-height: 1.4;
    color: var(--dark-grey)

}
.ConversationItem_container__1LXr6 {
    width: 100%;
    max-width: 400px;
    /* height: 200px; */
    line-height: 1.4;
    padding: 0px;
    border-radius: 10px;
    overflow: hidden;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.ConversationItem_participantInfo__1rzh_ {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 26px;
    cursor: pointer;
    justify-content: center;
}

.ConversationItem_participantInfo__1rzh_ .ConversationItem_texts__2j2kY {
    width: 100%;
}

.ConversationItem_name__bG3zx {
    font-size: 18px;
    font-weight: 700;
}

.ConversationItem_title__2Oadg {
    color: var(--text-secondary-color);
    margin-top: 6px;
    font-size: 14px;
}

.ConversationItem_actions__2QBWU {
    height: 57px;
}

.ConversationItem_horizontalDivider__1bW0c {
    width: 100%;
    height: 1px;
    background-color: var(--easy-grey);
}

.ConversationItem_verticalDivider__tUFG7 {
    height: 100%;
    width: 1px;
    margin-right: 2px;
    background-color: var(--easy-grey);
}

.ConversationItem_buttons__187Xe {
    display: flex;
    width: 100%;
    height: 100%;
}

.ConversationItem_buttonDisabled__1Wr2s {
    color: #d2d2d2 !important;
}

.ConversationItem_button__2V3aN {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: var(--dark-grey);
    height: 100%;
    border: none;
    cursor: pointer;
    font-size: 15px;
}

.ConversationItem_icon__khffX {
    color: #9ea6b2;
    margin-right: 14px;
    margin-top: 2px;
}

.ConversationItem_notesHeader__2QyiR {
    font-weight: bold;
    margin-bottom: 12px;
}

.ConversationItem_topBar__2QGVK {
    padding: 12px 18px 0px 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.ConversationItem_infoIcon__1PjU8 {
    margin-left: 2px;
    margin-bottom: 0px;
}

.ConversationItem_date__Qvrrm {
    color: var(--text-secondary-color);
    font-size: 12px;
    cursor: pointer;
}

.ConversationItem_feedbackScore__1rXQe {
    font-size: 12px;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.ConversationItem_scoreText__1yNQ9 {
    font-weight: bolder;
    font-size: 14px;
    color: #997606;
}

.ConversationItem_starIcon__2A0kB {
    color: #ffcd12;
    font-size: 16px;
}

.ConversationItem_didntHappen__2FPf4 {
    font-weight: 700;
    font-size: 12px;
}

.ConversationItem_upcomingMeeting__1o0Pu {
    font-size: 12px;
    color: var(--primary);
    font-weight: 600;
}

.EventFeedback_container__3Gy4s {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    line-height: 1.6;
}


.EventFeedback_content__32t-d {
    background: white;
    border-radius: 5px;
    width: 100%;
    /* padding: 24px 24px 0px 24px; */
    margin: 12px;
    text-align: center;
    line-height: 1.6;
}


.EventFeedback_header__2ZIRX {
    /* margin-top: 20px; */
    font-size: 20px;
    font-weight: 700;
}

.EventFeedback_subHeader__xl9Am {
    margin-top: 4px;
    color: var(--text-secondary-color);
}

.EventFeedback_rate__2cuZu {
    margin-top: 30px;
    transform: scale(2);
    padding-right: 30px;
    padding-left: 30px;
}


.EventFeedback_didNotMetButton__353r2 {
    margin-top: 50px;
}

.EventFeedback_phaseContainer__HrE4- {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* padding: 10px 0px 40px 0px; */
}

.EventFeedback_ratePhase__1ducF {
    
}

.EventFeedback_meetingNotesForm__3eAF- {
    margin-top: 20px;
    width: 100%;
}

.EventFeedback_meetingNotesForm__3eAF- textarea {
    width: 100%;
}

.EventFeedback_notesDoneButton__1JJrk {

}

.EventFeedback_feedbackGuide__3w2KS {
    margin-top: 50px;
    background-color: #fff3cc;
    color: #000000c2;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 6px;
    font-weight: 600;
    border-radius: 5px;
}

.EventFeedback_starIcon__3cFR4 {
    margin-right: 3px;
    transform: scale(0.8);
}

.EventFeedbackPage_container__3WWL2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.EventFeedbackPage_content__2-8bE {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 40px 60px;
}
.MetricsPage_container__2h_GH {
    max-width: var(--max-width-default);
}


.MetricsPage_content__1O0R6 {
    margin-top: 34px;
}

.MetricsPage_timeframeButton__1rcWi {
    color: var(--dark-black);
    border: unset;
    margin-bottom: 16px;
    padding-left: 0px;
    font-weight: 600;
}

.MetricsPage_topSection__10-sr {
    display: flex;
}

.MetricsPage_topSection__10-sr .MetricsPage_left__1YzUK {
    display: grid;
    flex-direction: column;
    grid-template-rows: 1fr 1fr 1fr;
    grid-row-gap: 12px;
    row-gap: 12px;
}
/* .topSection .left > * {
    margin-bottom: 12px;
} */

@media (max-width: 599px) {
    .MetricsPage_topSection__10-sr {
        flex-direction: column;
    }
}

.MetricsPage_membersChart__3-kMS {
 margin-left: 14px;
 height: 360px;
 width: 100%;
 /* max-width: 100%; */
 padding: 20px 24px 12px 12px;
}

@media (max-width: 599px) {
    .MetricsPage_membersChart__3-kMS {
        margin-left: 0px;
        margin-top: 12px;
    }
}


.MetricsPage_metricsCardContainer__3UoG4 {
    width: 300px;
    flex-shrink: 0;
}

@media (max-width: 599px) {
    .MetricsPage_metricsCardContainer__3UoG4 {
        width: 100%;
    }
}


.MetricsPage_metricsTitle__2GfD3 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
}

.MetricsPage_metricsNumberContainer__3pJ4G {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: flex-end;
}

.MetricsPage_metricsNumber__3Oh5F {
    margin-right: 12px;
    font-size: 34px;
    font-weight: 700;
    color: var(--dark-black);
}

.MetricsPage_bubble__FTPkR {
    padding: 4px 8px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.MetricsPage_greenBubble__1xTk7 {
    color: #2f6f5b;
    background-color: #def7ec;
}

.MetricsPage_redBubble__xvblD {
    color: #9e2827;
    background-color: #fce8e6;
}

.MetricsPage_icon__3Kcn8 {
    font-size: 12px;
    font-weight: 700;
    color: #0f9f6e;
    margin-right: 4px;
}

.MetricsPage_iconRed__3BoBP {
    color: #f05252;
}

.MetricsPage_secondStrip__1Uyht {
    margin-top: 24px;
}

@media (max-width: 599px) {
    .MetricsPage_secondStrip__1Uyht {
       margin-top: 42px;
    }
}

.MetricsPage_grid__3Jv_G {
    margin-top: 16px;
    display: grid;   
    grid-template-columns: 1fr 1fr;
    grid-gap: 14px;
    gap: 14px;
}

@media (max-width: 599px) {
    .MetricsPage_grid__3Jv_G {
        grid-template-columns: 1fr;
    }
}

.MetricsPage_subHeader__1xOUh {
    font-weight: 700;
    margin-bottom: 12px;
}


.MetricsPage_chartContainer__1p5vp {
    height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MetricsPage_filterChartHeader__1NHDy {
    display: flex;
    align-items: center;
    font-weight: 700;

}

.MetricsPage_filterChartHeader__1NHDy > * {
    margin-right: 8px;
}
.AccountPage_container__12NtJ {
    width: 100%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    position: relative;
}
.AccountPage_tabs__2jTnr {
    margin-top: 20px;
}


.MembershipTab_container__2WxiJ {
    color: var(--text-secondary-color);
}

.MembershipTab_content__1YV2x {
    margin-top: 30px;
    display: grid;
    grid-gap: 30px;
    gap: 30px;
}

.MembershipTab_smallHeader__1arbe {
    font-weight: 700;
    margin-bottom: 10px;
    color: var(--dark-black);
}

.MembershipTab_text__2ZSfN {
    color: var(--text-secondary-color);
    font-weight: 600;
}

.MembershipTab_button__2raTp {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.PaywallModal_content__2hToC {
    width: 100vw;
    max-width: 1000px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* background-color: red; */
    /* height: 200px;; */
}

@media (max-width: 599px) {
    .PaywallModal_content__2hToC {
        width: 100%;
        padding: 24px 8px;
    }
}

.PaywallModal_header__2EceQ {
    font-weight: 800;
    font-size: 28px;
    text-align: center;
}

.PaywallModal_description__1pEeb {
    color: var(--text-secondary-color);
    margin-top: 0px;
    font-size: 18px;
    text-align: center;
}

.PaywallModal_plans__1KMdX {
    margin-top: 34px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* grid-template-columns: 1fr 1fr; */
    grid-column-gap: 16px;
    -webkit-column-gap: 16px;
            column-gap: 16px;
    grid-row-gap: 16px;
    row-gap: 16px;
    width: 100%;
    flex-wrap: wrap;
    /* max-width: 900px; */
}

@media (max-width: 599px) {
    .PaywallModal_plans__1KMdX {
        flex-direction: column-reverse;
    }
}

.PaywallModal_pricingPlan__2rXkq {
    padding: 40px 32px 30px 32px;
    border: 1px #ebeaea solid;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    max-width: 400px;
}

@media (max-width: 599px) {
    .PaywallModal_pricingPlan__2rXkq {
        max-width: 100%;
    }
}

.PaywallModal_pricingHeader__3seF_ {
    font-weight: 700;
    font-size: 18px;
}

.PaywallModal_pricing__vf19T {
    margin-top: 12px;   
}

.PaywallModal_price__2Kieg {
    font-size: 38px;
    font-weight: 800;
}

.PaywallModal_oldPrice__2UbEP {
    font-size: 16px;
    margin-right: 8px;
    /* font-weight: 600; */
    color: #6a6a6a;
    text-decoration: line-through;
}

.PaywallModal_planTime__29ecb {
    font-weight: 700;
    font-size: 18px;
}

.PaywallModal_badge__wxV6_ {
    padding: 4px 12px;
    background-color: var(--primary);
    color: white;
    font-weight: 600;
    font-size: 12px;
    border-radius: 15px;
    text-transform: uppercase;
    width: auto;
    position: absolute;
    top: -12px;
}

.PaywallModal_planDescription__3Od-Z {
    margin-top: 16px;
    font-size: 16px;;
    color: var(--text-secondary-color);
}

.PaywallModal_accessButton__3rOoO {
    font-weight: 700;
    /* width: 100%; */
    align-self: center;
    border-radius: 10px;
    margin-top: auto;
    width: 80%;
}

.PaywallModal_notPopularButton__2fA3V {
    background-color: var(--primary-contrast-light);
    color: var(--primary);
    border-color: var(--primary-contrast-light);
}

.PaywallModal_perks__2TgDB {
    margin-top: 24px;
    margin-bottom: 30px;
    display: grid;
    grid-row-gap: 15px;
    row-gap: 15px;
}

.PaywallModal_perkContainer__2Quv2 {
    /* margin-left: 20px; */
    display: flex;
    flex-direction: row;
    
}

.PaywallModal_perk__3HKgW {
    margin-left: 10px;
    font-size: 15px;
    margin-top: -3px;
}

.PaywallModal_perkCheckmark__QRX3C {
    color: var(--primary);
    font-weight: 900;
    font-size: 24px;
}

.PaywallModal_boldedPerk__1LTAg {
    font-weight: 700;
}

/* 
.perkTooltip {
    background-color: var(--primary);
    color: white;
    font-weight: 600;
} */

.PaywallModal_underlinedLink__30juZ{
    font-weight: 700;
    text-decoration: underline;
    -webkit-text-decoration-color: var(--primary);
            text-decoration-color: var(--primary);
    -webkit-text-decoration-style: dotted;
            text-decoration-style: dotted;
    cursor: pointer;
  }

  .PaywallModal_billingCycle__3oWLY{
      margin-left: 6px;
      font-size: 12px;
      /* color: var(--text-secondary-color); */
  }
.CheckoutPage_container__1m9CJ {
    height: 100%;
    width: 100%;
}

.CheckoutPage_content__19Dd4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px 20px 20px;
    height: 100%;
    width: 100%;
    text-align: center;
}

.CheckoutPage_logo__3-Iu_ {
    height: 100px;
    transform: scale(0.7);
}

.CheckoutPage_header__3MSmv {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 16px;
}

.CheckoutPage_description__2RfyE {
    color: var(--text-secondary-color);
    font-size: 16px;
}

.CheckoutPage_planName__mHFmA {
    font-weight: 700;
}

.CheckoutPage_changePlan__2OdBr {
    color: var(--primary);
    margin-left: 8px;
    font-weight: 700;
    cursor: pointer;
}

.CheckoutPage_iframeWrapper__1EJr1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0px 10px 0px;
    max-width: 1000px;
    width: 100%;
    height: 100%;
}

.CheckoutPage_loader__hUm6e {
    position: absolute;
}

.CheckoutPage_shareContainer__NBTZb {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4px;
}

.CheckoutPage_shareHeader__9vFMi {
    font-size: 14px;
    /* font-weight: 700; */
    border: var(--material-color-grey-200) 1px solid;
    padding: 8px;
    color: var(--text-secondary-color);
}

.CheckoutPage_iframe__3e2cF {
    width: 100%;
    height: 100%;
    min-height: 520px;
    border: none;
}

.CheckoutPage_payingUser__2kbsV {
    color: var(--text-secondary-color);
    font-size: 16px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
}

.CheckoutPage_userName__tHT-e {
    font-weight: 700;
    margin-left: 4px;
}


.CheckoutPage_shareLink__2ioQ4 {
    font-weight: 600;
    color: var(--primary);
    cursor: pointer;
}

.CheckoutPage_smallLetters__3_Zhb {
    font-size: 14px;
    color: var(--text-secondary-color);
    text-align: center;
    font-weight: 600;
}

.Logo_container__1SA8M {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 270px;

}

.Logo_logo__1jwas {
    width: 100%;
}

.Logo_phocuswright__3sVso {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Logo_phocuswright__3sVso img {
    width: 100px;
}

.Logo_phocuswright__3sVso span {
    margin-top: 1px;
    margin-right: 4px;
    font-size: 14px;
    font-weight: 300;
    color: #113a4e;
}
.ResourcesPage_container__1y8E4 {
    position: relative;
}

.ResourcesPage_resourcesGrid__6gd_T {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
}

.ResourcesPage_sectionContainer__3lv42 {
    display: flex;
    flex-direction: column;
}

.ResourcesPage_resourcesGrid__6gd_T>* {
    margin-right: 15px;
    margin-bottom: 15px;
}

@media(max-width: 599px) {
    .ResourcesPage_resourcesGrid__6gd_T>* {
        margin-right: 0px;
        margin-bottom: 16px;
        width: 100%;
    }
}

.ResourcesPage_buttons__u9hYM {
    margin-top: 22px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.ResourcesPage_buttons__u9hYM>* {
    margin-right: 8px;
}

.ResourcesPage_eventsContainer__3BELm {
    margin-top: 60px;
}

.AddResourceDrawer_content__1f6dL {
    color: var(--text-secondary-color);
    font-size: 16px;
    padding: 30px 24px;
    display: grid;
    grid-gap: 60px;
}

@media(max-width: 599px) {
    .AddResourceDrawer_content__1f6dL {
        padding: 30px 12px;
    }
}

.AddResourceDrawer_uploadText__o8fX2 {
    font-size: 14px;
    color: var(--text-secondary-color);
}

.resource-form .ant-select-selection-item {
    padding-top: 10px !important;
}

.resource-form .ant-select-selection-placeholder {
    padding-top: 10px !important;
}

.resource-form textarea {
    min-height: 140px;
}

.ResourcePage_container__2ihUf {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-width: var(--max-width-default);
}

.ResourcePage_sectionContainer__2mPIN {
    display: flex;
    flex-direction: column;
}

.ResourcePage_buttons__PO7ov {
    margin-top: 22px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.ResourcePage_buttons__PO7ov>* {
    margin-right: 8px;
}


.ResourcePage_card__vpM7S {
    display: flex;
    flex-direction: column;
    line-height: 1.6em;
    font-size: 16px;
}

.ResourcePage_contentContainer__3bEkL{
    margin: 22px 0px;
    height: 100%;
}

.ResourcePage_loader__38m98 {
    position: absolute;
}

.ResourcePage_embedContainer__27xch {
    width: 100%;
    height: 100%;
    display: flex;
    border: 1px var(--bland-grey) solid;
    justify-content: center;
    background-color: #d1d1d1;
    align-items: center;
}

.ResourcePage_embed__1oV4z {
    width: 100%;
    height: 100%;
}


.ResourcePage_externalIcon__3__MT {
    width: 12px;
    margin-right: 4px;
}

.LoginWith_container__2mpNa {
    width: 100%;
    padding: 18px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    background-color: var(--primary) !important;
    border-radius: 5px;
}

.LoginWith_icon___jAa5 {
    width: 24px;
    height: 24px;
    margin-right: 16px;
}

.LoginWith_text__vi-oR {
    font-size: 16px;
    font-weight: 700;
}



.InvitationBubble_container__1k1uM {
    padding: 30px 30px;
    margin: 0 8px;
    width: 100%;
    max-width: 500px;
    background-color: #f6f7fa;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
}

@media (max-width: 599px) {
    .InvitationBubble_container__1k1uM {
        padding: 30px 8px;
        text-align: center;
    }
}

.InvitationBubble_content__rSNxO {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width: 599px) {
    .InvitationBubble_content__rSNxO {
        flex-direction: column;
    }
}

.InvitationBubble_textContainer__1WeXh {
    display: flex;
    flex-direction: column;
    margin-left: 18px;
    z-index: 99;
}

@media (max-width: 599px) {
    .InvitationBubble_textContainer__1WeXh {
        margin-left: 0px;
        margin-top: 12px;
    }
}

.InvitationBubble_header__1FD1n {
    color: black;
    font-size: 20px;
    font-weight: 700;
}

.InvitationBubble_secondaryText__2wDds {
    color: #181d26;
    margin-top: 4px;
    font-size: 14px;
    font-weight: 600px;
}

.InvitationBubble_boardingPassIcon__3yyY9 {
    position: absolute;
    width: 200px;
    right: -80px;
    bottom: 0px;
    transform: rotateY(180deg);
    z-index: 1;
}

@media (max-width: 599px) {
    .InvitationBubble_boardingPassIcon__3yyY9 {
        width: 120px;
        right: -40px;

    }
}
.LoginPage_container__2nrSw {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 12px;
    padding-left: 12px;
    line-height: 1.5;
    height: 100%;
}

@media (max-width: 599px) {
    .LoginPage_container__2nrSw {
        height: auto;
        padding: 20px 0px;
    }
}

.LoginPage_content__gYvCk {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 680px;
    height: 100%;
    width: 100%;
}

@media (max-width: 599px) {
    .LoginPage_content__gYvCk {
        justify-content: flex-start;
        margin-top: 20px;
    }
}

@media (max-height: 720px) {
    .LoginPage_content__gYvCk {
        justify-content: flex-start;
        padding: 20px 0px;
    }
}


.LoginPage_invitation__2-34G {
    margin-top: 28px;
    width: 100%;
    justify-content: center;
    display: flex;
}

.LoginPage_textContainer__Z5hvc {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    line-height: 1.7;
    justify-content: center;
}

.LoginPage_header__1EujA {
    font-size: 20px;
    font-weight: 800;
}

.LoginPage_subHeader__1NgUA {
    margin-top: 4px;
    font-size: 15px;
    text-align: center;
    color: #636363;
    max-width: 500px;
    margin: 0px 12px;    
}

.LoginPage_loginButtons__3AUrF {
    padding: 0px 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 40px;
    width: 100%;
    height: auto;
    max-width: 400px;
}

.LoginPage_tosContainer__1_edd {
    margin-top: 24px;
    font-size: 12px;
    color: #636363;
    line-height: 1.5;
    text-align: center;
}

.LoginPage_credentialContainer__3JUuj {
    width: 100%;
}

.LoginPage_divider__4AlZ9 {
    font-size: 14px !important;
    color: var(--text-secondary-color) !important;
}

.LoginPage_loginButton__2yms0 {
    font-weight: 600;
    width: 100%;
}

.LoginPage_forgotPasswordContaienr__T2Ofv {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 50px 0px 30px 0px;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
}

.LoginPage_signUpWithCredentials__RWYi_ {
    font-size: 12px;
    margin-top: 12px;
}

.LoginPage_signupContainer__38vAv {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.LoginPage_alreadyMemberContainer__hZynX {
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 0px 10px 0px;
    color: #b7b7b7;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
}

.LoginPage_alreadyMemberContainer__hZynX>* {
    margin-bottom: 12px;
}

.LoginPage_modalContainer__2C1Kf {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.LoginPage_modalHeader__1Z5df {
    font-weight: 800;
    font-size: 28px;
    line-height: 1.4;
    text-align: center;
}

.LoginPage_modalSubHeader__3iuXG {
    margin-top: 20px;
    text-align: center;
    margin-bottom: 20px;
}

.LoginPage_emailAnimation__3BoJn {
    width: 160px;
}

.LoginPage_travelTechNation__3aj-t {
    position: absolute;
    padding: 16px;
    border: 1px solid #f2f2f2;
    bottom: 0px;
    left: 0px;
    font-size: 15px;
    font-weight: 600;
    color: var(--text-secondary-color);
    /* display: flex; */
    justify-content: center;
    cursor: pointer;
}

@media (max-width: 599px) {
    .LoginPage_travelTechNation__3aj-t {
        position: static;
        margin-bottom: -20px;
        margin-top: 50px;
        text-align: center;
    }
}

.LoginPage_ttnLogo__1mKcp {
    height: 18px;
    margin-left: 2px;
    margin-top: -9px;
}
.LoginPage_pwLogo__3Yuxi {
    height: 20px;
    margin-top: -4px;
    margin-left: 3px;
}

.LoginPage_loaderContainer__1WLVi{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.GuestInvitationBubble_container__yXEMo {
    padding: 40px 40px 30px 40px;
    margin: 0 8px;
    width: 100%;
    max-width: 500px;
    background-color: black;
    background-image: url(/static/media/invite-bg.fe3c0ed5.jpg);
    animation: GuestInvitationBubble_movingBackground__3OKDT 8s ease infinite;
    color: white;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    text-align: center;
    box-shadow: 0 0 10px 0 rgba(198, 198, 198, 0.5);

}

@media (max-width: 599px) {
    .GuestInvitationBubble_container__yXEMo {
        padding: 30px 8px;
        text-align: center;
    }
}

.GuestInvitationBubble_content__3Ez41 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.GuestInvitationBubble_subHeader__2TWPz {
    font-size: 13px;
    opacity: 0.8;
}

.GuestInvitationBubble_header__AXt4a {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.3;
}

.GuestInvitationBubble_date__2dvL_ {
    margin-top: 4px;
    font-size: 14px;
    opacity: 1;
}

.GuestInvitationBubble_role__1xA7Z {
    margin-top: 20px;
    background-color: #ffc300;
    color: #000000c2;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 6px;
    font-weight: 600;
    border-radius: 5px;
}

.GuestInvitationBubble_starIcon__3OI_e {
    margin-right: 3px;
    transform: scale(0.8);
}

.GuestInvitationBubble_inviterText__3JJO1 {
    color: #181d26;
    font-size: 14px;
    font-weight: 600px;
    margin-left: 8px
}

.GuestInvitationBubble_inviterContainer__1oYTv {
    margin-top: -10px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.GuestInvitationBubble_inviterContainer__1oYTv > * {
    margin-right: 10px;
}

.GuestInvitationBubble_avatar__3pmhp {
    border: 1px solid white;
}

.GuestInvitationBubble_boardingPassIcon__1piAd {
    position: absolute;
    width: 200px;
    right: -80px;
    bottom: 0px;
    transform: rotateY(180deg);
    z-index: 1;
}

@media (max-width: 599px) {
    .GuestInvitationBubble_boardingPassIcon__1piAd {
        width: 120px;
        right: -40px;
    }
}

@keyframes GuestInvitationBubble_movingBackground__3OKDT {
	0% {
		background-position: 00% 00%;
	}
	50% {
		background-position: 60% 60%;
	}
	100% {
		background-position: 00% 00%;
	}
}

.GuestInvitationBubble_closeIcon__2am4p {
    color: white;
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;
    font-weight: 600;
    font-size: 12px;
    opacity: 0.8;
}
.BasicDetails_content__1vfoV {
    max-width: 600px;
    width: 100%;
    margin-top: -24px;
}

.BasicDetails_doubleInputGrid__18xL4 {
    margin-top: -24px;
    display: grid;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    grid-template-columns: 1fr 1fr;
}

@media (max-width: 599px) {
    .BasicDetails_doubleInputGrid__18xL4 {
        grid-row-gap: 0px;
        row-gap: 0px;;
        grid-template-columns: 1fr;
    }
}

.BasicDetails_userProfile__CbYDF {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.BasicDetails_profileLabels__1ESIa {
    margin-left: 24px;
}

.BasicDetails_profileHeaderText__1U6Q7 {
    font-weight: bold;
}

.BasicDetails_profileSubHeaderText__2mreL {
    color: var(--text-secondary-color);
}

.BasicDetails_companySearchHit__vrcwk {
    display: flex;
    align-items: center;
    height: 40px;
}

.BasicDetails_companySearchName__209_5 {
    font-weight: 600;
}

.BasicDetails_companySearchLogo__1xHAm {
    margin-right: 12px;
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 2px;
    border: 1px #f2f2f2 solid;
}

.BasicDetails_addNewButtonLink__1lg8O {
    padding: 8px 0px;
    font-weight: 600;
    color: var(--primary);
}

.BasicDetails_consent__15RUG {
    display: flex;
    flex-direction: center;
    justify-content: center;
}

.BasicDetails_noCompanySelected__3JU_t {
    margin-top: 8px;
    font-size: 14px;
    color: red;
}
.OnboardingCompleted_content__6Z5e_ {
    display: flex;
    justify-content: center;
    align-items: center;
}

.OnboardingCompleted_doneAnimation__wkeo7 {
    margin-top: -50px;
    width: 300px;
}

.SectorsSecondaryStep_content__1eVNK {
    display: flex;
    justify-content: center;
    margin-top: -20px;
}

.SectorsSecondaryStep_sectorsContainer__1470t {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.SectorsSecondaryStep_sectorsContainer__1470t > * {
    margin-top: 10px;
}



.SectorsStep_content__3Pbii {
    display: flex;
    justify-content: center;
    margin-top: -20px;
}

.SectorsStep_sectorsContainer__1kiSK {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.SectorsStep_sectorsContainer__1kiSK > * {
    margin-top: 10px;
}


.UserTypeStep_content__1iHoB {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center ;
    justify-content: center;
}

.UserTypeStep_typeButton__2kt3z {
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 34px 12px;
    color: var(--primary);
    font-weight: 700;
    border: 2px var(--primary) solid;
    border-radius: 5px;
    width: 100%;
    max-width: 400px;
    margin-bottom: 12px;
    transition: 0.3s;
    cursor: pointer;
}

.UserTypeStep_icon__3zWGU {
    position: absolute;
    bottom: -5px;
    width: 40px;
    right: 16px;
}

.UserTypeStep_typeButton__2kt3z:hover {
    background-color: var(--primary-contrast-light);
    box-shadow: 0 0 10px 0 rgba(198, 198, 198, 0.5);
}
.OnboardingPage_container__2OjD_ {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    line-height: 1.7;
    padding: 12px;
    position: relative;
}

.OnboardingPage_content__2Nta6{
    max-width: 800px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 12px 0;
}

.OnboardingPage_welcomeAnimation__2Rtdk {
    margin-top: 50px;
    width: 250px;
}


.OnboardingPage_welcomePhase__3hohD {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.OnboardingPage_logoutButton__2xJK_ {
    position: absolute;
    left: 20px;
    bottom: 20px;
    font-size: 12px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    color: var(--text-secondary-color);
}

@media (max-width: 599px) {
    .OnboardingPage_logoutButton__2xJK_ {
        top: 20px;
        right: 20px;
    }
}
.WaitingListPage_container__3QMWe {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    text-align: center;
}

.WaitingListPage_header__1wKpS {
    margin-top: 38px;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 20px;
}

.WaitingListPage_logout__2W5rb {
    margin-top: 50px;
    font-size: 14px;
}

.WaitingListPage_button__3dsiK {
    margin-top: 30px;
    padding: 0px 40px;
    font-weight: 600;
}
.UpcomingPage_container__2Beon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    line-height: 1.4;
    padding: 12px;
}

.UpcomingPage_header__2tJzU {
    font-size: 48px;
    font-weight: 800;
    margin-bottom: 30px;
    line-height: 1.2;
    text-align: center;
}

@media (max-width: 599px) {
    .UpcomingPage_header__2tJzU {
        font-size: 40px;
    }
}

.UpcomingPage_subHeader__2vWSy {
    font-size: 22px;
    color: var(--text-secondary-color);
    text-align: center;
}

@media (max-width: 599px) {
    .UpcomingPage_subHeader__2vWSy {
        font-size: 18px;
    }
}


.UpcomingPage_logout__1KgmY {
    margin-top: 50px;
    font-size: 14px;
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.UpcomingPage_button__2DDxq {
    margin-top: 30px;
    padding: 0px 40px;
    font-weight: 600;
}

.UpcomingPage_foundingMemberCard__2s7eP {
    margin-top: 60px;
    margin-bottom: 30px;
}

.UpcomingPage_animation__1Ux7B {
    width: 300px;
    margin-bottom: 30px;
}
.FoundingMemberCard_container__Qs80z {
    width: 100%;
    max-width: 400px;
    background-color: #f6f7fa;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}

.FoundingMemberCard_content__3a70H {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
    padding: 30px;
}


.FoundingMemberCard_textContainer__2DZhu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 18px;
    z-index: 99;
}

@media (max-width: 599px) {
    .FoundingMemberCard_textContainer__2DZhu {
        margin-left: 10px;
        margin-top: 12px;
    }
}

.FoundingMemberCard_header__21Ez5 {
    color: black;
    font-size: 20px;
    font-weight: 700;
}

.FoundingMemberCard_secondaryText__36TqR {
    color: var(--text-secondary-color);
    margin-top: 2px;
    font-size: 14px;
    font-weight: 400px;
}

.FoundingMemberCard_boardingPassIcon__2cz6N {
    position: absolute;
    width: 200px;
    right: -80px;
    bottom: 0px;
    transform: rotateY(180deg);
    z-index: 1;
}

@media (max-width: 599px) {
    .FoundingMemberCard_boardingPassIcon__2cz6N {
        width: 120px;
        right: -40px;
    }
}

.FoundingMemberCard_boardingPassHeader__36M4A {
    width: 100%;
    position: absolute;
    top: 0;
    background-color: var(--primary);
    display: flex;
    align-items: center;  
    color: white;
    font-weight: 600;
    padding: 8px 20px;
}

.FoundingMemberCard_dashed__17Xxs {
    position: absolute;
    right: 0;
    border-left: 1px dashed #dddcd9;
    width: 90px;
    height: 100%;
}

.FoundingMemberCard_boardingPassText__sq5dC {
    text-transform: uppercase;
    opacity: 0.9;
    font-size: 10px;;
}

.FoundingMemberCard_mockingContainer__43ruf {
    margin-top: 35px;
    padding: 8px 12px;
}

.FoundingMemberCard_mockingHeader__udiSb {
    font-weight: 800;
    text-transform: uppercase;
    color: var(--dark-black);
    margin-bottom: 4px;
    opacity: 0.8;
    font-size: 10px;
}

.FoundingMemberCard_mockingText__3qqf9 {
    height: 8px;
    background-color: #bcbdc1;
    border-bottom: 8px;
    width: 40px;
}

.FoundingMemberCard_barcode__1XsiP {
    margin-top: 20px;
    height: 10px;
    width: 100%;
    opacity: 0.7;
}
.AddToCalendarPage_container__3KTkE {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    line-height: 1.5;
    text-align: center;
    flex-direction: column;
    width: 100%;
    position: relative;
    
}

.AddToCalendarPage_content__1qoAv {
    max-width: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.AddToCalendarPage_topHeaderText__3muFV {
    color: var(--primary);
    font-weight: 600;
    margin-top: 8px;
    margin-bottom: 50px;
    font-size: 14px;
}

.AddToCalendarPage_subHeader__3DhYr {
    font-size: 15px;
    color: var(--text-secondary-color);
}

.AddToCalendarPage_header__3dHNj {
    font-size: 28px;
    font-weight: 700;
}

.AddToCalendarPage_addToCalendarButton__3ST-o {
    font-weight: 600;
    margin-top: 50px;
}

.AddToCalendarPage_logo__1ec6- {
    position: absolute;
    bottom: 40px;
    width: 160px;
}

.AddToCalendarPage_loading__c96Kq {
    width: 100px;
}
.GuestConfirmationPage_container__219M7 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--background-color);
}

.GuestConfirmationPage_content__1n8ak {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    padding-bottom: 120px;
}

@media (max-width: 599px) {
    .GuestConfirmationPage_content__1n8ak {
        padding: 0 12px;
        padding-bottom: 120px;
    }
}

.GuestConfirmationPage_header__3EJvW {
    width: 100%;
    margin: 0 auto;
    height: 60px;
    padding: 16px 0px;
}

@media (max-width: 599px) {
    .GuestConfirmationPage_header__3EJvW {
        /* padding-right: 12px;
        padding-left: 12px; */
    }
}

.GuestConfirmationPage_headerContent__1ySEd {
    display: flex;
    width: 100%;
    /* background-color: red; */
}

.GuestConfirmationPage_logoContainer__2n5JD {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.GuestConfirmationPage_logo__UkBSc {
    height: 26px;
}

.GuestConfirmationPage_guestAccess__3oMC5 {
    padding: 4px 8px;
    background-color: var(--primary-contrast-light);
    color: var(--primary-contrast-dark);
    font-size: 14px;
    font-weight: 600;
    margin-left: 12px;
    border-radius: 5px;
    /* color: var(--semi-black) */
}

.GuestConfirmationPage_header__3EJvW .GuestConfirmationPage_rightSide__w3BiO {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.GuestConfirmationPage_logoutButton__n4ku9 {
    font-size: 12px;
    margin-left: 8px;
    cursor: pointer;
}

.GuestConfirmationPage_eventDetails__14y5b {
    margin-top: 25px;
    display: grid;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    grid-template-columns: 60fr 40fr;
}

@media (max-width: 599px) {
    .GuestConfirmationPage_eventDetails__14y5b {
        grid-template-columns: 1fr;
    }
}

.GuestConfirmationPage_eventDetails__14y5b .GuestConfirmationPage_leftSide__2tImN {
    /* background-color: red; */
}

/* @media (max-width:599px) {
    .eventDetails .leftSide {
        display: none;
    }
} */

.GuestConfirmationPage_eventDetails__14y5b .GuestConfirmationPage_rightSide__w3BiO {
    position: -webkit-sticky;
    position: sticky;
    top: 20px;
    display: flex;
    width: 100%;
    height: auto;
    margin-top: 0;
    margin-right: 32px;
    padding: 0 0 20px;
    flex-direction: column;
    align-self: flex-start;
    flex: 1 1;
}

@media (max-width: 599px) {
    .GuestConfirmationPage_eventDetails__14y5b .GuestConfirmationPage_rightSide__w3BiO {
        margin-top: 44px;
        position: static;
    }
}

.GuestConfirmationPage_eventTitle__2abqu {
    font-size: 28px;
    line-height: 1.4em;
    font-weight: 700;
}

.GuestConfirmationPage_themesContainer__wXz6V {
    margin-top: 10px;
}

.GuestConfirmationPage_eventDescription__apzo3 {
    margin-top: 44px;
    line-height: 1.6;
}

.GuestConfirmationPage_miniHeader__2J1uO {
    margin-top: 44px;
    font-size: 18px;
    font-weight: bold;
}

.GuestConfirmationPage_speakersGrid__1R5Rz {
    margin-top: 24px;
    display: grid;
    grid-row-gap: 16px;
    row-gap: 16px;
    grid-column-gap: 16px;
    -webkit-column-gap: 16px;
            column-gap: 16px;
    grid-template-columns: 1fr 1fr;
}

@media (max-width: 599px) {
    .GuestConfirmationPage_speakersGrid__1R5Rz {
        grid-template-columns: 1fr;
    }
}

.GuestConfirmationPage_dateAndLocationContainer__2KUD8 {
    margin-top: 16px;
}

.GuestConfirmationPage_date__14dgU>div {
    margin-left: 12px;
}

.GuestConfirmationPage_icon__3ZWu4 {
    font-size: 14px;
    color: #727C8B;
}

.GuestConfirmationPage_location__1frZ4 {
    margin-top: 14px;
}

.GuestConfirmationPage_dateContainer__37GY9 {
    display: inline-flex;
}

.GuestConfirmationPage_date__14dgU {
    display: flex;
    align-items: center;
    color: #727C8B;
    font-weight: 600;
    font-size: 15px;
}

@media (max-width: 599px) {
    .GuestConfirmationPage_dateContainer__37GY9 {
        display: flex;
        flex-direction: column;
    }
    .GuestConfirmationPage_date__14dgU {
        line-height: 1.4;
    }
    .GuestConfirmationPage_addToCalendar__YTZ0r {
        display: flex;
        margin-top: 8px;
    }
}

.GuestConfirmationPage_guestDisclaimer__A48ur {
    background-color: white;
    display: flex;
    line-height: 1.6em;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: var(--text-secondary-color);
    text-align: center;
    margin-top: 12px;
    padding: 12px;
    border: 1px solid var(--material-color-grey-300);
}

.GuestConfirmationPage_applyMembershipButton__5wVFJ {
    margin-top: 16px;
}

.GuestConfirmationPage_attendeesContainer__2hFi0 {
    margin-top: 50px;
}

@media (max-width: 599px) {
    .GuestConfirmationPage_attendeesContainer__2hFi0 {
        padding-bottom: 48px;
    }
}

.GuestConfirmationPage_membersContainer__1zjLU {
    margin-top: 28px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.GuestConfirmationPage_membersContainer__1zjLU>* {
    margin-right: 16px;
    margin-bottom: 16px;
}

@media (max-width: 599px) {
    .GuestConfirmationPage_membersContainer__1zjLU>* {
        margin-right: 0px;
    }
}

.GuestConfirmationPage_stickyBottom__3qxrn {
    justify-content: center;
    align-items: center;
    position: fixed;
    display: flex;
    width: 100%;
    bottom: 0px;
    left: 0px;
    height: 94px;
    box-shadow: 0px -4px 10px rgba(211, 211, 211, 0.5);
    background-color: white;
}

.GuestConfirmationPage_stickyContent__1Ygo8 {
    /* background-color: red; */
    max-width: 1200px;
    width: 100%;
    display: flex;
    align-items: center;
}

.GuestConfirmationPage_stickyContent__1Ygo8 .GuestConfirmationPage_rightSide__w3BiO {
    display: flex;
    align-items: center;
    margin-left: auto;
}

@media (max-width: 599px) {
    .GuestConfirmationPage_stickyContent__1Ygo8 {
        width: auto;
    }
    .GuestConfirmationPage_stickyContent__1Ygo8 .GuestConfirmationPage_leftSide__2tImN {
        display: none;
    }
    .GuestConfirmationPage_stickyContent__1Ygo8 .GuestConfirmationPage_rightSide__w3BiO {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0px;
    }
}

.GuestConfirmationPage_registrationStatus__1LJNv {
    font-weight: 15px;
    font-weight: 600;
    margin-right: 22px;
}

.GuestConfirmationPage_registerButton__Z7xRn {
    border-radius: 10px;
    /* width: 160px; */
    padding: 0px 44px;
    font-size: 18px;
    font-weight: 700;
    height: 50px;
}

.GuestConfirmationPage_explainerMessage__-EUGL {
    font-weight: 600;
}

.GuestConfirmationPage_registrationButtonContainer__327kH {
    display: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.GuestConfirmationPage_tooltip__lhZqR {
    position: absolute;
    top: -40px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: var(--primary);
    padding: 8px 12px;
    border-radius: 5px;
    box-shadow: 0 2px 5px 0 rgba(211, 211, 211, 0.5);
    background-color: var(--primary-contrast-light);
    /* border: 1px solid rgba(var(--primary), 1); */
}

.GuestConfirmationPage_inviterText__D3j9p {
    margin-left: 8px;
    font-weight: 700;
}

.GuestConfirmationPage_titleBottom__FJdH2 {
    font-weight: 600;
    margin-top: 6px;
    font-size: 16px;
    max-width: 800px;
    padding: 4px 0px;
    margin-bottom: 0px !important;
}

.GuestConfirmationPage_dateBottom__1bxST {
    font-size: 14px;
    color: var(--primary-contrast-dark);
}
.AccountUpdatePage_container__2GoOi {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    line-height: 1.5;
    text-align: center;
    flex-direction: column;
    width: 100%;
    position: relative;
    
}

.AccountUpdatePage_content__orNtk {
    max-width: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.AccountUpdatePage_subHeader__1M4zU {
    font-size: 15px;
    color: var(--text-secondary-color);
}

.AccountUpdatePage_header__3yAPx {
    font-size: 28px;
    font-weight: 700;
}

.AccountUpdatePage_addToCalendarButton__LyNXd {
    font-weight: 600;
    margin-top: 50px;
}

.AccountUpdatePage_logo__1Jxz- {
    position: absolute;
    bottom: 40px;
    width: 160px;
}

.AccountUpdatePage_loading__3gzWa {
    width: 100px;
}
.IntroSchedulePage_container__2aAjy {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    height: 100%;
    line-height: 1.5;
    text-align: center;
    flex-direction: column;
    width: 100%;
    position: relative;
    overflow-y: auto;
    background-color: var(--background-color);
}

.IntroSchedulePage_loaderContainer__813mz {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.IntroSchedulePage_header__3Ys0z {
    display: flex;
    padding: 12px;
    position: relative;
    width: 100%;
    position: fixed;
    top: 0px;
}

.IntroSchedulePage_logo__2kV-p {
    margin-left: 12px;
    width: 120px;
    position: absolute;
    right: 12px;
}

.IntroSchedulePage_homeButton__YEbqx {
    position: absolute;
    left: 12px;
    ;
}

.IntroSchedulePage_completionModalContainer__2h7jY {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 12px;
}

.IntroSchedulePage_completionHeader__n8ldO {
    font-size: 22px;
    font-weight: 700;
}

.IntroSchedulePage_completionDescription__3OUaI {
    margin-top: 12px;
    font-size: 16px;
    color: var(--text-secondary-color);
}

.IntroSchedulePage_completionButtonsContainer__3KOZX {
    margin-top: 34px;
    display: flex;
    flex-direction: column;
    font-weight: 700;
}

.IntroSchedulePage_completionButtonsContainer__3KOZX>* {
    margin-bottom: 8px;
}

.IntroSchedulePage_keepPosted__1jm8N {
    margin-top: 18px;
}

.IntroSchedulePage_doneAnimation__1758c {
    margin-top: -20px;
    width: 150px;
    height: 120px;
}

.IntroSchedulePage_dateAndTime__1zGHT {
    margin-top: 22px;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.8;
    color: var(--text-secondary-color);
}

.IntroSchedulePage_dateAndTimeIcon__DKJ60 {
    margin-right: 4px;
    /* color: var(--primary); */
}
.MeetingScheduler_container__2VY66 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
}

.MeetingScheduler_stickyBarOffset__1FTlF {
    height: 150px;
}

.MeetingScheduler_content__1sJZ- {
    padding: 8px;
}

.MeetingScheduler_stickyBar__3nsGk {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 12px;
    position: fixed;
    display: flex;
    width: 100%;
    bottom: 0px;
    left: 0px;
    box-shadow: 0px -4px 10px rgba(211, 211, 211, 0.5);
    background-color: white;
}

.MeetingScheduler_stickyBarContent__12L2_ {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.MeetingScheduler_doneButton__1OUt0 {
    /* width: 100%; */
    width: 300px;
    height: 50px;
    font-weight: 700;
    border-radius: 5px;
}

.MeetingScheduler_header__16VHh {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    line-height: 1.4;
    margin-top: 40px;
}

.MeetingScheduler_bubblesContainer__3NKGY {
    margin: 24px 0px 20px 0px;
    display: flex;
    flex-direction: row;
}

.MeetingScheduler_userAvatar__TRzSd {
    margin-right: -8px;
}

.MeetingScheduler_title__30Uua {
    font-size: 22px;
    font-weight: 700;
    color: var(--dark-purple);
}

.MeetingScheduler_icons__1oaOC {
    margin: 10px 0px 20px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    color: var(--text-secondary-color);
}

.MeetingScheduler_icon__1MP_C {
    margin-right: 2px;
}


.MeetingScheduler_subTitle___BEQd {
    margin-top: 12px;
    color: var(--dark-purple);
    font-size: 16px;
    font-weight: 600;
}

.MeetingScheduler_guide__19hiu {
    font-size: 18px;
    /* font-weight: 600; */
    color: var(--dark-grey);
    text-align: center;
    margin-bottom: 18px;
}

.MeetingScheduler_selectedDatesNumber__3AzXJ {
    /* color: var(--primary); */
    font-weight: 700;
}

.MeetingScheduler_calendar__2Q59I {
    margin-top: 14px;
    height: auto;
    border: 1px solid rgb(224, 229, 247);
    border-radius: 5px 5px 0px 0px;
    /* ;max-width: 590px; */
    width: 100%;
    padding: 0;
    position: relative;
}

.MeetingScheduler_calendarBottom__3R76v  {
    width: 100%;
    background-color: #f6f6f7;
    border-top: 1px solid #dadcec;
    color: #5b5b5b;
    padding: 8px;
    font-size: 14px;
}

.MeetingScheduler_calendarContent__25dKy {
    padding: 12px 34px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@media (max-width: 599px) {
    .MeetingScheduler_calendarContent__25dKy {
        padding: 12px 20px;
        flex-direction: column;
    }
}

.MeetingScheduler_dayColumn__1xbvx {
    display: flex;
    flex-direction: column;
    padding: 0px 8px;
}


@media (max-width: 599px) {
    .MeetingScheduler_dayColumn__1xbvx {
        margin-top: 12px;
    }
}

.MeetingScheduler_dayName__3B81D {
    font-weight: 800;
    font-size: 15px;
}

.MeetingScheduler_dayDate__2bNrS {
    margin-top: 2px;
    color: var(--text-secondary-color);
    font-size: 12px;
    font-weight: 400;
}

.MeetingScheduler_timesColumn__1EKbq {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
}

.MeetingScheduler_timeOption__2lE-A {
    transition: all 0.2s ease 0s;
    width: 66px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    padding: 1px 12px;
    white-space: nowrap;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    font-size: 16px;
    color: var(--text-secondary-color);
    border: 1px solid var(--easy-grey);
    margin-bottom: 12px;
}


@media (max-width: 599px) {
    .MeetingScheduler_timeOption__2lE-A {
        width: 100%;
    }
}

.MeetingScheduler_timeOption__2lE-A:hover{
    background-color: var(--primary-contrast-light);
}

.MeetingScheduler_timeOptionSelected__2UF3n {
    background-color: var(--primary);
    color: white;
}

.MeetingScheduler_timeOptionSelected__2UF3n:hover {
    background-color: var(--primary);
}

.MeetingScheduler_timezone__26src {
    margin-top: 8px;
    font-size: 13px;
    color: var(--text-secondary-color);
}

.MeetingScheduler_nextWeekButton__2cMXI {
    position: absolute;
    right: 14px;
    top: 14px;
    cursor: pointer;
}

.MeetingScheduler_previousWeekButton__2qrZ5 {
    left: 14px;
    right: unset;
}

.MeetingScheduler_weekText__3sayz {
    display: none;
    font-size: 12px;
}

@media (max-width: 599px) {
    .MeetingScheduler_weekText__3sayz {
        padding: 0px 4px;
        display:inline;
        display:initial;
    }
}

.MeetingScheduler_undelineDercoration__35suq {
    font-weight: 600;
    text-decoration: underline;
    -webkit-text-decoration-color: #b5b5b5;
            text-decoration-color: #b5b5b5;
    -webkit-text-decoration-style: dotted;
            text-decoration-style: dotted;
    cursor: pointer;
}

.MeetingScheduler_timezonesTooltip__1w7fc {
    font-size: 14px;
}
.MeetingChoices_container__tMH4L {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
}

.MeetingChoices_stickyBarOffset__2YnYz {
    height: 150px;
}

.MeetingChoices_content__2YYEd {
    padding: 8px;
}

.MeetingChoices_stickyBar__3BlUL {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 12px;
    position: fixed;
    display: flex;
    width: 100%;
    bottom: 0px;
    left: 0px;
    box-shadow: 0px -4px 10px rgba(211, 211, 211, 0.5);
    background-color: white;
}

.MeetingChoices_stickyBarContent__36BJM {
    display: flex;
    flex-direction: column;
}

.MeetingChoices_doneButton__1DjIl {
    /* width: 100%; */
    width: 300px;
    height: 50px;
    font-weight: 700;
    border-radius: 5px;
}

.MeetingChoices_icons__2rMte {
    margin: 10px 0px 20px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    color: var(--text-secondary-color);
}

.MeetingChoices_icon__2LEcG {
    margin-right: 2px;
}


.MeetingChoices_header__1sRKL {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    line-height: 1.4;
    margin-top: 60px;
}

.MeetingChoices_bubblesContainer__sz6Ex {
    margin: 24px 0px 20px 0px;
    display: flex;
    flex-direction: row;
}

.MeetingChoices_userAvatar__BWL0q {
    margin-right: -8px;
}

.MeetingChoices_title__2xsSU {
    font-size: 22px;
    font-weight: 700;
    color: var(--dark-purple);
}

.MeetingChoices_subTitle__3wu5t {
    margin-top: 6px;
    font-size: 16px;
    color: var(--text-secondary-color)
}

.MeetingChoices_guide__18Ajb {
    font-size: 16px;
    /* font-weight: 600; */
    color: var(--dark-grey);
    margin-bottom: 12px;
}

.MeetingChoices_selectedDatesNumber__25d65 {
    /* color: var(--primary); */
    font-weight: 700;
}

.MeetingChoices_calendar__z665l {
    margin-top: 20px;
    padding: 12px 34px;
    height: auto;
    border: 1px solid rgb(224, 229, 247);
    border-radius: 5px 5px 0px 0px;
    min-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
}

@media (max-width: 599px) {
    .MeetingChoices_calendar__z665l {
        flex-direction: column;
    }
}

.MeetingChoices_dayColumn__EtlfC {
    display: flex;
    flex-direction: column;
    padding: 0px 8px;
    align-items: center;
}

@media (max-width: 599px) {
    .MeetingChoices_dayColumn__EtlfC {
        margin-top: 12px;
        align-items:unset;
    }
}

.MeetingChoices_dayName__1eRnH {
    font-weight: 700;
    font-size: 15px;
}

.MeetingChoices_dayDate__1bEz0 {
    margin-top: 2px;
    color: var(--text-secondary-color);
    font-size: 12px;
    font-weight: 400;
}

.MeetingChoices_timesColumn__3B2Bp {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
}

.MeetingChoices_timeOption__3YmCc {
    transition: all 0.2s ease 0s;
    width: 66px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    padding: 1px 12px;
    white-space: nowrap;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    font-size: 16px;
    color: var(--text-secondary-color);
    border: 1px solid var(--easy-grey);
    margin-bottom: 12px;
}

@media (max-width: 599px) {
    .MeetingChoices_timeOption__3YmCc {
        width: 100%;
    }
}

.MeetingChoices_timeOption__3YmCc:hover {
    background-color: var(--primary-contrast-light);
}

.MeetingChoices_timeOptionSelected__PNYtP {
    background-color: var(--primary);
    color: white;
}

.MeetingChoices_timeOptionSelected__PNYtP:hover {
    background-color: var(--primary);
}

.MeetingChoices_timezone__29EpM {
    margin-top: 8px;
    font-size: 13px;
    color: var(--text-secondary-color);
}

.MeetingChoices_nextWeekButton__35bQk {
    position: absolute;
    right: 14px;
    top: 14px;
    cursor: pointer;
}

.MeetingChoices_previousWeekButton__2ypaR {
    left: 14px;
    right: unset;
}

.MeetingChoices_weekText__1NFi- {
    display: none;
    font-size: 12px;
}

@media (max-width: 599px) {
    .MeetingChoices_weekText__1NFi- {
        padding: 0px 4px;
        display: inline;
        display: initial;
    }
}

.MeetingChoices_selectedDate__3ZLJe {
    font-size: 18px;
    font-weight: 700;
    margin: 3px 0px;
}
.EventSider_container__2a1Mt {
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
}

@media (max-width: 599px) {
    .EventSider_container__2a1Mt {
        padding: 0px;
    }
}

.EventSider_navigationPanel__3aldj {
    box-shadow: none !important;
}

.EventSider_tabs__36B97 {
    width: 280px;
}

.EventSider_tabContainer__1FDdp {
    width: 280px;
}

.EventSider_attendees__3adG0>* {
    margin-bottom: 20px;
}

.EventSider_attendees__3adG0 {
    padding: 16px 10px;
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    flex-grow: 1;
    max-height: calc(100vh - 60px);
}

.EventSider_attendees__3adG0::-webkit-scrollbar {
    display: none;
}

.EventSider_attendeeContainer__3-M1j {
    display: flex;
    align-items: center;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;

}

.EventSider_texts__2CLyJ {
    margin-left: 8px;
    margin-right: 12px;
}

.EventSider_attendeeName__1VH1g {
    display: flex;
    flex-direction: row;
    font-size: 15;
    font-weight: 600;
    align-items: center;
}

.EventSider_badges__3JUNw {
    margin-left: 6px;
    display: flex;
}

.EventSider_badges__3JUNw>* {
    margin-right: 4px;
}

.EventSider_youBadge__3P718 {
    padding: 0px 4px;
    font-size: 9px;
    background-color: var(--ok-green);
    border-radius: 4px;
    color: white;
}

.EventSider_speakerBadge__22ma7 {
    background-color: #d00061;
    color: white;
}

.EventSider_hostBadge__3OF-P {
    background-color: var(--primary);
    color: white;
}

.EventSider_firstTimerBadge__2h0_S {
    /* composes: youBadge; */
    font-size: 9px;

    /* background-color: #d0b800; */
    color:  #d0b800;
}

.EventSider_attendeeTitle__13n6V {
    font-size: 12px;
    color: var(--text-secondary-color);
}

.EventSider_buttonsContainer__qSbBo {
    margin-top: 20px;
    display: grid;
    grid-row-gap: 40px;
    row-gap: 40px
}

.EventSider_smallInfoText__2DP7I {
    font-size: 12px;
    color: var(--text-secondary-color);
}

.EventSider_className__1AP7k::-webkit-scrollbar {
    display: none;
}

.EventSider_siderHeader__2Fq_z {
    display: flex;
    align-items: center;
}

.EventSider_attendeesCollapsed__3j0BZ {
    padding: 10px 0px;
    overflow: scroll;
    scrollbar-width: none;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: calc(100vh - 100px);
}

.EventSider_attendeeContainerCollapsed__24uaa {
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;
}

@media (max-width) {
    .EventSider_attendeeContainerCollapsed__24uaa {
        transform: none;
        transform: initial;
    }
}

.EventSider_enblePrivateTalksContainer__2GvON {
    margin-top: 20px;
}

.EventSider_externalLinksContainer__pcFBO {
    margin-top: 40px;
}

.EventSider_header__1Fv0k {
    font-weight: 700;
    color: #00063c;
    margin-bottom: 8px;
}

.EventSider_links__6WaME {
    margin-top: 12px;
}

.EventSider_links__6WaME>* {
    margin-bottom: 8px;
}

.EventSider_goToStageButton__2RpG3 {
    color: #ce871c;
}

.EventSider_goToLoungeButton__14Yan {
    color: #963ffa;
}

.EventSider_attendeeDivider__JPZNX {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.EventSider_talkButton__2PC6D {
    border-color: var(--primary);
    color: var(--primary);
    position: absolute;
    right: -6px;
    border: none;
}

.EventSider_busy__KNCns {
    height: 24px;
    padding: 0px 7px;
    font-size: 14px;
    border-radius: 2px;
    border: 1px solid #ce871c;
    color: #ce871c;
    margin-left: auto;
}

.EventSider_notification__3eO66 {
    background: var(--red);
    width: 4px;
    height: 4px;
    margin-left: 5px;
    border-radius: 50%;
}

.EventSider_tabWithNotification__2xo77 {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    /* flex-direction: row; */
    /* justify-content: center; */
    /* align-items: center; */
}

.EventSider_onlineUsers__8toOi {
    margin-top: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin-left: 4px;
    color: #cbcbcb;
}

.EventSider_onlineBubble__FsmvT {
    background: rgb(1, 222, 119);
    width: 4px;
    height: 4px;
    margin-left: 5px;
    border-radius: 50%;
    margin-right: 2px;
}

.EventSider_actions__17dq7 {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
}

.EventSider_adminButton__Mfj9q {
    margin-right: 8px;
    border: none;
    box-shadow: none !important;
    padding-right: 0px;
    padding-left: 0px;
    margin-left: 12px;
}

.EventSider_profileBubble__1s1K6 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: auto;
}

.EventSider_arrowContainer__20oof {
    margin-right: 12px;
}

.EventSider_navigation__hCBkv {
    position: absolute;
    top: -1px;
    margin-left: -20px;
}

.event-sider .ant-radio-button-wrapper {
    color: #989898;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.event-sider .ant-tabs-top>.ant-tabs-nav {
    margin: 0px;
}

.event-sider .ant-tabs-tab {
    margin: 0px;
    display: flex;
    justify-content: center;
    width: 100%;
    color: #9a9a9d;
    font-weight: 600;
}

.event-sider .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
}

.event-sider .ant-tabs-top>.ant-tabs-nav::before {
    /* border-bottom: none; */
}

.EventSider_container__2a1Mt:hover .EventSider_handler__2vmJe {
    opacity: 1;
}


.EventSider_handler__2vmJe {
    height: 80px;
    width: 20px;
    top: 45%;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.256);
    position: absolute;
    left: -26px;
    display: flex;
    opacity: 0;
    justify-content: center;
    align-items: center;
    color: white;
    transition: all 0.3s;
    cursor: pointer;
}

.EventSider_handler__2vmJe:hover {
    background-color: rgba(255, 255, 255, 0.379);
    opacity: 1;
}


@media (max-width: 599px) {
    .EventSider_handler__2vmJe {
        opacity: 1;
        color: var(--dark-purple);
        background-color: rgba(255, 255, 255, 0.756);
        border: 1px solid rgb(216, 216, 216);
        left: -26px;
        padding: 12px;
        box-shadow: rgba(21, 21, 25, 0.708) 0px 10px 40px;
    }
    .EventSider_handler__2vmJe:hover {
        background-color: rgba(255, 255, 255, 0.756);
    }
}

.EventSider_handlerMobile__1Kx4T {
    opacity: 1;
    padding: 12px;
}

.EventSider_userAvatar__3CFN4 {
    transform: scale(0.9);
}
.EventChat_container__3y3R9 {
    display: flex;
    flex-direction: column;
    position: relative;
    /* width: 100%; */
}

.EventChat_empty__1Pu6g {
    height: calc(100vh - 50px);
    padding-top: 20px;
    color: #8e8e8e;
}

.EventChat_messagesContainer__2ZHsh {
    padding: 12px;
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding-bottom: 50px;
    height: calc(100vh - 50px);
}

@media (max-width: 599px) {
    .EventChat_messagesContainer__2ZHsh {
        height: calc(100vh - 170px);
    }    
}


.EventChat_messagesContainer__2ZHsh::-webkit-scrollbar {
    display: none;
}

.EventChat_messageBackground__3nacF{
    background-color: white;
}

.EventChat_input__PToKn {
    border: none;
    width: 100%;
}

.EventChat_sendMessageContainer__3ryPp {
    display: flex;
    width: 100%;
    margin-top: 20px;
    position: absolute;
    bottom: 0px;
    right: 0;
    left: 0;
    border-top: 1px solid #e9e9e9;
    padding: 10px;
}

.EventChat_sendButton__1n039 {
    padding: 5px;
    margin-left: auto;
    margin-left: 18px;
    cursor: pointer;
    color: var(--primary);
}

.EventChat_notification__10ZEQ {
    background: var(--red);
    width: 4px;
    height: 4px;
    margin-left: 5px;
    border-radius: 50%;
}

.EventChat_tabWithNotification__IzBZ1 {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    color: #9a9a9d;
    font-weight: 600;
    font-size: 14px;
    padding: 16px 12px;
    border-bottom: 1px solid #f0f0f0;

}

.message-container .ant-mentions:hover {
    border-width: 0px !important;
    border-color: transparent !important;
    box-shadow: none;
}

.message-container .ant-mentions > textarea {
    background:white;
    
}
.message-container .ant-mentions > textarea::-webkit-input-placeholder {
    color:#525252de !important;
    
}
.message-container .ant-mentions > textarea:-ms-input-placeholder {
    color:#525252de !important;
    
}
.message-container .ant-mentions > textarea::-ms-input-placeholder {
    color:#525252de !important;
    
}
.message-container .ant-mentions > textarea::placeholder {
    color:#525252de !important;
    
}

.message-container .ant-mentions:focus {
    border-width: 0px !important;
    border-color: transparent !important;
    box-shadow: none;
}

.message-container .ant-mentions-focused {
    border-width: 0px !important;
    border-color: transparent !important;
    box-shadow: none;
}
.ChatMessage_container__1nYSe {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;    
}

.ChatMessage_texts__Vu7mD {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    justify-content: center;
}

.ChatMessage_titleContainer__cUceC {
    display: flex;
    align-items: center;
}

.ChatMessage_name__jZ7KW {
    font-weight: 600;
    color: var(--dark-black);
    font-size: 14px;
}

.ChatMessage_time__2KMwh {
    margin-left: 6px;
    font-size: 12px;
    color: #b0b0b0;
}

.ChatMessage_message__kUHGX {
    margin-top: 2px;
    font-size: 14px;
    color: #053737f0;
    background-color: var(--primary-contrast-light);
    padding: 8px;
    border-radius: 10px;
    line-height: 1.6;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.ChatMessage_avatar__2JtB7 {
    display: flex;
    justify-content: center;
    margin-top: 2px;
}

.ChatMessage_host__3PbC2 {
    margin-left:6px;
    font-size: 10px;
    color:var(--primary);
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
}
.NavigationPanel_container__LBcge {
    padding: 10px 0px 20px 0px;
    width: 70px;
    border-left: 1px #F7F7F7 solid;
    background-color: white;
    flex-shrink: 0;
    box-shadow: rgba(245, 245, 245, 0.708) 0px 0px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--dark-purple);
}



.NavigationPanel_logoContainer__iBww8 {
    width: 43px;
    height: 43px;
    box-shadow: rgba(213, 213, 213, 0.708) 0px 0px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    transition: all 0.3s;
}

.NavigationPanel_logo__RoJVB {
    width: 20px;
    flex-shrink: 0;
}

.NavigationPanel_logoContainer__iBww8:hover {
    transform: scale(1.1);
}

.NavigationPanel_bottomItems__3kde8 {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 80px;
}

@media (max-width: 599px) {
    .NavigationPanel_bottomItems__3kde8 {
        margin-bottom: 0px;
    }
    
}

.NavigationPanel_horizontalDivider__2hxt7 {
    margin: 12px 0px;
    background-color: #e1e1e1;
    height: 1px;
    width: 100%;
}

.NavigationPanel_leaveEventButton__2HSSR {
    margin-top: 34px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
}

.NavigationPanel_leaveEventButton__2HSSR img {
    width: 16px;
    margin-bottom: 14px;
}

.NavigationPanel_leaveEventButton__2HSSR div {
    font-size: 12px;
}


.NavigationPanel_centerItems__2sO2f{
    margin-top: 50px;
}

.NavigationPanel_centerItems__2sO2f > * {
    margin-bottom: 34px;
}


.NavigationPanel_navigationButton__2MAqg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    transition: all 0.3s;
}

.NavigationPanel_navigationButtonSelected__2vHhY {
    color: var(--primary) !important;
    font-weight: 600 !important;
}

.NavigationPanel_navigationButtonDisabled__3UTGs {
    color: #c2c2c2;
}

.NavigationPanel_navigationButton__2MAqg:hover {
    color: var(--primary);
}
.NavigationPanel_navigationButtonDisabled__3UTGs:hover {
    color: #c2c2c2;
}

.NavigationPanel_navigationIconWrapper__3IMUG {
    padding: 8px 12px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;

}

.NavigationPanel_navigationIconWrapperSelected__2QNC6 {
    background-color: var(--primary-contrast-light);
}

.NavigationPanel_navigationIcon__3Q-kG {
    font-size: 26px;
    /* margin-top: -4px; */
}

.NavigationPanel_navigationLabel__5pC_w {
    margin-top: 6px;
    text-align: center;
    font-size: 12px;
}

.NavigationPanel_statusBubble__yl4wN {
    font-size: 9px;
    font-weight: 600;
    padding: 2px 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary);
    color: white;
    text-transform: capitalize;
    border: 1px white solid;
    position: absolute;
    right: -6px;
    border-radius: 4px;
    top: -8px;
}
.EventSettingsModal_modalHeader__qTBZ8 {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 12px;
}

.EventSettingsModal_setTimerButton__3eqQ- {
    margin-top: 12px;
}

.EventSettingsModal_cancelTimerButton__2PbkF {
    margin-left: 8px;
}

.EventSettingsModal_clearTimerButton__YbueW {
    margin-top: 12px;
    border-color: #ce871c;
    color: #ce871c;
}

.EventSettingsModal_enblePrivateTalksContainer__1UgsA {
    margin-top: 20px;
}


.EventSettingsModal_externalLinksContainer__1JXWC {
    margin-top: 20px;
}

.EventSettingsModal_header__3kmxn {
    font-weight: 700;
    color: #00063c;
    margin-bottom: 2px;
}

.EventSettingsModal_links__iKy84 {
    margin-top: 12px;
}

.EventSettingsModal_links__iKy84 > * {
    margin-bottom: 8px;
}
.AttendeeInfo_attendeePopover__2I87n {
    width: 340px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-row-gap: 20px;
    row-gap: 20px;
}

.AttendeeInfo_attendeeDivider__2fal3 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.AttendeeInfo_attendeePopover__2I87n .AttendeeInfo_header__2ZAlz {
    font-weight: 600;
    margin-bottom: 8px;
}

.AttendeeInfo_attendeePopover__2I87n .AttendeeInfo_basicInfo__1ftA2 {
    display: flex;
    align-items: center;
}

.AttendeeInfo_attendeePopover__2I87n .AttendeeInfo_basicInfoTexts__SUwId {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
}

.AttendeeInfo_attendeePopover__2I87n .AttendeeInfo_fullname__300tQ {
    font-weight: 700;
    font-size: 16px;
}

.AttendeeInfo_attendeePopover__2I87n .AttendeeInfo_title__2KzD2 {
    color: var(--dark-black);
    font-weight: 600;
    font-size: 13px;
}

.AttendeeInfo_attendeePopover__2I87n .AttendeeInfo_bio__3GeVx {
    color: var(--text-secondary-color) !important;
    font-size: 14px;
    height: 40px;
}

.AttendeeInfo_attendeePopover__2I87n .AttendeeInfo_bioAuto__2kIn1 {
    color: var(--text-secondary-color) !important;
    font-size: 14px;
    height: auto !important;
}

.AttendeeInfo_attendeePopover__2I87n .AttendeeInfo_industryTags__yTBGA {
    display: flex;
    flex-wrap: wrap;
}

.AttendeeInfo_attendeePopover__2I87n .AttendeeInfo_industryTags__yTBGA>* {
    margin-right: 6px;
    margin-bottom: 6px;
}

.PeopleTab_container__ILXdt {
    line-height: 1.5715;
}

.PeopleTab_attendees__2_HcQ>* {
    margin-bottom: 20px;
}

.PeopleTab_attendees__2_HcQ {
    padding: 16px 10px;
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    flex-grow: 1;
    max-height: calc(100vh - 60px);
}

.PeopleTab_attendees__2_HcQ::-webkit-scrollbar {
    display: none;
}

.PeopleTab_attendeeContainer__V_d6f {
    display: flex;
    align-items: center;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;

}

.PeopleTab_texts__1DczW {
    margin-left: 8px;
    margin-right: 12px;
}

.PeopleTab_attendeeName__2X_jG {
    display: flex;
    flex-direction: row;
    font-size: 15;
    font-weight: 600;
    align-items: center;
}

.PeopleTab_badges__3KbCw {
    margin-left: 6px;
    display: flex;
}

.PeopleTab_badges__3KbCw>* {
    margin-right: 4px;
}

.PeopleTab_youBadge__3zoPp {
    padding: 0px 4px;
    font-size: 9px;
    background-color: var(--ok-green);
    border-radius: 4px;
    color: white;
}

.PeopleTab_speakerBadge__25Kmi {
    background-color: #d00061;
    color: white;
}

.PeopleTab_hostBadge__7tK79 {
    background-color: var(--primary);
    color: white;
}

.PeopleTab_firstTimerBadge__3e-2x {
    /* composes: youBadge; */
    font-size: 9px;

    /* background-color: #d0b800; */
    color:  #d0b800;
}

.PeopleTab_attendeeTitle__4su1F {
    font-size: 12px;
    /* margin-top: 4px; */
    color: var(--text-secondary-color);
}

.PeopleTab_buttonsContainer__2x4W8 {
    margin-top: 20px;
    display: grid;
    grid-row-gap: 40px;
    row-gap: 40px
}

.PeopleTab_smallInfoText__30vS6 {
    font-size: 12px;
    color: var(--text-secondary-color);
}

.PeopleTab_className__3SeHl::-webkit-scrollbar {
    display: none;
}

.PeopleTab_siderHeader__3Woye {
    display: flex;
    align-items: center;
}

.PeopleTab_attendeesCollapsed__2rxH- {
    padding: 10px 0px;
    overflow: scroll;
    scrollbar-width: none;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: calc(100vh - 100px);
}

.PeopleTab_attendeeContainerCollapsed__3R3Hr {
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;
}

@media (max-width) {
    .PeopleTab_attendeeContainerCollapsed__3R3Hr {
        transform: none;
        transform: initial;
    }
}

.PeopleTab_enblePrivateTalksContainer__2LG3o {
    margin-top: 20px;
}

.PeopleTab_externalLinksContainer__1iJA9 {
    margin-top: 40px;
}

.PeopleTab_header__7dcAc {
    font-weight: 700;
    color: #00063c;
    margin-bottom: 8px;
}

.PeopleTab_links__1Bynu {
    margin-top: 12px;
}

.PeopleTab_links__1Bynu>* {
    margin-bottom: 8px;
}

.PeopleTab_goToStageButton__2brm3 {
    color: #ce871c;
}

.PeopleTab_goToLoungeButton__2Lb3e {
    color: #963ffa;
}

.PeopleTab_attendeeDivider__15WCc {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.PeopleTab_talkButton__1C8Rp {
    border-color: var(--primary);
    color: var(--primary);
    position: absolute;
    right: -6px;
    border: none;
}

.PeopleTab_busy__3Q1Ow {
    height: 24px;
    padding: 0px 7px;
    font-size: 14px;
    border-radius: 2px;
    border: 1px solid #ce871c;
    color: #ce871c;
    margin-left: auto;
}

.PeopleTab_notification___Y9A9 {
    background: var(--red);
    width: 4px;
    height: 4px;
    margin-left: 5px;
    border-radius: 50%;
}

.PeopleTab_tabWithNotification__4OB0j {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    color: #9a9a9d;
    font-weight: 600;
    font-size: 14px;
    padding: 16px 12px;
    border-bottom: 1px solid #f0f0f0;

}


.PeopleTab_onlineUsers__3Pw5T {
    margin-top: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin-left: 4px;
    color: #cbcbcb;
}

.PeopleTab_onlineBubble__19nxV {
    background: rgb(1, 222, 119);
    width: 4px;
    height: 4px;
    margin-left: 5px;
    border-radius: 50%;
    margin-right: 2px;
}

.PeopleTab_actions__1AVCt {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
}

.EventTimer_container__x2tyX {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.83);
    box-shadow: rgb(21 21 25 / 71%) 0px 10px 40px;
    position: absolute;
    top: 12px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    width: 270px;
    color: white;
}

.EventTimer_label__25ErP {
    font-size: 15px;
    line-height: 1.4;
}

.EventTimer_countdown__2XNsx {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 800;
}

.EventTimer_container__x2tyX:hover > .EventTimer_hideButton__1WNHQ {
    display: flex;
}

.EventTimer_hideButton__1WNHQ {
    background-color: var(--primary);
    font-size: 10px;
    color: white;
    position: absolute;
    top: -8px;
    right: -5px;
    width: 20px;
    height: 20px;
    display: none;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-weight: bold;
    cursor: pointer;
    box-shadow: rgba(21, 21, 25, 0.708) 0px 10px 40px;

}
.EventLounge_container__swmKd {
    display: flex;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
}

@media (max-width: 599px) {
    .EventLounge_container__swmKd {
        height: 100%;
    }
}

.EventLounge_iframe__3noIs {
    border: 0;
    width: 100%;
    height: 100%;
}

.EventLounge_helpBox__3MEn1 {
    background-color: white;
    width: 200px;
    height: 100px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(198, 198, 198, 0.5);
    position: 0;
    bottom: 20px;
    left: 20px;
    z-index: 4;
    position: absolute;
}

.EventLounge_watermark__3xIbg {
    padding: 8px 70px;
    background-color: rgba(255, 255, 255, 0.981);
    width: auto;
    height: 48px;
    border-radius: 5px;
    opacity: 1;
    display: flex;
    align-items: center;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.EventLounge_logo___O5J1 {
    margin-top: -4px;
    /* height: 28px; */
    width: 140px;
}

.EventLounge_title__2Ggfu {
    font-weight: 500;
    margin-top: 2px;
    margin-left: 4px;
    opacity: 0.7;
    font-size: 15px;
}

.EventLounge_buttonsContainer__2DwE_ {
    margin-top: 12px;
}

.EventLounge_startSessionButton__3B0Dn {
    background: linear-gradient(1deg, rgb(255, 248, 57) 0%, rgb(255, 167, 85) 100%);
    font-size: 15px;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.927);
    font-weight: 700;
    cursor: pointer;
    border: none;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    opacity: 1;
    transition: 0.3s;
}

.EventLounge_startSessionButton__3B0Dn:hover {
    background: linear-gradient(1deg, rgb(255, 248, 57) 0%, rgb(255, 167, 85) 100%);
    color: rgba(0, 0, 0, 0.927);
    opacity: 0.8;
}

.EventLounge_endSessionButton__3-gIC {
    border: none;
    background: linear-gradient(1deg, rgb(255, 57, 57) 0%, rgb(194, 0, 0) 100%);
    font-size: 15px;
    border-radius: 5px;
    color: white;
    font-weight: 600;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    opacity: 1;
    transition: 0.5s;
}

.EventLounge_endSessionButton__3-gIC:hover {
    background: linear-gradient(1deg, rgb(255, 57, 57) 0%, rgb(194, 0, 0) 100%);
    opacity: 0.8;
}

.EventLounge_floating__1QTM3 {
    position: absolute;
    left: 8px;
    top: 8px;
}

.EventLounge_endButtonContainer__2QlNi {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width: 599px) {
    .EventLounge_endButtonContainer__2QlNi {
        display: none;
    }
}

.EventLounge_eventLiveContainer__2RTZf {
    display: flex;
    flex-direction: row;
    color: white;
    font-weight: 600;
    font-size: 12px;
    margin-left: 12px;
    align-items: center;
}

.EventLounge_liveBubble__o-7rw {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: rgb(1, 222, 119);
    margin-right: 6px;
}

.EventStage_container__2fUEx {
    width: 100%;
    min-height: 100vh;
    height: 100vh;
    padding: 40px 30px 30px 30px;
    background: url(/static/media/bg.9f6c34d6.jpg) no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
}

.EventStage_topStrip__39tnf {
    height: 50px;
    color: white;
    flex-shrink: 0;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width: 599px) {
    .EventStage_topStrip__39tnf {
        flex-direction: column;
        height: auto;
        margin-bottom: 20px;
        align-items: flex-start;
    }
}

.EventStage_buttons__2feZ3 {
    display: flex;
    align-items: center;
    margin-left: auto;
}

@media (max-width: 599px) {
    .EventStage_buttons__2feZ3 {
        margin-top: 16px;
        margin-left: 0px    ;
    }
    
}

.EventStage_endSessionButton__1qQK-:hover {
    background: linear-gradient(1deg, rgb(255, 57, 57) 0%, rgb(194, 0, 0) 100%);
    opacity: 0.8;
}

.EventStage_goToLoungeButton__3gSYb {
    font-size: 15px;
    background: linear-gradient(1deg, rgb(196, 57, 255) 0%, rgb(136, 85, 255) 100%);
    padding: 12px 16px;
    border-radius: 5px;
    color: rgba(255, 255, 255, 0.899);
    font-weight: 600;
    cursor: pointer;
}

.EventStage_buttonsDivider__2Dl3s {
    background: #7b7b7e;
    width: 1px;
    margin-right: 16px;
    margin-left: 16px;
}

.EventStage_header__3yMNG {
    font-size: 24px;
    font-weight: 700;
}

.EventStage_smallHeader__hHGvR {
    display: flex;
    font-size: 15px;
    color: var(--primary);
    font-weight: 600;
    margin-bottom: 10px;
}

.EventStage_videoContainer__1apUw {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
}

.EventStage_frameContainer__1vO6d {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(-45deg, #005e45, #086171, #6f0874, #9a2d0b);
    background-size: 400% 400%;
    animation: EventStage_gradient__2tUeU 15s ease infinite;
    border-radius: 30px;
    padding: 3px;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.EventStage_iframe__2KD3I {
    border: 0;
    border-radius: 30px;
    width: 100%;
    height: 100%;
}

.EventStage_hideiFrame__iSiKn {
    display: none;
}

.EventStage_loading__3eA4C {
    font-size: 24px;
    font-weight: 700;
    color: white;
}

.EventStage_aspectRatio__ey6O_ {
    height: 100%;
    padding-bottom: 56.25%;
}

@keyframes EventStage_gradient__2tUeU {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.EventStage_liveIndicator__1rct9 {
    display: flex;
    font-size: 14px;
    margin-right: 12px;
    align-items: center;
}

.EventStage_liveDot__2JqLr {
    margin-right: 4px;
    background: rgb(1, 181, 58);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    animation: EventStage_opacityAnimation__T_rvO 0.8s alternate-reverse infinite;
}

@keyframes EventStage_opacityAnimation__T_rvO {
    0% {
        opacity: 0.2;
    }
    100% {
        opacity: 1;
    }
}

.EventStage_explainerMessage__b1jut {
    font-weight: 600;
}
.LiveEventPage_container__3tCjI {
    display: flex;
    min-height: 100vh;
    width: 100%;
    background: url(/static/media/bg.9f6c34d6.jpg) no-repeat;
    background-size: cover;
}

@media (max-width: 599px) {
    .LiveEventPage_container__3tCjI {
        position: fixed;
        min-height: 100%;
        overflow: hidden;
    }
}

.LiveEventPage_content__1dms3 {
    position: relative;
    flex: auto;
    height: 100%;
}

@media (max-width: 599px) {
    .LiveEventPage_content__1dms3 {}
}

.LiveEventPage_sider__22FQt {
    /* height: 100%; */
    right: 0;
    /* position: fixed; */
    flex-shrink: 0;
    background-color: white;
    z-index: 10;
    border-left: 1px solid var(--kinky-grey);
    padding: 20px;
    width: auto !important;
    max-width: 100% !important;
    min-width: auto !important;
    flex: initial !important;
    
}

@media(max-width: 599px) {
    .LiveEventPage_sider__22FQt {
        /* position: fixed; */
        height: 100vh;
        /* right: 0px; */
    }
}

.LiveEventPage_loadingContainer__mKa-5 {
    position: absolute;
    width: 400px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.LiveEventPage_loadingHeader__R4uZ7 {
    font-weight: 700;
    font-size: 28px;
    line-height: 1.4;
    text-align: center;
    color: white;
}

.LiveEventPage_doneAnimation__1DclI {
    margin-bottom: 30px;
    color: white;
    width: 140px;
}

.LiveEventPage_newAttendeeNotification__1snOz {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
}

.LiveEventPage_newAttendeeNotification__1snOz>* {
    margin-right: 18px;
}

.LiveEventPage_messageNotification__2aONl {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.LiveEventPage_messageNotificationTexts__2zDXx {
    margin-left: 12px;
}

.LiveEventPage_messageNotificationTitle__ZoqlN {
    font-weight: 600;
}

.LiveEventPage_mobileHandler__xtjci {
    padding: 0px;
    position: absolute;
    right: 0;
    background-color: #f4f6fc;
    box-shadow: 0 0 10px 0 rgba(198, 198, 198, 0.5);
    top: calc(var(--header-size) + 10px);
    z-index: 999999;
}

.LiveEventPage_startSessionButton__k8ERO {
    background: linear-gradient(1deg, rgb(255, 248, 57) 0%, rgb(255, 167, 85) 100%);
    font-size: 15px;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.927);
    font-weight: 600;
    cursor: pointer;
    border: none;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    opacity: 1;
    transition: 0.3s;
}

.LiveEventPage_startSessionButton__k8ERO:hover {
    background: linear-gradient(1deg, rgb(255, 248, 57) 0%, rgb(255, 167, 85) 100%);
    color: rgba(0, 0, 0, 0.927);
    opacity: 0.8;
}

.LiveEventPage_endSessionButton__2aQRB {
    border: none;
    background: linear-gradient(1deg, rgb(255, 57, 57) 0%, rgb(194, 0, 0) 100%);
    font-size: 15px;
    border-radius: 5px;
    color: white;
    font-weight: 600;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    opacity: 1;
    transition: 0.5s;
}

.LiveEventPage_endSessionButton__2aQRB:hover {
    background: linear-gradient(1deg, rgb(255, 57, 57) 0%, rgb(194, 0, 0) 100%);
    opacity: 0.8;
}

.LiveEventPage_modalHeader__3rzD2 {
    color: var(--dark-black);
    font-weight: 600;
}

.LiveEventPage_modalText__3lPpC {
    margin-top: 8px;
    color: var(--text-secondary-color);
}

.LiveEventPage_buttonsDivider__Cx18B {
    background: #7b7b7e;
    width: 1px;
    height: 40px;
    margin-right: 20px;
    margin-left: 20px;
}

.LiveEventPage_addTableButton__38ihn {
    color: white;
    border-color: white;
    background-color: transparent;
    transition: 0.3;
}

.LiveEventPage_addTableButton__38ihn:hover {
    color: white;
    border-color: white;
    background-color: rgba(255, 255, 255, 0.084);
}

.user-online .ant-notification-notice-message {
    margin-bottom: 0 !important;
}

.LiveEventPage_userOnline__RlLd1 {
    padding: 8px !important;
    /* background-color: rgba(255, 255, 255, 0.598); */
}

.LiveEventPage_feedbackCard__m3J8D {
    position: absolute;
    bottom: 20px;
    left: 20px;
    padding: 0px 10px;
    width: 100%;
    max-width: 360px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 599px) {
    .LiveEventPage_feedbackCard__m3J8D {
        bottom: 20px;
        left: 10px;
        /* right: auto; */
    }
}

.LiveEventPage_hideFeedbackButton__3MD_6 {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    padding: 8px;
    color: var(--text-secondary-color);
}
.EventTransitionNotification_container__2otIg {
    height: auto;
    background: #ffd602;
    padding: 20px 30px;
    color: #2e2a20;
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width: 599px) {
    .EventTransitionNotification_container__2otIg {
        padding: 20px 12px;
    }
}

.EventTransitionNotification_header__210qO {
    font-size: 20px;
    font-weight: 700;
}

.EventTransitionNotification_subHeader__2GEB7  {
    margin-top: 10px;
    font-size: 16px;
    font-size: 400;
    line-height: 1.4;
}

.EventTransitionNotification_counterBox__22G_n {
    width: 80px;
    height: 80px;
    color: white;
    display: flex;
    background-color: #222226;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 28px;
    margin-right: 20px;
    flex-shrink: 0;
}
.IncomingConversationNotification_container__IXXtS {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width: 599px) {
    .IncomingConversationNotification_container__IXXtS {
        flex-direction: column;
        text-align: center;
    }
}

.IncomingConversationNotification_messageContainer__1kqzO {
    margin-left: 20px;
}

@media (max-width: 599px) {
    .IncomingConversationNotification_messageContainer__1kqzO {
        margin-left: 0px;
        margin-top: 16px;
        margin-bottom: 20px;
    }
}

.IncomingConversationNotification_title__uYdiH {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 2px;
}

.IncomingConversationNotification_subTitle__xp9Sb {
    color: var(--text-secondary-color);
}

.IncomingConversationNotification_buttonsContainer__1aGIK {
    margin-left: 24px;
}

@media (max-width: 599px) {
    .IncomingConversationNotification_buttonsContainer__1aGIK {
        margin-top: 16px;
    }
}

.IncomingConversationNotification_acceptButton__t5TgE {
 width: 100%;
}


.IncomingConversationNotification_rejectButton__s-iKo {
    margin-top: 10px;
    width: 100%;
    border-color: var(--red);
    color: var(--red);
}
.ConversationRequestModal_container__rVgm7 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.ConversationRequestModal_title__34jw6 {
    font-weight: 800;
    font-size: 19px;
    line-height: 1.4;
    text-align: center;
}

.ConversationRequestModal_subTitle__QzeV- {
    margin-top: 8px;
    text-align: center;
    color: var(--text-secondary-color);
}

.ConversationRequestModal_avatarsContainer__2bAhJ {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 40px;
    margin-bottom: 50px;
    width: 300px;
}

.ConversationRequestModal_avatar__2IYaW {
    
}

.ConversationRequestModal_cancelButtonContainer__36-c6 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ConversationRequestModal_countdownText__1cZNh {
    color: var(--text-secondary-color);
    font-size: 12px;
    margin-top: 10px;
    text-align: center;
}
.ConversationPrepareModal_container__8pSXh {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.ConversationPrepareModal_title__17D0G {
    font-weight: 800;
    font-size: 19px;
    line-height: 1.4;
    text-align: center;
}

.ConversationPrepareModal_subTitle__1RUvS {
    margin-top: 8px;
    text-align: center;
    color: var(--text-secondary-color);
}

.ConversationPrepareModal_loader__3wrr_ {
    margin: 30px 0px;
    width: 100%;
    height: 250px;
}



.VideoButtonsStrip_container__QOBVD {
    display: flex;
    flex-direction: row;
}

@media (max-width: 599px) {
    .VideoButtonsStrip_container__QOBVD {
        flex-direction: column;
    }
    .VideoButtonsStrip_container__QOBVD > * {
        margin-bottom: 16px;
    }
}

.VideoButtonsStrip_container__QOBVD > * {
    margin-right: 16px;
}


.VideoButtonsStrip_endTalkButton__SXa6_ {
    background-color: #fe4d4d;
    color: white;
    font-weight: 700;
    border: none;
}

.VideoButtonsStrip_regularButton__8FgiT {
    color: white;
    border-color: white;
    background-color: transparent;
}

.VideoButtonsStrip_divider__2psFw {
    background: #7b7b7e;
    width: 1px;
    margin-right: 20px;
    margin-left: 4px;
}

@media (max-width: 599px) {
    .VideoButtonsStrip_divider__2psFw {
        display: none;
    }
}


.VideoButtonsStrip_sendMessageButton__1RaOY {
    margin-top: 4px;
}

.VideoButtonsStrip_message__17rF3 {
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    
}

.VideoButtonsStrip_messageContainer__21tE0 {
    display: flex;
    padding: 0px 4px 4px 4px;
    font-size: 16px;
    flex-direction: column;
}
.ParticipantIntroBubble_container__15-D5 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    -webkit-column-gap: 40px;
            column-gap: 40px;
    padding: 34px 24px;
    background-color: #18161d;
    border-radius: 15px;
    line-height: 1.4;
}

@media (max-width: 599px) {
    .ParticipantIntroBubble_container__15-D5 {
        grid-template-columns: 1fr;
        grid-column-gap: 0px;
        -webkit-column-gap: 0px;
                column-gap: 0px;
        grid-row-gap: 40px;
        row-gap: 40px;
    }
}

.ParticipantIntroBubble_header__1tpto {
    font-size: 18px;
    color: white;
    font-weight: 700;
}

.ParticipantIntroBubble_text__3FeDO {
    color: #E8E3FF;
}

.ParticipantIntroBubble_bio__3yqWH {
    margin-top: 12px;
    color: #E8E3FF;
    line-height: 1.5;
}

.ParticipantIntroBubble_subHeader__2Bdxf {
    font-size: 16px;
    color: #E8E3FF;
    font-weight: 700;
}

.ParticipantIntroBubble_comapnyInfo__1pE-A {
    margin-top: 24px;
    color: #E8E3FF;
}

.ParticipantIntroBubble_industryTags__1dzQo {
    margin-top: 12px;
    display: flex;
    flex-wrap: wrap;
}

.ParticipantIntroBubble_industryTags__1dzQo>* {
    margin-right: 6px;
    margin-bottom: 6px;
}

.ParticipantIntroBubble_tag__3HzqI {
    background-color: #008571 !important;
    color: white !important;
    font-size: 14px !important;
    border-radius: 2px;
}
:root {
    --videoContainerPaddingSize: 2px;
    --videoContainerBorderRadius: 10px;
}

.Participant_container__HL9pB {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-shrink: 1;
    
}

.Participant_videoContainer__2K7Hd {
    position: relative;
    border-radius: 10px;
    border-radius: var(--videoContainerBorderRadius);
    overflow: hidden; 
    padding: 2px; 
    padding: var(--videoContainerPaddingSize);
    height: 100%;
}

.Participant_video__1ASBW {
    border-radius: 10px;
    border-radius: var(--videoContainerBorderRadius);   
    width: 100%;
    height: 100%;
    object-fit: cover;
    flex-shrink: 1;
}


.Participant_local__36_Ci {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #cc23d5, #d54c23);
	background-size: 400% 400%;
	animation: Participant_gradient__2iQsS 15s ease infinite;
    /* background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab); */

}

.Participant_other__3Wpv7 {
    background: linear-gradient(-45deg, #1CBEC0, #3cb9e7, #235bd5, #09dcab);
	background-size: 400% 400%;
	animation: Participant_gradient__2iQsS 15s ease infinite;
}

@keyframes Participant_gradient__2iQsS {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


.Participant_videoOverlay__19zF4 {
    position: absolute;
    bottom: 2px;
    bottom: var(--videoContainerPaddingSize);
    right: 2px;
    right: var(--videoContainerPaddingSize);
    left: 2px;
    left: var(--videoContainerPaddingSize);
    height: 100px;    
    border-bottom-right-radius: 10px;    
    border-bottom-right-radius: var(--videoContainerBorderRadius);
    border-bottom-left-radius: 10px;
    border-bottom-left-radius: var(--videoContainerBorderRadius);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
}

.Participant_userInfo__2VJ-0 {
    position: absolute;
    bottom: 0;
    padding: 20px 20px 22px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.Participant_texts__m0SId {
    margin-left: 20px;
}

.Participant_participantName__dO38u {
    font-weight: 600;
    font-size: 16px;
    color: white;
}

.Participant_participantTitle__sVYeh {
    font-size: 14px;
    color: white;
    margin-top: 8px;
}

.Participant_controlButtons__1bIrr {
    display: flex;
    flex-direction: row;
    justify-self: flex-end;
    align-self: flex-end;
    margin-left: auto;

}

.Participant_controlButtons__1bIrr > * {
    margin-left: 16px;
}


.Participant_message__V8tHW {
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    
}

.Participant_messageContainer__2E65L {
    display: flex;
    padding: 0px 4px 4px 4px;
    font-size: 16px;
    flex-direction: column;
}

.Participant_cameraOffContainer__2jJgW {
    display: flex;
    height: 100%;
    width: 100%;
    min-height: 300px;
    justify-content: center;
    align-items: center;
}

.Participant_avatar__2J6-I {
}

.Participant_controlButton__212S7 {
    padding: 5px;
}
.VideoRoom_container__t3_0z {
    display: flex;
    flex-direction: column;
    height: auto;
}

.VideoRoom_participantsContainer__ZWH-S {
    display: grid;
    flex-direction: row;
    grid-template-columns: 1fr 1fr;
    flex-wrap: nowrap;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
}

@media (max-width: 599px) {
    .VideoRoom_participantsContainer__ZWH-S {
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
        row-gap: 20px;
        grid-column-gap: 0px;
        -webkit-column-gap: 0px;
                column-gap: 0px;
    }
}

.VideoRoom_buttonsContainer__2opE2 {
    margin-top: 30px;
}

.VideoRoom_infoContainer__k4QZ5 {
    margin-top: 38px;
}

.VideoRoom_downtown__1MUrN {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.VideoRoom_uptown__2ds79 {
    display: flex;
    height: 100%;
    flex-direction: column;
}

.VideoRoom_waitingContainer__3xJ8I {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #E8E3FF;
    flex-direction: column;
    font-size: 18px;
    font-weight: 600;
}

.VideoRoom_cancelMeetingButton__3fwNK {
    margin-top: 24px;
    font-size: 12px;
    color: white;
    text-decoration: underline;
    cursor: pointer;
    opacity: 0.9;
}

.VideoRoom_loadingAnimation__J9Niw {
    margin-bottom: 22px;
    width: 150px;
}

.EventConversationRoom_container__2qGFp {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background: url(/static/media/bg.9f6c34d6.jpg) repeat;

}


@media (max-width: 599px) {
    .EventConversationRoom_container__2qGFp {
        margin-top: 50px;
        padding: 30px 10px 30px 10px;
        height: 100%;
    }
}
.EventRoundTables_container__3j0F5 {
    width: 100%;
    height: 100vh;
    padding: 40px 30px 00px 30px;
    background: url(/static/media/bg.9f6c34d6.jpg) no-repeat;
    background-size: cover;
    display: flex;
    overflow: hidden;
    flex-direction: column;
}

.EventRoundTables_topStrip__1lUJb {
    height: 50px;
    color: white;
    flex-shrink: 0;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width: 599px) {
    .EventRoundTables_topStrip__1lUJb {
        flex-direction: column;
        height: auto;
        margin-bottom: 20px;
        align-items: flex-start;
    }
}

.EventRoundTables_buttons__3hYFE {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.EventRoundTables_adminButtons__2tKmp {
    display: flex;
    align-items: center; 
}

@media (max-width: 599px) {
    .EventRoundTables_buttons__3hYFE {
        margin-top: 16px;
        margin-left: 0px;
    }
}

.EventRoundTables_header__291EJ {
    font-size: 24px;
    font-weight: 700;
}

.EventRoundTables_subHeader__3_lyk {
    margin-top: 12px;
    font-size: 15px;
    opacity: 0.9;
}

.EventRoundTables_content__3JcV2 {
    margin-top: 20px;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.EventRoundTables_content__3JcV2::-webkit-scrollbar {
    display: none;
  }

.EventRoundTables_roundTables__wPN_l {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-column-gap: 16px;
    -webkit-column-gap: 16px;
            column-gap: 16px;
    grid-row-gap: 16px;
    row-gap: 16px;
    padding-bottom: 30px;
}

@media (max-width: 1600px) {
    .EventRoundTables_roundTables__wPN_l {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (max-width: 1400px) {
    .EventRoundTables_roundTables__wPN_l {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 599px) {
    .EventRoundTables_roundTables__wPN_l {
        grid-template-columns: 1fr;
    }
}

.EventRoundTables_settingsButton__2T1jS {
    width: 40px;
    height: 40px;
    border: 1px white solid;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 50%;
    margin-left: 12px;
    cursor: pointer;
}


.RoundTable_container__1BFDc {
    display: flex;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 304px;
    padding: 12px;
    background: #1f1e2592;
    position: relative;
    border-radius: 5px;
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}

.RoundTable_container__1BFDc:hover {
    background: #1f1e25bd;
}

.RoundTable_container__1BFDc:hover>.RoundTable_buttonContainer__2KUhb {
    background-color: #3c3946;
}

.RoundTable_adminContainer__3Yyrx:hover>.RoundTable_top__2u8Nn .RoundTable_tableNumber__pKSsS {
    display: none;
}

.RoundTable_adminContainer__3Yyrx:hover>.RoundTable_top__2u8Nn .RoundTable_editButton__2BtoF {
    display: block;
}

.RoundTable_editButton__2BtoF {
    cursor: pointer;
    display: none;
}

.RoundTable_top__2u8Nn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    color: #9592a3;
    top: 16px;
    left: 16px;
    right: 16px;
    font-weight: 600;
}

.RoundTable_userTableIndicator__2IbwY {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.RoundTable_userTableIndicatorText__350l3 {
    margin-bottom: 0px !important;
    height: 15px;
    color: #000;
    color: initial;
    width: 200px;
    text-align: right;
}

.RoundTable_userAvatar__1sKbj {
    margin-left: 8px;
    border: 1px #9592a3 solid;
}

.RoundTable_tableContainer__2TIS5 {
    margin-top: -16px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: scale(1.15);
}

.RoundTable_table__30-Sc {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: black;
    opacity: 1;
    border-radius: 16px;
    width: 120px;
    height: 120px;
    color: white;
    text-align: center;
    font-weight: 600;
    padding: 12px;
    line-height: 1.5;
    font-size: 11px;
    box-shadow: rgba(21, 21, 25, 0.708) 0px 10px 40px;
    position: relative;
}

.RoundTable_buttonContainer__2KUhb {
    position: absolute;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    bottom: 8px;
    left: 8px;
    right: 8px;
    height: 44px;
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}

.RoundTable_joinButton__2ZqxJ {
    border-radius: 8px;
    height: 100%;
    font-size: 15px;
    color: #9592a3;
    width: 100%;
    background: none;
    border: none;
    font-weight: 600;
}

.RoundTable_joinButton__2ZqxJ:hover {
    color: #9592a3;
    background: none;
}

.RoundTable_joinButton__2ZqxJ:focus {
    color: #9592a3;
    background: none;
}

.RoundTable_full__1mGhM {
    color: #ff9d9d;
    font-weight: 700;
} 

.RoundTable_chairCoupleRightLeft__t9Cn5 {
    display: flex;
    flex-direction: column;    
    justify-content: space-around;
    align-items: center;
    position: absolute;
    height: 80%;
}

.RoundTable_chairCoupleRight__2rxih {
    right: -16px;
}

.RoundTable_chairCoupleLeft__Nlu3Y {
    left: -16px;
}


.RoundTable_chairCoupleTopBottom__rhMFC {
    display: flex;
    flex-direction: row;    
    justify-content: space-around;
    position: absolute;
    width: 80%;
}

.RoundTable_chairCoupleBottom__2Raua {
    bottom: -16px;
}

.RoundTable_chairCoupleTop__Zw0gS {
    top: -16px;
}


.RoundTable_chairContainer__38fQq {
    display: flex;
    justify-self: center;
    align-items: center;
    position: relative;
}


.RoundTable_chair__341G9 {
    background-color: rgb(60, 57, 70);
    border: 1.5px solid #373643;
    width: 32px;
    height: 32px;
    border-radius: 6px;
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}


.RoundTable_chairAvatar__338dL {
    cursor: pointer;
    position: absolute;
    border-radius: 5px;
    border: 1px solid #4a4959;
}

.RoundTable_preselectedTable__4E5Sr {
    background: #1cbdc030;
}

.RoundTable_preselectedTable__4E5Sr:hover {
    background: #1cbdc045;
}

.RoundTable_preselectedTable__4E5Sr:hover>.RoundTable_buttonContainer__2KUhb {
    background-color: #004d4d46;
    border: 1px solid #ffffff58; 
    font-weight: 600;
}


.AddOrEditRoundTableModal_attendeeOption__1ffFD {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.AddOrEditRoundTableModal_attendeeAvatar__2DZkU {
    margin-right: 12px;
    width: 24px;
    height: 24px;
    margin-left: 8px;
    flex-shrink: 0;
}

.AddOrEditRoundTableModal_attendeeName__3aV5B {
    color: var(--text-secondary-color);
    font-size: 14px;
    color: var(--dark-black);
    margin-right: 8px;
}

.AddOrEditRoundTableModal_userSelect__2YhMr {
    width: 100%;
    height: auto;
}

.edit-round-table-modal .ant-select-show-search.ant-select-multiple .ant-select-selector {
    height: auto;
    min-height: 60px;
}
.edit-round-table-modal .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    height: auto;
    font-weight: 600;
    padding: 4px !important;
    border-radius: 4px;
    border-color: rgb(220, 220, 220);
    background-color:#f2f2f2;
}
:root {
    --group-video-background: #101010f9;
    --group-video-button-background: #302e38;
    --group-video-button-background-hover: #1d1c22;
}

.round-tables .ant-modal-content {
    background-color: transparent !important;
}

.round-tables .ant-modal-body {
    padding: 10px 0px 5px 0px;
}

.RoundTableVideoRoom_container__1i5Tr {
    width: auto !important;
    min-width: 600px !important;
    max-width: auto !important;
    border-radius: 10px !important;
    padding-bottom: 0px;
    padding-right: 12px;
    padding-left: 12px;
    overflow: hidden;
    background-color: #101010f9;
    background-color: var(--group-video-background);
    box-shadow: rgba(0, 0, 0, 0.708) 0px 5px 40px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

@media (max-width: 599px) {
    .RoundTableVideoRoom_container__1i5Tr {
        min-width: 0 !important;
        min-width: initial !important;
        width: auto !important;
        width: initial !important;
        max-width: none !important;
        max-width: initial !important;
        border-radius: 0 !important;
        border-radius: initial !important;
        padding: 0 !important;
        padding: initial !important;
        
    }
}

.RoundTableVideoRoom_content__3q68D {}

.RoundTableVideoRoom_grid__1rjrn {
    display: grid;
    flex-direction: row;
    width: 80vw;
    max-width: 1400px;
    max-height: 85vh;
    grid-gap: 8px;
    gap: 8px;
}

@media (max-width: 599px) {
    .RoundTableVideoRoom_grid__1rjrn {
        display: inline;
        display: initial;
        max-height: none;
        max-height: initial;
        width: 100vw;        
    }
}

.RoundTableVideoRoom_grid__1rjrn>* {
    /* min-width: 250px;
    min-height: 250px; */
    width: auto;
    height: auto;
    border-radius: 5px;
    width: 100%;
    display: flex;
    border: 1px #171718 solid;
    box-shadow: rgba(0, 0, 0, 0.708) 0px 10px 40px;
}

.RoundTableVideoRoom_grid__1rjrn>*::after {
    content: "";
    display: block;
    padding-bottom: 80%;
}

.RoundTableVideoRoom_twoParticipants__2AjhG {
    grid-template-columns: 1fr 1fr;
}

.RoundTableVideoRoom_threeParticipants__1IPnI {
    grid-template-columns: 1fr 1fr 1fr;
}

.RoundTableVideoRoom_fourParticipants__1H4K9 {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.RoundTableVideoRoom_fiveParticipants__X0g89 {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.RoundTableVideoRoom_sixParticipants__1OkkA {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.RoundTableVideoRoom_sevenParticipants__2ixMA {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.RoundTableVideoRoom_eightParticipants__1cDGL {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.RoundTableVideoRoom_nineParticipants__xupB8 {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
}

.RoundTableVideoRoom_content__3q68D {
    display: flex;
    flex-direction: column;
}

.RoundTableVideoRoom_bottomStrip__32Icz {
    margin-top: 18px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px rgb(34 34 34) solid;
    position: relative;
}

.RoundTableVideoRoom_leaveTableButton__MW5__ {
    cursor: pointer;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    right: 0;
    background-color: #302e38;
    background-color: var(--group-video-button-background);
    color: #e25549;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
}

.RoundTableVideoRoom_leaveTableButton__MW5__:hover {
    transition: 0.3s;
    background-color: #1d1c22;
    background-color: var(--group-video-button-background-hover);
}

.RoundTableVideoRoom_stripButton__leP69 {
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #302e38;
    background-color: var(--group-video-button-background);
    margin-left: 8px;
    transition: 0.3s;
}

.RoundTableVideoRoom_stripButton__leP69:hover {
    background-color: #1d1c22;
    background-color: var(--group-video-button-background-hover);
}


.RoundTableVideoRoom_stripButtonDisabled__Ci9c1 {
    background: var(--red);
}

.RoundTableVideoRoom_stripButtonDisabled__Ci9c1:hover {
    background-color: var(--red);
}


.RoundTableVideoRoom_stripButtonIcon__2DdPh {
    font-size: 18px;
}

.RoundTableVideoRoom_tableDetails__1hQ0g {
    position: absolute;
    left: 0;
    opacity: 0.5;
}

.RoundTableVideoRoom_tableNumber__3q9Y5 {
    font-size: 14px;
    font-weight: 700;
    color: #E8E3FF;
}

.RoundTableVideoRoom_tableTitle__e3jaX {
    margin-top: 2px;
    font-size: 12px;
    font-weight: 400;
    color: #E8E3FF;
}

.RoundTableVideoRoom_noVideoIcon__ctewI {
    width: 20px;
}

.RoundTableVideoRoom_loaderContainer__31I2B {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.RoundTableVideoRoom_loadingAnimation__2okRn {
    width: 100px;
}


.RoundTableVideoRoom_divider__GLpAc {
    background: #373737;
    width: 1px;
    height: 28px;
    margin-right: 4px;
    margin-left: 12px;
}
.GroupParticipant_container__3FJRy {
    background-color: #222226;
    overflow: hidden;
}

.GroupParticipant_content__39RMR {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
} 

.GroupParticipant_contentSafariBug__1G0-y {
    height: auto !important;
}

.GroupParticipant_video__1Hbs8 {
    position: absolute;
    border-radius: 5px;
    object-position: center center;
    z-index: 1;
    transform: rotateY(180deg);
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.GroupParticipant_videoOverlay__3SzGf {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100px;
    z-index: 2;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
}

.GroupParticipant_userInfo__28cdA {
    position: absolute;
    bottom: 0;
    padding: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    z-index: 3;
}

.GroupParticipant_details__S9IyV {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.GroupParticipant_texts__2XFwn {
    margin-left: 14px;
}

.GroupParticipant_participantName__22bAh {
    font-weight: 600;
    font-size: 14px;
    color: white;
}

.GroupParticipant_participantTitle__1YXKf {
    font-size: 12px;
    max-width: 100%;
    color: white;
    margin-bottom: 0 !important;
}

.GroupParticipant_controlButtons__o8IhP {
    display: flex;
    flex-direction: row;
    justify-self: flex-end;
    align-self: flex-end;
    margin-left: auto;
}

.GroupParticipant_controlButtons__o8IhP>* {
    margin-left: 2px;
}

.GroupParticipant_message__3MU3i {
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
}

.GroupParticipant_messageContainer__1SVSk {
    display: flex;
    padding: 0px 4px 4px 4px;
    font-size: 16px;
    flex-direction: column;
}

.GroupParticipant_cameraOffContainer__U4hYG {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.GroupParticipant_companyAvatar__1wJUQ {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
}

.GroupParticipant_controlButton__2DggE {
    padding: 5px;
}

.GroupParticipant_youBadge__3zLMX {
    position: absolute;
    z-index: 2;
    top: 12px;
    right: 12px;
    padding: 2px 8px;
    font-size: 12px;
    background-color: var(--ok-green);
    border-radius: 4px;
    color: white;
    font-weight: 700;
}

.GroupParticipant_infoBadge__aiCAc {
    color: white;
    font-size: 13px;
    margin-left: 6px;
}

.GroupParticipant_tooltip__1CRNi { 
    /* color: var(--text-secondary-color); */
    font-size: 14px;
}
.SettingsModal_content__33N3a {
    display: flex;
    flex-direction: column;
}

.SettingsModal_header__2cvM- {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 12px;
}

.SettingsModal_settingsContainer__G2EmE {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SettingsModal_select__3lXDW {
    /* margin-bottom: 12px; */
    width: 100%;
}

.SettingsModal_video__1-kea {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.SettingsModal_devices__3Y-DE {
    width: 100%;
}
.BrowserSupport_container__62xLk {
    
}

@media (max-width: 599px) {
    .BrowserSupport_container__62xLk {
        
    }
}

.BrowserSupport_content__3BZ1W {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 00px;
    width: 100%;
}

.BrowserSupport_header__HTw5S {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
}

.BrowserSupport_description__1D1hS {
    color: var(--text-secondary-color);

}

.BrowserSupport_illustration__V_qy3 {
    margin-top: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.BrowserSupport_illustration__V_qy3 img {
    width: 200px;
}

.BrowserSupport_proceedButton__sNNeg {
    margin-top: 30px;
    color: var(--text-secondary-color);
    font-size: 14px;
    text-decoration: underline;
    align-self: center;
    cursor: pointer;
}
.EventEndedModal_content__kkG2S {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.EventEndedModal_header__3mWne { 
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 6px;
}

.EventEndedModal_subHeader__by1Lt {
    color: var(--text-secondary-color);
    font-size: 16px;
}

.EventEndedModal_calendarButton__1Hbq2 {
    margin-top: 20px;
}

.EventEndedModal_events__MULP5 {
    display: grid;
    margin-top: 20px;
    grid-row-gap: 16px;
    row-gap: 16px;
    width: 100%;
}


.EventEndedModal_eventItem__JKZ3T {
    position: relative;
    padding: 12px;
    width: 100%;
    cursor: pointer;
    box-shadow: 0 2px 5px 0 rgba(211, 211, 211, 0.5);
    border: 1px var(--kinky-grey) solid;
}


.EventEndedModal_eventTitle__3ocGK {
    font-weight: 800;
    margin-bottom: 2px;
    font-size: 16px;
    color: var(--dark-black);
}

.EventEndedModal_eventDate__26o_6 {
    color: var(--text-secondary-color);
    font-size: 14px;
    margin-bottom: 16px;
    color: var(--primary-contrast-dark);
    font-weight: 700;
}

.EventEndedModal_peopleGoing__2r_q1 {
    line-height: 1;
    align-items: flex-start;    
}

.EventEndedModal_attendToButton__2gSXg {
    position: absolute;
    bottom: 12px;
    right: 12px;
    font-weight: 600;
}

@media (max-width: 599px) {
    .EventEndedModal_attendToButton__2gSXg {
        margin-top: 18px;
        position: static;
    }
}


.EventEndedModal_speakersContainer__2BBlz {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.EventEndedModal_speakers__2Tsxt {
    /* margin-top: 14px; */
    display: flex;
    flex-direction: row;
    align-items: center;
}

.EventEndedModal_speakers__2Tsxt>* {
    margin-right: 10px;
}

.EventEndedModal_attendButton__1n9y9 {
    position: absolute;
    right: 12px;
    bottom: 12px;
}

.EventEndedModal_timerText__2KbR9 { 
    margin-top: 20px;
    font-size: 15px;
    color: var(--text-secondary-color);
    text-align: center;
}
.EventRedirectPage_container__2zRxA {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.EventRedirectPage_header__1cXnR {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0 20px;
    /* background-color: red; */
    border-bottom: 1px solid #ebebeb;
}

.EventRedirectPage_headerContent__3TJgu {
    width: 100%;
    max-width: 1000px;
    display: flex;
    align-items: center;
}

.EventRedirectPage_contentWrapper__35GTo {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0 20px;
    height: 100%;
    /* background-color: blue; */
    align-items: center;
}

.EventRedirectPage_content__C3pzJ {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    width: 100%;
    /* background-color: green; */
    max-width: 1000px;
}

@media (max-width: 599px) {
    .EventRedirectPage_content__C3pzJ {
        margin-top: 30px;
        flex-direction: column;
    }
}

.EventRedirectPage_leftSide__1T4s4 {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    justify-content: center;
}

.EventRedirectPage_logo__3gvbr {
    /* margin-left: -6px; */
    height: 28px;
    margin: 30px 0;
}

.EventRedirectPage_helpButton__1o07F {
    margin-left: auto;
    border-radius: 12px;
    font-size: 14px;
    padding: 6px 12px;
    cursor: pointer;
    border: 1px solid rgb(3, 1, 24);
}

.EventRedirectPage_loadingAnimation__zevkI {
    margin-top: -60px;
    margin-bottom: 30px;
    width: 60px;

}

@media (max-width: 599px) {
    .EventRedirectPage_loadingAnimation__zevkI {
        margin-top: 0px;
    }
}

.EventRedirectPage_mainText__fD_dO {
    font-size: 22px;
    font-weight: 600;
    line-height: 1.4em;

}

.EventRedirectPage_infoBubble__2zSL3 {
    /* margin-top: 20px; */
    border-radius: 8px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    background-color: #fffadc;
    max-width: 400px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    /* border: 1px solid #fff9d1; */
}

@media (max-width: 599px) {
    .EventRedirectPage_infoBubble__2zSL3 {
        margin-top: 30px;
    }
}


.EventRedirectPage_infoTextContainer__GVa1F {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.EventRedirectPage_infoIcon__2kmlm {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    display: flex;
    border-radius: 10px;
    margin-right: 12px;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.EventRedirectPage_infoText__d9faI {
    line-height: 1.4;
    font-size: 18px;
}

.EventRedirectPage_spatialImage__3Z3Fc {
    border-radius: 8px;
    width: 100%;
}

.EventRedirectPage_imageContainer__2-P1q {
    position: relative;
    width: 100%;
}

.EventRedirectPage_handAnimation__3oY-G {
    width: 150px;
    position: absolute;
    bottom: 50px;
    right: 30px;
}

@media (max-width: 599px) {
    .EventRedirectPage_handAnimation__3oY-G {
        bottom: 10%;
        right: 10px;
    }
}

.MembershipCardPage_container__38pHX {
    height: auto;
    min-height: 100vh;
    width: 100%;
    line-height: 1.4em;
    background-color: var(--background-color);
}

.MembershipCardPage_upperPart__2BsMi {
    height: 430px;
    background-color: white;
    box-shadow: 0px 10px 30px rgba(239, 239, 239, 0.4);
    overflow: hidden;
}

.MembershipCardPage_upperPartBody__1WIwD {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 20px;
    position: relative;
    z-index: 2;
}

.MembershipCardPage_background__dd_hP {
    overflow: hidden;
    position: absolute;
    z-index: 1;
    width: 100%;
    max-height: 400px;
}

.MembershipCardPage_avatar__23Z6q {
    margin-top: 20px;
    border: 3px solid var(--primary);
}

.MembershipCardPage_userDetails__1981e {
    margin-top: 30px;
    text-align: center;
}

.MembershipCardPage_name__1MQ-p {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 8px;
}

.MembershipCardPage_title__1GkBN {
    font-size: 16px;
    color: var(--text-secondary-color);
}

.MembershipCardPage_foundingMemberBadge__34n3T {
    padding: 3px 8px;
    background-color: var(--primary-contrast-light);
    color: var(--primary-contrast-dark);
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 16px;
}

.MembershipCardPage_premiumBadge__3p-hq {
    padding:4px 8px;
    background-color: #cba83c;
    color: white;
    font-size: 12px;
    border-radius: 4px;
    position: absolute;
    font-weight: 600;
    text-transform: uppercase;
    top: 12px;
    left: 12px;
}

.MembershipCardPage_bottomPart__3fpkP {
    overflow: scroll;
    padding: 20px 0px;
    /* height: 100%; */
}

.MembershipCardPage_grid__1_4Yx {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    gap: 16px;
    padding: 0 12px;
}

.MembershipCardPage_card__31NKf {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    box-shadow: 0px 0px 30px rgba(197, 197, 197, 0.40);
    border-radius: 10px;
    height: 180px;
    padding: 12px;
}

.MembershipCardPage_sideIcon__157HW {
    position: absolute;
    left: 12px;
    top: 12px;
    font-size: 24px;
}

.MembershipCardPage_date__hyXyQ {
    font-weight: 900;
    font-size: 28px;
}

.MembershipCardPage_smallHeader__bsRa5 {
    font-size: 16px;
    font-weight: 600;
    position: absolute;
    bottom: 24px;
    padding: 0 12px;
    width: 100%;
    text-align: center;
}

.MembershipCardPage_largeText__DESYD {
    font-size: 38px;
    font-weight: 900;
}


.MembershipCardPage_contactDetails__1mZgR {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
    margin-top: 26px;
}

.MembershipCardPage_circle__1BLem {
    width: 70px;
    height: 70px;
    color: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--primary);
    border-radius: 50%;
    cursor: pointer;
    font-size: 28px;
    box-shadow: 0px 0px 20px rgba(133, 133, 133, 0);
}

.MembershipCardPage_editProfile__2y6MX {
    /* position: absolute; */
    bottom: 22px;
    color: #070e22a8;
    display: flex;
    width: 100%;
    font-size: 16px;
    text-decoration: underline;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.MembershipCardPage_logo__phrN9 {
    position: absolute;
    height: 18px;
    top: 12px;
    right: 12px;
    -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
    
}
